import { API_STATIC_FILES } from '@lib/constants';
import { firebaseConfig } from '@lib/firebase/config';

/**
 * Resolves an url into a sanitized unicode string
 *
 * @param {string} path The url path
 * @returns {string} The sanitized string
 */
export const getImageUrl = (path: string | undefined) => {
    const newPath = path?.replace('/', '%2F');
    return `${API_STATIC_FILES}${firebaseConfig.storageBucket}/o/${newPath}?alt=media`;
};
