import { RefObject, useEffect } from 'react';

export const useClickedOutsideOfComponent = (
    ref: RefObject<HTMLElement> | null,
    callback: () => void,
) => {
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (!ref?.current?.contains(event.target as Node)) {
                callback();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, callback]);
};
