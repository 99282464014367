import { DigicoUser } from '@lib/types';
import { addStudentIdToResultGroupByEmail } from '@lib/helper/addStudentIdToResultGroupByEmail';
import router from 'next/router';
import { doc, getDoc, getFirestore } from '@firebase/firestore';

const db = getFirestore();

const studentExists = async (user: DigicoUser) => {
    const docRef = doc(db, 'student', user.studentId ?? '');
    const docSnap = await getDoc(docRef);
    return docSnap.exists();
};

const removeLocalStorageItems = () => {
    const savedPartnerId = localStorage.getItem('partnerId');
    if (savedPartnerId) {
        localStorage.removeItem('partnerId');
    }

    const creatingUser = localStorage.getItem('creatingUser');
    if (creatingUser) {
        localStorage.removeItem('creatingUser');
    }

    const resultGroupId = localStorage.getItem('resultGroupId');
    if (resultGroupId) {
        localStorage.removeItem('resultGroupId');
    }
};

export const redirectToStudentPortal = async (
    user: DigicoUser,
    resultId?: string | null,
) => {
    if (user) {
        try {
            if (resultId) {
                // Ensure the user is properly created before proceeding
                if (!(await studentExists(user))) {
                    const resultGroupAdded =
                        await addStudentIdToResultGroupByEmail(
                            user.emailAddress,
                            resultId,
                        );

                    if (resultGroupAdded) {
                        const savedPartnerId =
                            localStorage.getItem('partnerId');

                        if (savedPartnerId) {
                            await router.push(
                                `/learning-content?partnerId=${savedPartnerId}&assessmentId=${resultId}&view=summary`,
                            );
                            return;
                        }

                        await router.push('/');
                    } else {
                        // Handle the case where the user creation failed
                        console.error('Failed to create the user.');
                    }
                } else {
                    await router.push(
                        `/learning-content?partnerId=${user.partnerId}&assessmentId=${resultId}&view=summary`,
                    );
                }
            } else {
                await router.push('/');
            }
        } catch (err) {
            console.error(err);
        } finally {
            removeLocalStorageItems();
        }
    }
};
