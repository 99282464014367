import React from 'react';
import { Image } from '@components/Image';

export type ButtonType = 'primary' | 'secondary' | 'tertiary' | 'google';
export type ButtonSize = 'sm' | 'md';

type SkillifyButtonProps = {
    children: React.ReactNode;
    buttonType?: ButtonType;
    onClick?: () => void;
    isBackButton?: boolean;
    isNextButton?: boolean;
    isNextVerticalButton?: boolean;
    isSubmit?: boolean;
    isDisabled?: boolean;
    isIcon?: boolean;
    isHidden?: boolean;
    isSmallPadding?: boolean;
    isRound?: boolean;
    size?: ButtonSize;
};

export const SkillifyButton: React.FC<
    SkillifyButtonProps & React.HTMLAttributes<HTMLDivElement>
> = ({
    children,
    buttonType = 'primary',
    onClick,
    isBackButton,
    isNextButton,
    isNextVerticalButton,
    isSubmit,
    isDisabled,
    isIcon,
    isHidden,
    isSmallPadding,
    isRound,
    size = 'md',
    ...Props
}: SkillifyButtonProps & React.HTMLAttributes<HTMLDivElement>) => (
    <div {...Props}>
        {buttonType === 'google' ? (
            <button
                disabled={isDisabled}
                type={isSubmit ? 'submit' : 'button'}
                onClick={onClick}
                className="tw-inline-flex tw-w-full tw-items-center tw-justify-center tw-gap-4 tw-rounded-lg tw-border-2 tw-border-solid tw-border-skillify-color tw-bg-white tw-p-1 tw-font-semibold tw-text-skillify-color"
            >
                <div className="tw-flex tw-items-center tw-justify-center tw-gap-4 ">
                    <Image
                        src="/images/Google-Logo.svg"
                        alt="google logo"
                        width={20}
                        height={20}
                    />
                    <span className="skillify-font tw-flex tw-items-center tw-gap-4">
                        {children}
                    </span>
                </div>
            </button>
        ) : (
            <button
                disabled={isDisabled}
                type={isSubmit ? 'submit' : 'button'}
                onClick={onClick}
                className={`tw-inline-flex tw-h-full tw-w-full tw-items-center tw-gap-4 tw-rounded-lg
                            ${size === 'sm' ? 'tw-text-sm' : 'tw-text-base'}
            ${
                isNextVerticalButton && isNextButton
                    ? 'tw-justify-between md:tw-flex-col md:tw-justify-center'
                    : isBackButton || isNextButton
                    ? 'tw-justify-between'
                    : 'tw-justify-center'
            }
            ${
                buttonType === 'primary'
                    ? 'tw-border-0 tw-bg-skillify-color tw-text-white'
                    : ''
            }
            ${
                buttonType === 'secondary'
                    ? 'tw-border-2 tw-border-solid tw-border-skillify-color tw-bg-white tw-text-skillify-color'
                    : ''
            }
            ${
                buttonType === 'tertiary'
                    ? 'tw-border-0 tw-bg-white tw-text-black tw-shadow-skillify'
                    : ''
            }
            ${isDisabled ? 'tw-text-[#D1D5DB]' : ''}
            ${
                isIcon
                    ? 'tw-px-4 tw-py-0.5'
                    : isSmallPadding
                    ? 'tw-px-4  tw-py-1'
                    : 'tw-px-4 tw-py-1.5  xs:tw-px-8'
            }
            ${isHidden ? 'tw-hidden' : ''}
            ${isRound ? '!tw-rounded-3xl tw-py-1.5 tw-px-6' : ''}
            `}
            >
                {isBackButton && (
                    <Image
                        className={`rtl:tw-rotate-180 ${
                            size === 'sm' ? 'tw-scale-75' : 'tw-scale-100'
                        }`}
                        src={
                            isDisabled
                                ? '/images/arrowLeftDisabled.svg'
                                : buttonType === 'tertiary'
                                ? '/images/arrowLeftBlack.svg'
                                : '/images/arrowLeftWhite.svg'
                        }
                        alt="previous"
                    />
                )}
                <span
                    className={`skillify-font tw-font-semibold ${
                        isNextVerticalButton
                            ? 'tw-leading-4 md:tw-max-w-[6.25rem]'
                            : ''
                    }`}
                >
                    {children}
                </span>
                {isNextButton && (
                    <Image
                        className={`rtl:tw-rotate-180 ${
                            isNextVerticalButton ? 'md:tw-mt-3' : ''
                        } ${size === 'sm' ? 'tw-scale-75' : 'tw-scale-100'}`}
                        src={
                            isDisabled
                                ? '/images/arrowRightDisabled.svg'
                                : buttonType === 'tertiary'
                                ? '/images/arrowRightBlack.svg'
                                : '/images/arrowRightWhite.svg'
                        }
                        alt="next"
                    />
                )}
            </button>
        )}
    </div>
);
