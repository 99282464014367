import { Image } from '@components/Image';
import React, { useState } from 'react';
import { useAssessmentToolProvider } from '@providers/AssessmentToolProvider';
import { SkillifyModal } from '@components/AssessmentTool/SkillifyModal';
import { getImageUrl } from '@lib/helper/getImageUrl';
import { useRouter } from 'next/router';
import { useEffectOnMount } from '@lib/hooks/useEffectOnMount';
import { useAuthProvider } from '@providers/AuthProvider';

interface MotivationModalProps {}

export const MotivationModal: React.FC<MotivationModalProps> = () => {
    const {
        setShowMotivation,
        areasQuestions,
        globalAreaIndex,
        partnerAssessment,
        setShowSignup,
        setUpcomingVerificationQuestion,
    } = useAssessmentToolProvider();
    const { isUserLoggedIn } = useAuthProvider();
    const [endOfAssessment, setEndOfAssessment] = useState(false);
    useEffectOnMount(() => {
        if (isUserLoggedIn) {
            setEndOfAssessment(
                globalAreaIndex + 1 > (areasQuestions?.length ?? 0),
            );
        } else {
            setEndOfAssessment(
                globalAreaIndex + 1 >= (areasQuestions?.length ?? 0),
            );
        }
    });

    const router = useRouter();
    if (endOfAssessment) {
        return (
            <SkillifyModal
                confirmButton={{
                    buttonType: 'primary',
                    onClick: () => {
                        setShowSignup(true);
                        setShowMotivation(false);
                        setUpcomingVerificationQuestion(null); // We set it to null, because if no verification questions are reached it will save as undefined to local storage resulting in an error
                    },
                    text: partnerAssessment?.endAssessmentModal?.signupButton,
                }}
                cancelButton={{
                    buttonType: 'secondary',
                    onClick: () => {
                        router.push('/sign-in');
                    },
                    text: partnerAssessment?.endAssessmentModal?.loginButton,
                }}
                backgroundImage="/images/Confetti.png"
            >
                <h1 className="skillify-font tw-text-center tw-text-2xl tw-font-bold md:tw-text-4xl">
                    {partnerAssessment?.endAssessmentModal?.title}
                    <span className="tw-sr-only">.</span>
                </h1>
                <p className="skillify-font md:tw-text-md tw-m-0 tw-text-center tw-text-sm tw-font-semibold tw-text-black/60 md:tw-font-bold">
                    {partnerAssessment?.endAssessmentModal?.paragraph1}
                </p>
                <p className="skillify-font md:tw-text-md tw-m-0 tw-text-center tw-text-sm tw-font-semibold tw-text-black/60 md:tw-font-bold">
                    {partnerAssessment?.endAssessmentModal?.paragraph2}
                </p>
                <Image
                    className="tw-h-[9.375rem] tw-py-4  md:tw-h-[12.5rem]"
                    src={getImageUrl(
                        partnerAssessment?.endAssessmentModal?.image,
                    )}
                    alt={partnerAssessment?.endAssessmentModal?.title}
                />
            </SkillifyModal>
        );
    }
    return (
        <SkillifyModal
            confirmButton={{
                onClick: () => setShowMotivation(false),
                text: areasQuestions?.[globalAreaIndex - 1]
                    ?.motivationButtonLabel,
            }}
            backgroundImage="/images/Confetti.png"
        >
            <h1 className="skillify-font tw-text-center tw-text-2xl tw-font-bold md:tw-text-4xl">
                {areasQuestions?.[globalAreaIndex - 1]?.motivationTitle}
                <span className="tw-sr-only">.</span>
            </h1>
            <p className="skillify-font md:tw-text-md tw-m-0 tw-text-center tw-text-sm tw-font-semibold tw-text-black/60 md:tw-font-bold">
                {areasQuestions?.[globalAreaIndex - 1]?.motivationText}
            </p>
            <Image
                className="tw-h-[9.375rem] tw-py-4  md:tw-h-[12.5rem]"
                src={getImageUrl(
                    areasQuestions?.[globalAreaIndex - 1]?.motivationImage!,
                )}
                alt={areasQuestions?.[globalAreaIndex - 1]?.motivationTitle}
            />
            <p className="skillify-font md:tw-text-md  tw-m-0 tw-text-center tw-text-sm tw-font-semibold tw-text-black/60 md:tw-font-bold">
                {areasQuestions?.[globalAreaIndex - 1]?.motivationNextUp}{' '}
                {areasQuestions?.[globalAreaIndex]?.name}
            </p>
        </SkillifyModal>
    );
};
