import { Image } from '@components/Image';
import React from 'react';
import { getImageUrl } from '@lib/helper/getImageUrl';
import type { Partner } from '@digico/common/lib/Partner';

interface PartnerLogoProps {
    partnerData: Partner | undefined;
}

export const PartnerLogo: React.FC<PartnerLogoProps> = ({
    partnerData,
}: PartnerLogoProps) => (
    <Image
        className="tw-max-h-[2.75rem]"
        src={getImageUrl(partnerData?.logo ?? 'images/Skillify_Lo_FF-01.png')}
        alt={partnerData?.nameOfPartner ?? 'Skillify'}
    />
);
