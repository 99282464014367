import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import type { Language } from '@digico/common/lib/Languages';
import { useRouter } from 'next/router';
import { getAuth } from '@firebase/auth';

export type LanguageContextType = {
    countryCode: string;
    setCountryCode: React.Dispatch<React.SetStateAction<string>>;
    languages: Language[] | undefined;
    setLanguages: React.Dispatch<React.SetStateAction<Language[] | undefined>>;
    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    isRTL: boolean;
};

export const Context = createContext<LanguageContextType | undefined>(
    undefined,
);
const LanguageProvider: React.FunctionComponent = ({ children }) => {
    const [countryCode, setCountryCode] = useState<string>('en');
    const [languages, setLanguages] = useState<Language[] | undefined>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const router = useRouter();
    useEffect(() => {
        setIsLoading(true);
        if (!router.query.lang && !router.asPath.includes('lang=')) {
            const storedCountryCode = localStorage.getItem('code') ?? 'en';
            router
                .push({
                    pathname: router.pathname,
                    query: {
                        lang: storedCountryCode,
                        ...router.query,
                    },
                })
                .then(() => {
                    setCountryCode(storedCountryCode);
                    setIsLoading(false);
                });
        } else {
            setIsLoading(false);
        }
    }, [router]);

    useEffect(() => {
        let storedCountryCode = localStorage.getItem('code') ?? 'en';
        let newCountryCode: string | undefined = window.location.href
            .split('lang=')?.[1]
            ?.slice(0, 2);
        const uppercaseCountryCodes: string[] = ['AR', 'UA'];
        if (!uppercaseCountryCodes.includes(newCountryCode)) {
            newCountryCode = newCountryCode?.toLowerCase();
        }
        if (uppercaseCountryCodes.includes(newCountryCode?.toUpperCase())) {
            newCountryCode = newCountryCode?.toUpperCase();
        }
        if (
            storedCountryCode !== newCountryCode &&
            newCountryCode !== undefined
        ) {
            localStorage.setItem('code', newCountryCode);
            setCountryCode(newCountryCode);
            storedCountryCode = newCountryCode;
        } else setCountryCode(storedCountryCode);
        const auth = getAuth();
        auth.languageCode = storedCountryCode.toLowerCase();
    }, []);

    const contextValue = useMemo<LanguageContextType>(
        () => ({
            countryCode,
            setCountryCode,
            languages,
            setLanguages,
            isLoading,
            setIsLoading,
            isRTL: countryCode === 'AR',
        }),
        [
            countryCode,
            setCountryCode,
            languages,
            setLanguages,
            setIsLoading,
            isLoading,
        ],
    );
    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export default LanguageProvider;

export const useLanguageProvider = () => {
    const context = useContext(Context);
    if (!context) {
        throw new Error(
            'useLanguageProvider must be used within a LanguageProvider',
        );
    }
    return context;
};
