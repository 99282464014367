import { useCallback, useEffect, useState } from 'react';
import { DIGICO_URL, servicesURL } from '@lib/firebase/config';
import type { Email } from '@digico/common/lib';
import { EmailsPath } from '@lib/firebase/firestorePaths';
import { useDocument } from 'react-firebase-hooks/firestore';
import { doc, getFirestore } from '@firebase/firestore';
import { getData } from '../../services/api';

export const useSendSignupMail = (countryCode: string) => {
    const [signupEmail, setSignupEmail] = useState<Email.Email>();

    const emailsPath =
        countryCode === 'en'
            ? `${EmailsPath}/sign-up`
            : `${EmailsPath}/sign-up/locales/${countryCode}`;

    const [emailData] = useDocument(doc(getFirestore(), emailsPath), {
        snapshotListenOptions: { includeMetadataChanges: true },
    });

    useEffect(() => {
        if (emailData) setSignupEmail(emailData.data() as Email.Email);
    }, [emailData]);

    return useCallback(
        async (name: string, email: string, partnerId: string) => {
            const { subject, sender, templateId, link } =
                signupEmail as Email.Email;
            const linkWithPartnerId = link?.replace(
                /\[partnerId\]/i,
                partnerId,
            );
            await getData(`${servicesURL}service-sendEmail`, 'POST', {
                email,
                from: sender,
                subject,
                templateId,
                data: {
                    name,
                    link: DIGICO_URL + linkWithPartnerId,
                },
            });
        },
        [signupEmail],
    );
};
