export const getCookie = (cookieName: string, cookies: string) => {
    if (cookies) {
        const values: { [c: string]: string } = cookies
            .split(';')
            .reduce((res, item) => {
                const data = item.trim().split('=');
                return { ...res, [data[0]]: data[1] };
            }, {});
        return values[cookieName];
    }

    return undefined;
};
