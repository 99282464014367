import React, { useCallback, useEffect } from 'react';
import { useAssessmentToolProvider } from '@providers/AssessmentToolProvider';
import { ANALYTICS_NAMES } from '@lib/constants';
import { useRouter } from 'next/router';
import { IntroCardText } from '@components/AssessmentTool/IntroCard/IntroCardText';
import { CompetencyItem } from '@components/AssessmentTool/CompetencyItem';
import { logAnalytic } from '@lib/helper/logAnalytic';
import { useLanguageProvider } from '@providers/LanguageProvider';
import type { AssessmentArea } from '@digico/common/lib/AssessmentArea';

interface IntroCardProps {
    visibleAreas: AssessmentArea[];
}

export const IntroCard: React.FC<IntroCardProps> = ({ visibleAreas }) => {
    const { partnerAssessment, setWatchTutorial, setIsIntro } =
        useAssessmentToolProvider();
    const router = useRouter();
    const { countryCode } = useLanguageProvider();
    const { partnerId, assessmentId } = router.query;

    const onButtonClick = useCallback(() => {
        const currentUrl = router.asPath; // get the current URL
        const path = currentUrl.split('?')[0] ?? '';
        router.push(`${path}/full-assessment`);
        logAnalytic(ANALYTICS_NAMES.assessmentArea, {
            event: 'startedTest',
            partnerId,
        });

        setWatchTutorial(true);
        setIsIntro(false);
    }, [partnerId, setWatchTutorial, setIsIntro]);

    const competencies = visibleAreas?.map((area) => (
        <CompetencyItem
            key={area.name}
            onClick={() => {
                router.push(
                    `/assessment-tool/${partnerId}/${assessmentId}/${area.id}?lang=${countryCode}&intro=false`,
                );
            }}
            area={area}
        />
    ));

    useEffect(() => {
        if (visibleAreas?.length === 1) {
            setWatchTutorial(true);
            setIsIntro(false);
        }
    }, [setIsIntro, setWatchTutorial, visibleAreas?.length]);

    if (visibleAreas?.length > 1) {
        return (
            <IntroCardText
                partnerAssessment={partnerAssessment}
                onClick={onButtonClick}
                elements={competencies}
            />
        );
    }

    return null;
};
