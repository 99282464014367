import React from 'react';
import { Image } from '@components/Image';
import { getImageUrl } from '@lib/helper/getImageUrl';

type AreaQuestionProps = {
    question?: string;
    titleSmallScreen?: string;
    titleBigScreen?: string;
    questionOptions: JSX.Element[] | undefined;
    verificationImage?: string;
};
export const AreaQuestion: React.FC<AreaQuestionProps> = ({
    question,
    titleSmallScreen,
    titleBigScreen,
    questionOptions,
    verificationImage,
}: AreaQuestionProps) => (
    <section
        className={`tw-mb-12 ${
            verificationImage
                ? 'md:tw-grid md:tw-grid-cols-2 md:tw-items-center md:tw-gap-8'
                : 'tw-flex tw-flex-col'
        }`}
    >
        <div
            className={`tw-text-center md:tw-text-start ${
                verificationImage ? '' : ''
            }`}
        >
            <h1 className="skillify-font tw-text-base tw-font-semibold tw-text-skillify-color md:tw-hidden ">
                {titleSmallScreen}
                <span className="tw-sr-only">.</span>
            </h1>
            <h1 className="skillify-font tw-hidden tw-text-lg tw-font-semibold tw-text-skillify-color md:tw-inline xl:tw-text-xl ">
                {titleBigScreen}
                <span className="tw-sr-only">.</span>
            </h1>
            <h2 className="skillify-font tw-min-h-[80px] tw-text-lg tw-font-semibold tw-text-black/80 md:tw-text-xl xl:tw-text-2xl">
                {question}
                <span className="tw-sr-only">.</span>
            </h2>
            {verificationImage && (
                <Image
                    className="tw-hidden tw-max-h-[285px] tw-max-w-[80%] tw-p-8 tw-ps-12 md:tw-block"
                    src={getImageUrl(verificationImage)}
                    alt="verification Image"
                />
            )}
        </div>
        <div
            className={
                questionOptions?.some(
                    (option) =>
                        option.props.question?.question.presentationStyle ===
                        'image-selection',
                )
                    ? 'tw-grid tw-grid-cols-2 tw-gap-4 '
                    : 'md:tw-grid md:tw-h-full md:tw-grid-flow-col md:tw-grid-cols-2 md:tw-grid-rows-2 md:tw-gap-x-8 md:tw-py-8'
            }
        >
            {questionOptions}
        </div>
    </section>
);
