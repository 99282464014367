import type { Result } from '@digico/common/lib/Result';

export const calculateAverageFromResults = (
    results: Result[],
    property: 'scoreNumber' | 'scorePercentage' | 'verificationScore',
) =>
    results.reduce(
        (prevValue, curValue) => prevValue + (curValue[property] ?? 0),
        0,
    ) / results.length;
