// Keep in sync with components/web/lib/firestorePaths.ts
export const AnswerPath = 'answer';
export const AssessmentsPath = 'assessments';
export const AssessmentPath = 'assessment-structure';
export const AssessmentAreaPath = 'assessment-area';
export const AssessmentCompetencyPath = 'assessment-competency';
export const AssessmentQuestionPath = 'assessment-question';
export const CitiesPath = 'cities';
export const CountriesPath = 'countries';
export const EmailsPath = 'emails';
export const LearningContentPath = 'learning-content';
export const LearningContentPagePath = 'learning-content-page';
export const LanguagesPath = 'languages';
export const PagePath = 'page';
export const RowPath = 'row';
export const ResultGroupPathV2 = 'result-group-v2';
export const PartnerPath = 'partner';
export const ResultGroupPath = 'result-group';
export const StudentPath = 'student';
export const OptionCategoryPath = 'option-category';
export const AdminPath = 'admin';
export const StudentPortalPath = 'student-portal';
export const NavigationItemsPath = 'navigation-items';
export const DynamicPagesPath = 'dynamic-pages';
export const PdfContentPath = 'pdf-content';
export const AboutDigCompPath = 'about-digcomp';
export const PartnerCoursePath = 'learning-content';
export const PrivacyPolicyPath = 'privacy-policy';
