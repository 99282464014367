import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import * as FirebaseAuth from '@firebase/auth';
import {
    browserSessionPersistence,
    GoogleAuthProvider,
    setPersistence,
} from '@firebase/auth';
import { firebaseApp } from '@lib/firebase/firebase';
import {
    addDoc,
    collection,
    getDocs,
    query,
    updateDoc,
} from 'firebase/firestore';
import { db, getDocData } from '@lib/firebase/firestore-crud';
import { StudentPath } from '@lib/firebase/firestorePaths';
import {
    AuthUserContextType,
    DigicoUser,
    GoogleSignupValues,
    IsNewStudent,
    ManualSignupValues,
    ResultGroupWeb,
} from '@lib/types';
import { useSendSignupMail } from '@lib/hooks/useSendSignUpEmail';
import { useLanguageProvider } from '@providers/LanguageProvider';
import { useIsLoading } from '@lib/hooks/useIsLoading';
import { doc, limit, orderBy, where } from '@firebase/firestore';
import _ from 'lodash';
import type { Student } from '@digico/common/lib/Student';
import { useRouter } from 'next/router';
import { parseQueryToString } from '@lib/helper/parseQueryToString';
import { useCookieListener } from '@lib/hooks/useCookieListener';
import { useEffectOnMount } from '@lib/hooks/useEffectOnMount';
import { generateFullName } from '@lib/helper/generateFullName';
import { DIGICO_PARTNER_ID } from '@lib/constants';
import { getCookie } from '@lib/helper/getCookie';
import { checkIfStudentExists, getDataWithCredentials } from '../services/api';

const authUserContext = createContext<AuthUserContextType | undefined>(
    undefined,
);

const getLatestResult = async (
    studentId: string,
): Promise<ResultGroupWeb | null> => {
    const q = query(
        collection(db, 'result-group-v2'),
        where('_studentIdRefString', '==', studentId ?? ''),
        orderBy('created_at', 'desc'),
        limit(1),
    );
    const querySnapshot = await getDocs(q);

    let studentResult: ResultGroupWeb | null = null;
    querySnapshot.forEach((doc) => {
        studentResult = doc.data();
    });
    return studentResult;
};

const AuthProvider: React.FunctionComponent = ({ children }) => {
    const router = useRouter();
    const { countryCode } = useLanguageProvider();
    const auth = FirebaseAuth.getAuth(firebaseApp);
    const [authUser, setAuthUser] = useState<DigicoUser | null>(null);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(
        auth.currentUser !== null,
    );
    const [loading, setLoading] = useState(true);
    const [isAboutSkillifyAccessible, setIsAboutSkillifyAccessible] =
        useState<boolean>(true);
    const sendSignupEmail = useSendSignupMail(countryCode);
    const isLoading = useIsLoading([loading]);
    const csstCookie = useCookieListener('csst');

    const formatAuthUser = useCallback(
        async (
            user: FirebaseAuth.User,
            values?: ManualSignupValues | GoogleSignupValues,
        ): Promise<[DigicoUser, IsNewStudent]> => {
            let isNewStudent = false;

            let fullName: string | null = null;
            if (values?.firstName || values?.lastName) {
                fullName = generateFullName(
                    values.firstName ?? '',
                    values.lastName ?? '',
                );
            }

            const { exists, id } = await checkIfStudentExists(user.email);
            let studentId = id;

            if (!exists && values) {
                try {
                    const { partnerId, assessmentId } = router.query;

                    const res = await addDoc(collection(db, StudentPath), {
                        name: user.displayName ?? fullName,
                        emailAddress: user.email,
                        age: parseInt(values.age, 10),
                        partnerId: partnerId
                            ? parseQueryToString(partnerId)
                            : undefined,
                        assessmentId: assessmentId
                            ? parseQueryToString(assessmentId)
                            : undefined,
                        ..._.omit(values, [
                            'password',
                            'confirmPassword',
                            'emailAddress',
                            'age',
                        ]),
                    });
                    if (user.email)
                        await sendSignupEmail(
                            values.firstName,
                            user.email,
                            parseQueryToString(partnerId),
                        );
                    studentId = res.id;
                    isNewStudent = true;

                    return [
                        {
                            partnerId: partnerId
                                ? parseQueryToString(partnerId)
                                : undefined,
                            assessmentId: assessmentId
                                ? parseQueryToString(assessmentId)
                                : undefined,
                            displayName: user.displayName ?? fullName,
                            studentId,
                            emailAddress: user.email,
                        },
                        isNewStudent,
                    ];
                } catch (err) {
                    console.error(err);
                }
            }

            if (studentId) {
                const latestResult = await getLatestResult(studentId);
                if (latestResult) {
                    const { partnerId, assessmentId } = latestResult;
                    await updateDoc(doc(db, StudentPath, studentId), {
                        partnerId: partnerId?.id,
                        assessmentId,
                    });
                }
                const studentValues = await getDocData<Student>(
                    StudentPath,
                    studentId,
                );

                if (studentValues)
                    return [
                        {
                            partnerId: studentValues.partnerId,
                            assessmentId: studentValues.assessmentId,
                            displayName:
                                studentValues.name ??
                                generateFullName(
                                    studentValues.firstName ?? '',
                                    studentValues.lastName ?? '',
                                ),
                            studentId,
                            emailAddress: studentValues.emailAddress,
                            age: studentValues.age?.toString(),
                            benefits: studentValues.benefits,
                            country: studentValues.country,
                            disability: studentValues.disability,
                            education: studentValues.education,
                            employment: studentValues.employment,
                            firstName: studentValues.firstName,
                            gender: studentValues.gender,
                            lastName: studentValues.lastName,
                            residency: studentValues.residency,
                        },
                        isNewStudent,
                    ];
            }
            return [
                {
                    displayName: user.displayName ?? fullName,
                    studentId,
                    emailAddress: user.email,
                },
                isNewStudent,
            ];
        },
        [router, sendSignupEmail],
    );

    const clear = async () => {
        await getDataWithCredentials('/auth/clear', 'POST');
        setAuthUser(null);
        setLoading(false);
    };

    const signInWithEmailAndPassword = useCallback(
        async (email: string, password: string) => {
            await setPersistence(auth, browserSessionPersistence);
            const userCredential =
                await FirebaseAuth.signInWithEmailAndPassword(
                    auth,
                    email,
                    password,
                );

            const [user] = await formatAuthUser(userCredential.user);
            return user;
        },
        [auth, formatAuthUser],
    );

    const signInWithToken = useCallback(
        async (customToken: string) => {
            await setPersistence(auth, browserSessionPersistence);
            const userCredential = await FirebaseAuth.signInWithCustomToken(
                auth,
                customToken,
            );

            const [user] = await formatAuthUser(userCredential.user);
            return user;
        },
        [auth, formatAuthUser],
    );

    const createUserWithEmailAndPassword = useCallback(
        async (signUpValues: ManualSignupValues) => {
            const { password, emailAddress } = signUpValues;
            if (!password || !emailAddress) {
                throw new Error('Email or Password undefined');
            }
            localStorage.setItem('creatingUser', 'true');
            await setPersistence(auth, browserSessionPersistence);
            const userCredential =
                await FirebaseAuth.createUserWithEmailAndPassword(
                    auth,
                    emailAddress,
                    password,
                );

            return formatAuthUser(userCredential.user, signUpValues);
        },
        [auth, formatAuthUser],
    );

    const signOut = useCallback(
        () => FirebaseAuth.signOut(auth).then(clear),
        [auth],
    );

    const signInWithGoogle = useCallback(
        async (values?: GoogleSignupValues) => {
            await setPersistence(auth, browserSessionPersistence);
            const userCredential = await FirebaseAuth.signInWithPopup(
                auth,
                new GoogleAuthProvider(),
            );
            if (values) return formatAuthUser(userCredential.user, values);

            const { exists } = await checkIfStudentExists(
                userCredential.user.email,
            );

            if (!exists) {
                await signOut();
                router.push(
                    `/sign-up${router.query.partnerId ?? DIGICO_PARTNER_ID}`,
                );
                throw Error();
            }

            return formatAuthUser(userCredential.user);
        },
        [auth, formatAuthUser, router, signOut],
    );

    const sendPasswordResetEmail = useCallback(
        (email: string, actionCodeSettings: FirebaseAuth.ActionCodeSettings) =>
            FirebaseAuth.sendPasswordResetEmail(
                auth,
                email,
                actionCodeSettings,
            ),
        [auth],
    );

    const authStateChanged = useCallback(
        async (firebaseUser: FirebaseAuth.User | null) => {
            if (!firebaseUser) {
                if (!authUser && getCookie('csst', window.document.cookie)) {
                    // we return early so the loading screen stays loading till we sign with `signInWithToken`
                    return;
                }
                setIsUserLoggedIn(false);
                setLoading(false);
                return;
            }
            setIsUserLoggedIn(true);
            setLoading(true);
            const idToken = await firebaseUser.getIdToken(true);
            await getDataWithCredentials('/auth/verify', 'POST', {
                idToken,
            });
            const [formattedUser] = await formatAuthUser(firebaseUser);
            setIsAboutSkillifyAccessible(true);
            setAuthUser(formattedUser);
            setLoading(false);
        },
        [formatAuthUser],
    );

    useEffectOnMount(() => {
        if (!authUser && getCookie('csst', window.document.cookie)) {
            getDataWithCredentials('/auth/checkStatus', 'POST').then((data) =>
                signInWithToken(data.customToken),
            );
        }
    });

    useEffect(() => {
        if (!isAboutSkillifyAccessible)
            localStorage.setItem(
                'isAboutSkillifyAccessible',
                `${isAboutSkillifyAccessible}`,
            );
    }, [isAboutSkillifyAccessible]);

    useEffect(() => {
        const isAccessible = localStorage.getItem('isAboutSkillifyAccessible');
        if (isAccessible) {
            setIsAboutSkillifyAccessible(JSON.parse(isAccessible));
        }

        return () => {
            setIsAboutSkillifyAccessible(true);
        };
    }, []);

    useEffect(() => {
        const storageEventHandler = (event: StorageEvent) => {
            if (event.key === 'isAboutSkillifyAccessible') {
                event.newValue
                    ? setIsAboutSkillifyAccessible(JSON.parse(event.newValue))
                    : setIsAboutSkillifyAccessible(true);
            }
        };
        window.addEventListener('storage', storageEventHandler);
        return () => {
            window.removeEventListener('storage', storageEventHandler);
        };
    }, []);

    useEffect(() => {
        const unsubscribe = FirebaseAuth.onAuthStateChanged(
            auth,
            authStateChanged,
        );
        return () => unsubscribe();
    }, [auth, authStateChanged]);

    useEffect(() => {
        if (!csstCookie) {
            signOut();
        }
    }, [csstCookie, signOut]);

    const authUserContextValue = useMemo<AuthUserContextType>(
        () => ({
            authUser,
            isLoading,
            isUserLoggedIn,
            signInWithEmailAndPassword,
            signInWithGoogle,
            createUserWithEmailAndPassword,
            sendPasswordResetEmail,
            signOut,
            isAboutSkillifyAccessible,
            setIsAboutSkillifyAccessible,
        }),
        [
            authUser,
            isLoading,
            isUserLoggedIn,
            signInWithEmailAndPassword,
            signInWithGoogle,
            createUserWithEmailAndPassword,
            sendPasswordResetEmail,
            signOut,
            isAboutSkillifyAccessible,
            setIsAboutSkillifyAccessible,
        ],
    );

    return (
        <authUserContext.Provider value={authUserContextValue}>
            {children}
        </authUserContext.Provider>
    );
};

export default AuthProvider;

export const useAuthProvider = () => {
    const context = useContext(authUserContext);
    if (!context) {
        throw new Error('useAuthProvider must be used within a AuthProvider');
    }
    return context;
};
