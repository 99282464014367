import React, { useCallback, useEffect, useState } from 'react';
import FormRenderer from '@data-driven-forms/react-form-renderer/form-renderer';
import type { SignUp } from '@digico/common/lib/SignUp';
import { useInnerWindowWidth } from '@lib/hooks/useInnerWindowWidth';
import {
    CommonSignupValues,
    DigicoUser,
    GoogleSignupValues,
    ManualSignupValues,
} from '@lib/types';
import { FormTemplateSignUp } from '@components/FormComponents/SignUp/FormTemplateSignUp';
import { useAuthProvider } from '@providers/AuthProvider';
import { useRouter } from 'next/router';
import { LoaderIcon } from '@components/Loader';
import { useIsLoading } from '@lib/hooks/useIsLoading';
import { redirectToStudentPortal } from '@lib/helper/redirectToStudentPortal';
import {
    signUpComponentMapper,
    SignUpStageOneSchema,
    SignUpStageTwoSchema,
} from '@components/FormComponents/SignUp';
import { SignUpStageThreeSchema } from '@components/FormComponents/SignUp/schemas';
import { useGlobalStoreProvider } from '@providers/GlobalStoreProvider';
import { parseQueryToString } from '@lib/helper/parseQueryToString';
import { DIGICO_DEFAULT_ASSESSMENT, DIGICO_PARTNER_ID } from '@lib/constants';
import { addStudentIdToResultGroup } from '@lib/helper/addStudentIdToResultGroup';

interface SignUpContainerProps {
    signUpText: SignUp | undefined;
}

const addStudentIdAndRedirect = async (user: DigicoUser) => {
    const resultGroupId = localStorage.getItem('resultGroupId');
    if (user.studentId && resultGroupId) {
        await addStudentIdToResultGroup(user.studentId, resultGroupId);
    }
    if (user && resultGroupId) {
        await redirectToStudentPortal(user, resultGroupId);
    }
};

export const SignUpContainer: React.FC<SignUpContainerProps> = ({
    signUpText,
}: SignUpContainerProps) => {
    const {
        createUserWithEmailAndPassword,
        signInWithGoogle,
        signInWithEmailAndPassword,
    } = useAuthProvider();
    const [step, setStep] = useState(1);
    const [displayAlert, setDisplayAlert] = useState(false);
    const [existsError, setExistsError] = useState<boolean>(false);
    const [isHandlingSubmit, setIsHandlingSubmit] = useState<boolean>();
    const [progressbarWidth, setProgressbarWidth] = useState('33%');
    const router = useRouter();
    const { assessmentId } = router.query;
    const innerWindowWidth = useInnerWindowWidth();
    const isLoading = useIsLoading([isHandlingSubmit]);
    const [formValues, setFormValues] = useState<CommonSignupValues>();
    const { currentPartner } = useGlobalStoreProvider();
    const googleSignUp = useCallback(
        async (values: GoogleSignupValues) => {
            try {
                localStorage.setItem('creatingUser', 'true');
                const curpartnerId = currentPartner?.id ?? DIGICO_PARTNER_ID;
                values.partnerId = curpartnerId;
                values.assessmentId =
                    parseQueryToString(assessmentId) ??
                    DIGICO_DEFAULT_ASSESSMENT; // TODO: Use correct assessmentID, get from URI
                const [user] = await signInWithGoogle(values);
                await addStudentIdAndRedirect(user);

                // Fallback redirect
                router.push('/');
            } catch (err) {
                console.error(err);
            } finally {
                localStorage.removeItem('creatingUser');
            }
        },
        [router, signInWithGoogle],
    );

    const validateSignUpFormData = (values: ManualSignupValues) =>
        Object.entries(values).some(([name, value]: [string, string]) => {
            if (value.length === 0) return false;
            if (
                name === 'email' &&
                !value.match(/^[A-Z\d._%+-]+@[A-Z\d.-]+\.[A-Z]{2,10}$/i)
            )
                return false;
            if (
                name === 'password' &&
                !value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,50}$/)
            )
                return false;
            return !(name === 'confirmPassword' && values.password !== value);
        });

    const submitHandler = useCallback(
        async (values: ManualSignupValues) => {
            if (!values) return;
            setIsHandlingSubmit(true);
            // Check validity of inputs
            const valid = validateSignUpFormData(values);
            if (!valid) {
                setIsHandlingSubmit(false);
                setDisplayAlert(true);
                return;
            }

            // Submit won't finish until the last step is reached
            if (step !== 3) {
                setFormValues(values);
                setIsHandlingSubmit(false);
                setStep((prevStep) => prevStep + 1);
                return;
            }
            // Get the email and password data to sign up
            try {
                values.partnerId = currentPartner?.id ?? DIGICO_PARTNER_ID;
                values.assessmentId =
                    parseQueryToString(assessmentId) ??
                    DIGICO_DEFAULT_ASSESSMENT; // TODO: Use partnerID when applicable!
                const [user] = await createUserWithEmailAndPassword(values);
                await addStudentIdAndRedirect(user);

                // Fallback redirect
                router.push('/');
                setIsHandlingSubmit(false);
            } catch (err: any) {
                console.error(err);
                if (err.message.includes('email-already-in-use')) {
                    const { password, emailAddress } = values;
                    if (!emailAddress || !password) {
                        setIsHandlingSubmit(false);
                        return;
                    }
                    await signInWithEmailAndPassword(emailAddress, password)
                        .then(async (user) => {
                            await addStudentIdAndRedirect(user);
                            setExistsError(false);
                        })
                        .catch((error) => {
                            console.error(error);
                            setExistsError(true);
                        });
                }
                setIsHandlingSubmit(false);
            } finally {
                localStorage.removeItem('creatingUser');
            }
        },
        [step, createUserWithEmailAndPassword, signInWithEmailAndPassword],
    );

    useEffect(() => {
        if (step === 1) setProgressbarWidth('tw-w-[calc(33.33%+7px)]');
        if (step === 2) setProgressbarWidth('tw-w-[calc(66.66%+9px)]');
        if (step === 3) setProgressbarWidth('tw-w-full');
    }, [step]);

    const formTemplate = useCallback(
        ({ formFields }) => (
            <FormTemplateSignUp
                formFields={formFields}
                step={step}
                text={signUpText}
                back={() => setStep((prevStep) => prevStep - 1)}
                googleSignUp={googleSignUp}
            />
        ),
        [signUpText, step, googleSignUp],
    );

    const pageTitle =
        step === 1
            ? signUpText?.pageTitle?.step1
            : step === 2
            ? signUpText?.pageTitle?.step2
            : signUpText?.pageTitle?.step3;

    return (
        <>
            {isLoading && <LoaderIcon />}
            <div className="tw-pb-6">
                <div className="tw-flex tw-justify-between tw-pb-2">
                    <div className="tw-flex tw-flex-col tw-justify-center">
                        <h1 className="skillify-font tw-m-0  tw-text-xs tw-font-semibold tw-text-skillify-color/40 xl:tw-text-base">
                            {signUpText?.progressBar?.title}
                            <span className="tw-sr-only">.</span>
                        </h1>
                        <h2 className="skillify-font tw-m-0 tw-text-sm tw-font-semibold tw-text-skillify-color md:tw-text-lg md:tw-font-bold">
                            {signUpText?.progressBar?.subTitle?.replace(
                                /{x}/,
                                step.toString(),
                            )}
                            <span className="tw-sr-only">.</span>
                        </h2>
                    </div>
                </div>
                <div className=" tw-relative tw-flex tw-h-[0.9rem] tw-overflow-hidden tw-rounded-[0.45rem] tw-bg-skillify-color-light">
                    <div
                        className={`tw-flex tw-h-[0.9rem] tw-items-center tw-justify-around tw-rounded tw-bg-skillify-color ltr:tw-rounded-r-[0.45rem]  rtl:tw-rounded-l-[0.45rem] ${progressbarWidth}`}
                    />
                    <div className="tw-absolute tw-flex tw-h-full tw-w-full tw-items-center tw-justify-evenly">
                        <div className="tw-h-2 tw-w-2 tw-rounded-full tw-bg-white" />
                        <div
                            className={`tw-h-2 tw-w-2 tw-rounded-full ${
                                step === 1
                                    ? 'tw-bg-skillify-color'
                                    : 'tw-bg-white'
                            }`}
                        />
                    </div>
                </div>
            </div>
            <div className="tw-flex tw-flex-col lg:tw-m-6">
                <h1 className="skillify-font tw-text-lg tw-font-bold md:tw-text-xl">
                    {pageTitle}:
                </h1>
                <div className="tw-flex tw-w-full tw-flex-col tw-items-center">
                    <div className="tw-w-full tw-py-4">
                        <FormRenderer
                            FormTemplate={formTemplate}
                            componentMapper={signUpComponentMapper}
                            schema={
                                step === 1
                                    ? SignUpStageOneSchema(
                                          signUpText,
                                          innerWindowWidth,
                                          formValues,
                                      )
                                    : step === 2
                                    ? SignUpStageTwoSchema(
                                          signUpText,
                                          formValues,
                                      )
                                    : SignUpStageThreeSchema(signUpText)
                            }
                            onSubmit={(values) =>
                                submitHandler(values as ManualSignupValues)
                            }
                        />
                        {displayAlert && (
                            <p className="tw-text-red-600">
                                {signUpText?.signUpErrorMessage}
                            </p>
                        )}
                        {existsError && (
                            <p className="tw-text-red-600">
                                {signUpText?.alreadyExistsErrorMessage}
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
