//  TODO:  Create a custom loader to optimize images for firebase
//  NOTE:  Could use next/image

import React, { ImgHTMLAttributes } from 'react';

export const Image = (props: ImgHTMLAttributes<HTMLImageElement>) => {
    const [error, setError] = React.useState(false);

    const { src, alt } = props;
    if (error || (src as string).endsWith('undefined?alt=media')) {
        return null;
    }

    return (
        // eslint-disable-next-line @next/next/no-img-element
        <img
            onError={(err) => {
                console.error(err);
                setError(true);
            }}
            alt={alt}
            {...props}
        />
    );
};
