import { Image } from '@components/Image';
import React from 'react';

interface CompetencySubItemProps {
    name: string | undefined;
}

export const CompetencySubItem: React.FC<CompetencySubItemProps> = ({
    name,
}: CompetencySubItemProps) => (
    <div className="tw-flex tw-min-h-[4.125rem] tw-min-h-[6rem] tw-cursor-pointer tw-select-none tw-flex-col tw-justify-between tw-rounded-md tw-border-2 tw-border-solid tw-px-2 tw-py-3 sm:tw-px-4 sm:tw-py-4">
        <p className="skillify-font tw-mb-2 tw-max-w-[7.5rem] tw-text-xs tw-font-semibold">
            {name}
        </p>
        <div className="tw-flex tw-w-full tw-justify-end">
            <Image
                className="rtl:tw-rotate-180"
                src="/images/arrowRightWhite.svg"
                alt="arrow right"
                width={15}
            />
        </div>
    </div>
);
