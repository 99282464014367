import { Score } from '@lib/types';

/**
 * Contextual switch that checks if a value is matched against a predicate
 * and returns a desired action
 *
 * @param {any} x The value to match
 * @returns {any} The desired operation to execute
 */
const match = (x: any) => ({
    on: (pred: any, fn: Function) => (pred(x) ? matched(fn(x)) : match(x)),
    otherwise: (fn: Function) => fn(x),
});
const matched = (fn: Function) => ({
    on: () => matched(fn),
    otherwise: () => fn,
});
/**
 * Resolves a quantitative value into a qualitative value
 *
 * @param {number | string} scorePercentage The score percentage value
 * @returns {Score} A qualitative assessment of the number score
 */
export const mapScore = (scorePercentage: number | string): Score =>
    match(scorePercentage)
        .on(
            (score: number | string) => Number(score) < 0.3,
            () => Score.LOW,
        )
        .on(
            (score: number | string) => Number(score) < 0.48,
            () => Score.FOUNDATION,
        )
        .on(
            (score: number | string) => Number(score) < 0.81,
            () => Score.INTERMEDIATE,
        )
        .on(
            (score: number | string) => Number(score) <= 1,
            () => Score.ADVANCED,
        )
        .otherwise(() => new Error(`Invalid score ${scorePercentage}`));
