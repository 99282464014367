import { useFormApi } from '@data-driven-forms/react-form-renderer';
import { SkillifyButton } from '@components/SkillifyButton';
import { CustomLink } from '@components/CustomLink';
import React, { ReactElement, useEffect, useState } from 'react';
import { Image } from '@components/Image';
import { GoogleSignupValues } from '@lib/types';
import type { SignUp } from '@digico/common/lib/SignUp';
import { ButtonSize } from '@components/SkillifyButton/SkillifyButton';
import { useInnerWindowWidth } from '@lib/hooks/useInnerWindowWidth';
import { useEffectOnMount } from '@lib/hooks/useEffectOnMount';
import { getImageUrl } from '@lib/helper/getImageUrl';
import { capitalizeFirstLetter } from '@lib/helper/capitalizeFirstLetter';

interface FormTemplateSignUpProps {
    formFields: ReactElement[];
    step: number;
    text: SignUp | undefined;
    back: () => void;
    googleSignUp: (values: GoogleSignupValues) => Promise<void>;
}

export const FormTemplateSignUp: React.FC<FormTemplateSignUpProps> = ({
    formFields,
    step,
    text,
    back,
    googleSignUp,
}: FormTemplateSignUpProps) => {
    const { handleSubmit, getState } = useFormApi();
    const [spanFormField, setSpanFormField] = useState<ReactElement>();
    const [remainingFormFields, setRemainingFormFields] =
        useState<ReactElement[]>();
    const [buttonSize, setButtonSize] = useState<ButtonSize>('sm');
    const innerWindowWidth = useInnerWindowWidth();

    const alreadyAnAccount = (
        <p className="skillify-font tw-text-xs tw-font-semibold tw-text-skillify-color lg:tw-text-base">
            {text?.signInCTA?.instruction}{' '}
            <CustomLink className="tw-underline" href="/sign-in">
                {text?.signInCTA?.linkText}
            </CustomLink>
        </p>
    );

    useEffectOnMount(() => {
        if (step === 1) {
            setSpanFormField(formFields.pop());
            setRemainingFormFields(formFields);
        }
        if (step === 3) {
            setSpanFormField(formFields.shift());
            setRemainingFormFields(formFields);
        }
    });

    useEffect(() => {
        if (innerWindowWidth) {
            innerWindowWidth > 991 ? setButtonSize('md') : setButtonSize('sm');
        }
    }, [innerWindowWidth]);

    return (
        <form
            noValidate // Validation is handled by form renderer
            onSubmit={(event) => {
                event.preventDefault();
                handleSubmit();
            }}
        >
            <div
                className={`lg:tw-flex ${
                    step === 2 ? 'lg:tw-w-fit' : 'lg:tw-w-full'
                }`}
            >
                <div className="tw-w-full">
                    <div className="tw-w-full xl:tw-grid xl:tw-grid-cols-2 xl:tw-gap-x-8">
                        {step === 1 && (
                            <>
                                {remainingFormFields}
                                <div className="tw-col-span-2">
                                    {spanFormField}
                                </div>
                            </>
                        )}
                        {step === 2 && formFields}
                        {step === 3 && (
                            <>
                                <div className="tw-col-span-2">
                                    {spanFormField}
                                </div>
                                {remainingFormFields}
                            </>
                        )}
                    </div>
                    {step === 3 && (
                        <div className="tw-pb-8 xl:tw-w-fit xl:tw-min-w-[55%]">
                            <div className="tw-py-4">
                                <SkillifyButton buttonType="primary" isSubmit>
                                    <span className="skillify-font tw-text-base md:tw-text-lg lg:tw-text-xl">
                                        {text?.buttonText?.signUp}
                                    </span>
                                </SkillifyButton>
                                <p className="skillify-font tw-my-2 tw-text-lg tw-font-semibold md:tw-text-xl">
                                    {capitalizeFirstLetter(text?.orText ?? '')}:
                                </p>
                                <SkillifyButton
                                    buttonType="google"
                                    onClick={() =>
                                        googleSignUp(
                                            getState()
                                                .values as GoogleSignupValues,
                                        )
                                    }
                                >
                                    <span className="skillify-font tw-text-base md:tw-text-lg">
                                        {text?.signUpWithGoogle}{' '}
                                    </span>
                                </SkillifyButton>
                            </div>
                            {alreadyAnAccount}
                        </div>
                    )}
                </div>
                {step === 1 && (
                    <div className="tw-hidden tw-w-full tw-items-center tw-justify-center lg:tw-flex">
                        <Image
                            className="tw-mx-8 tw-h-fit tw-w-72"
                            src={getImageUrl(text?.images?.step1)}
                            alt="sign up image"
                        />
                    </div>
                )}
                {step === 3 && (
                    <div className="tw-hidden tw-w-full tw-items-center tw-justify-center lg:tw-flex">
                        <Image
                            className="tw-mx-8 tw-h-fit tw-w-96"
                            src={getImageUrl(text?.images?.step3)}
                            alt="sign up image"
                        />
                    </div>
                )}
            </div>
            <div className="tw-flex tw-flex-col">
                <div
                    className={`tw-flex tw-w-full tw-items-center tw-py-4 ${
                        step === 1
                            ? 'tw-justify-end tw-gap-6'
                            : 'tw-justify-between'
                    }`}
                >
                    {step !== 1 && (
                        <SkillifyButton
                            isBackButton
                            buttonType="primary"
                            onClick={back}
                            size={buttonSize}
                        >
                            {text?.buttonText?.back}
                        </SkillifyButton>
                    )}
                    {step === 1 && (
                        <div className="lg:tw-hidden">{alreadyAnAccount}</div>
                    )}
                    {step !== 3 && (
                        <div className="tw-min-w-fit lg:tw-flex lg:tw-items-center lg:tw-gap-6">
                            <div className="tw-hidden lg:tw-flex">
                                {alreadyAnAccount}
                            </div>
                            <SkillifyButton
                                buttonType="primary"
                                isSubmit
                                isNextButton
                                size={buttonSize}
                            >
                                {text?.buttonText?.next}
                            </SkillifyButton>
                        </div>
                    )}
                </div>
            </div>
            {step === 2 && (
                <div className="lg:tw-hidden">{alreadyAnAccount}</div>
            )}
        </form>
    );
};
