import React, { useCallback, useEffect, useState } from 'react';
import { useAssessmentToolProvider } from '@providers/AssessmentToolProvider';
import { useRouter } from 'next/router';
import { Area } from '@lib/types';
import { Modal } from '@components/Modal/Modal';
import { SkillifyButton } from '@components/SkillifyButton';
import {
    getProgressPercentageForArea,
    ProgressBar,
} from '../ProgressBar/ProgressBar';

interface ProgressBarContainerProps {}

export const ProgressBarContainer: React.FC<ProgressBarContainerProps> = () => {
    const [showExitModal, setShowExitModal] = useState(false);
    const [percent, setPercent] = useState(0);
    const [progressBars, setProgressBars] = useState<JSX.Element[] | undefined>(
        [],
    );

    const {
        areasQuestions,
        globalQuestionIndex,
        studentAnswers,
        globalAreaIndex,
        partnerAssessment,
    } = useAssessmentToolProvider();
    const router = useRouter();

    const areasProgress = areasQuestions?.map((item: Area) => ({
        areaId: item.id ?? '',
        progress: 0,
        questionsLength: item.questions?.length,
    }));

    const goBackToIntro = useCallback(() => {
        const { partnerId, assessmentId } = router.query;
        router.push(`/assessment-tool/${partnerId}/${assessmentId}`);
    }, [router]);

    const normalQuestionsLength = areasQuestions?.map(
        (areaQuestions) =>
            areaQuestions.questions.filter((item) => item?.type === 'normal')
                .length,
    );
    const verificationQuestionsLength = areasQuestions?.map(
        (areaQuestions) => areaQuestions.competencies.length,
    );
    const questionsLength: number[] = [];

    if (areasQuestions) {
        for (let i = 0; i < areasQuestions?.length ?? 0; i++) {
            if (verificationQuestionsLength && normalQuestionsLength) {
                questionsLength[i] =
                    verificationQuestionsLength[i] +
                    normalQuestionsLength[i] -
                    2 * globalAreaIndex; // We take always 2 verification questions into account.
            }
        }
    }

    useEffect(() => {
        const mappedProgressBars = areasProgress?.map((areaProgress, index) => (
            <ProgressBar
                key={areaProgress.areaId}
                globalQuestionIndex={globalQuestionIndex}
                areaProgress={areaProgress}
                questionsLength={questionsLength![index]}
                studentAnswers={studentAnswers}
                firstArea={index === 0}
                currentArea={index === globalAreaIndex}
            />
        ));
        setProgressBars(mappedProgressBars);
        // Do not add the areaProgress in the dependencies because it will create an infinitive loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalQuestionIndex, studentAnswers, globalAreaIndex]);

    let percentageComplete = partnerAssessment?.main?.completion;
    percentageComplete = percentageComplete?.replace(
        /{x}/i,
        percent.toString(),
    );

    useEffect(() => {
        if (questionsLength) {
            const percentTotal = areasProgress
                ?.map((areaProgress, index) =>
                    getProgressPercentageForArea(
                        questionsLength[index],
                        areaProgress.areaId,
                        studentAnswers,
                    ),
                )
                .reduce((a, b) => a + b, 0);
            if (areasProgress !== undefined && percentTotal !== undefined) {
                setPercent(
                    Math.round((1 / areasProgress.length) * percentTotal),
                );
            }
        }
    }, [globalQuestionIndex, areasProgress, studentAnswers, questionsLength]);

    return (
        <>
            {showExitModal && (
                <Modal
                    text={partnerAssessment?.exitAssessmentModal?.text}
                    cancelButton={{
                        text: partnerAssessment?.exitAssessmentModal
                            ?.cancelButton,
                        onClick: () => setShowExitModal(false),
                    }}
                    confirmButton={{
                        text: partnerAssessment?.exitAssessmentModal
                            ?.confirmButton,
                        onClick: goBackToIntro,
                        buttonType: 'secondary',
                    }}
                />
            )}
            <div className="tw-mb-10">
                <div className="tw-flex tw-flex-row-reverse tw-justify-between  tw-pb-4">
                    <div className="tw-flex tw-items-center">
                        <SkillifyButton
                            className="md:tw-text-md tw-text-xs"
                            onClick={() => setShowExitModal(true)}
                        >
                            {partnerAssessment?.main?.cta?.exitAssessment}
                        </SkillifyButton>
                    </div>
                    <div className="tw-flex tw-flex-col tw-justify-center">
                        <p className="skillify-font tw-m-0 tw-hidden tw-text-xs tw-font-semibold tw-text-skillify-color/40 md:tw-inline">
                            {partnerAssessment?.main?.yourProgress}
                        </p>
                        <p className="skillify-font tw-m-0 tw-text-sm tw-font-semibold tw-text-skillify-color md:tw-text-lg md:tw-font-bold">
                            {percentageComplete}
                        </p>
                    </div>
                </div>
                <div className="tw-relative tw-flex tw-h-[0,9rem] tw-rounded-[0.45rem] tw-bg-skillify-color-light">
                    {progressBars}
                </div>
            </div>
        </>
    );
};
