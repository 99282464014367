import React, { useCallback, useEffect, useRef, useState } from 'react';
import Router, { useRouter } from 'next/router';
import { useGlobalStoreProvider } from '@providers/GlobalStoreProvider';
import {
    DIGICO_DEFAULT_ASSESSMENT,
    DIGICO_JOBS,
    DIGICO_PARTNER_ID,
} from '@lib/constants';
import type { NavigationItems } from '@digico/common/lib/NavigationItems';
import { useAuthProvider } from '@providers/AuthProvider';
import { useLanguageProvider } from '@providers/LanguageProvider';
import { CustomLink } from '@components/CustomLink';
import { SkillifyButton } from '@components/SkillifyButton';
import { CountryCodesDropDown } from '@components/DropDown';

interface NavigationProps {
    navigationItemsContent?: NavigationItems;
    linkToAssessmentTool?: string;
}

export const Navigation: React.FC<NavigationProps> = ({
    navigationItemsContent,
    linkToAssessmentTool,
}: NavigationProps) => {
    const { signOut, isUserLoggedIn, isAboutSkillifyAccessible, authUser } =
        useAuthProvider();
    const { countryCode } = useLanguageProvider();
    const { studentResultsData } = useGlobalStoreProvider();
    const [mobileMenuOpened, setMobileMenuOpened] = useState<boolean>(false);
    const menuButton = useRef(null);
    const router = useRouter();

    let partnerId =
        authUser?.partnerId && authUser?.partnerId !== ''
            ? authUser?.partnerId
            : router.query.partnerId ?? DIGICO_PARTNER_ID;

    if (typeof partnerId === 'object') {
        // There's a mismatch in type of partnerId, it is supposed to be a string but it is sometimes an object...
        partnerId = (partnerId as any).id;
    }

    const initialAssessmentId =
        authUser?.assessmentId && authUser?.assessmentId.trim() !== ''
            ? authUser?.assessmentId
            : DIGICO_DEFAULT_ASSESSMENT;

    const assessmentToolLink =
        linkToAssessmentTool ??
        `/assessment-tool/${partnerId}/${initialAssessmentId}?lang=${countryCode}`;

    useEffect(() => {
        if (mobileMenuOpened) {
            document.body.style.overflow = 'hidden';
        }
        if (!mobileMenuOpened) {
            const menu: any = menuButton.current;
            document.body.style.overflow = 'unset';
            if (menu && menu.checked) {
                menu.checked = false;
            }
        }
    }, [isUserLoggedIn, mobileMenuOpened]);

    const getAssessmentId = () => {
        if (studentResultsData && studentResultsData.length > 0)
            return studentResultsData[0].assessmentId;
        return '';
    };

    const language = countryCode;
    const assessmentId = getAssessmentId();

    const signOutCallback = useCallback(async () => {
        router.push(assessmentToolLink).then(async () => {
            try {
                setMobileMenuOpened(false);
                await signOut();
            } catch (err) {
                console.error(err);
            }
        });
    }, [assessmentToolLink, router, signOut]);

    return (
        <>
            <input
                ref={menuButton}
                tabIndex={0}
                className="menu-btn"
                type="checkbox"
                id="menu-btn"
                aria-label="navigation menu"
                onChange={() => setMobileMenuOpened(!mobileMenuOpened)}
            />
            <label className="menu-icon" htmlFor="menu-btn">
                <span className="nav-icon" />
            </label>
            <nav className="menu tw-my-1 tw-items-center tw-start-0 lg:tw-start-auto">
                <ul>
                    {isUserLoggedIn && assessmentId !== '' && (
                        <li className="nav-item">
                            <CustomLink
                                href={`/learning-content?lang=${language}&partnerId=${partnerId}&assessmentId=${assessmentId}&view=summary`}
                                className="nav-item"
                                onClick={() => {
                                    setMobileMenuOpened(false);
                                    window.sessionStorage.clear();
                                }}
                            >
                                {navigationItemsContent?.userDashboard ||
                                    'My Results'}
                            </CustomLink>
                        </li>
                    )}
                    {isUserLoggedIn && (
                        <li className="nav-item">
                            <CustomLink
                                href={`/courses?lang=${language}`}
                                className="nav-item"
                                onClick={() => {
                                    setMobileMenuOpened(false);
                                    window.sessionStorage.clear();
                                }}
                            >
                                {navigationItemsContent?.courses || 'Courses'}
                            </CustomLink>
                        </li>
                    )}
                    {/* For now, we hide it on production */}
                    {isUserLoggedIn && (
                        <li className="nav-item">
                            <CustomLink
                                href={
                                    process.env.NODE_ENV === 'development'
                                        ? `https://localhost:4200/user-jobs?partnerId=${partnerId}&lang=${language}`
                                        : `${DIGICO_JOBS}user-jobs?partnerId=${partnerId}&lang=${language}`
                                }
                                className="nav-item"
                                onClick={() => {}}
                            >
                                {navigationItemsContent?.jobs || 'Jobs'}
                            </CustomLink>
                        </li>
                    )}
                    <li className="nav-item">
                        <CustomLink
                            href={assessmentToolLink}
                            className="nav-item"
                            onClick={() => {
                                setMobileMenuOpened(false);
                                window.sessionStorage.clear();
                            }}
                        >
                            {navigationItemsContent?.assessment ||
                                'Self-assessment'}
                        </CustomLink>
                    </li>
                    {isAboutSkillifyAccessible && (
                        <li className="nav-item">
                            <CustomLink
                                href="/about-digcomp"
                                className="nav-item"
                                onClick={() => {
                                    setMobileMenuOpened(false);
                                }}
                            >
                                {navigationItemsContent?.aboutDigComp ||
                                    'About Skillify'}
                            </CustomLink>
                        </li>
                    )}
                </ul>
                <ul>
                    <li className="nav-item">
                        <SkillifyButton
                            className="tw-my-1 tw-flex tw-justify-center lg:tw-my-4"
                            onClick={() =>
                                router.push(assessmentToolLink).then(() => {
                                    setMobileMenuOpened(false);
                                    window.sessionStorage.clear();
                                })
                            }
                            isRound
                        >
                            <span className="skillify-font tw-inline-block tw-text-base tw-font-medium tw-leading-5">
                                {router.pathname.includes('learning-content')
                                    ? navigationItemsContent?.startNewTest ||
                                      'Start new test'
                                    : navigationItemsContent?.testMySkills ||
                                      'Test my skills'}
                            </span>
                        </SkillifyButton>
                    </li>
                    {isUserLoggedIn ? (
                        <li className="nav-item">
                            <SkillifyButton
                                className="tw-my-1 tw-flex tw-justify-center lg:tw-my-4"
                                onClick={signOutCallback}
                                buttonType="secondary"
                                isRound
                            >
                                <span className="skillify-font tw-inline-block tw-text-base tw-font-medium tw-leading-5">
                                    {navigationItemsContent?.signOut ||
                                        'Sign out'}
                                </span>
                            </SkillifyButton>
                        </li>
                    ) : (
                        <li className="nav-item">
                            <SkillifyButton
                                className="tw-my-1 tw-flex tw-justify-center lg:tw-my-4"
                                onClick={() =>
                                    Router.push(`/sign-in/${partnerId}`).then(
                                        () => {
                                            setMobileMenuOpened(false);
                                        },
                                    )
                                }
                                buttonType="secondary"
                                isRound
                            >
                                <span className="skillify-font tw-inline-block tw-text-base tw-font-medium tw-leading-5">
                                    {navigationItemsContent?.signIn ||
                                        'Sign in'}
                                </span>
                            </SkillifyButton>
                        </li>
                    )}

                    <li className="nav-item tw-my-0 lg:tw-my-2">
                        <CountryCodesDropDown />
                    </li>
                </ul>
            </nav>
        </>
    );
};
