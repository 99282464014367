import React from 'react';
import type { AssessmentArea } from '@digico/common/lib/AssessmentArea';
import { CompetencySubItem } from '@components/AssessmentTool/CompetencySubItem';

interface CompetencyItemProps {
    onClick: () => void;
    area: AssessmentArea;
}
export const CompetencyItem: React.FC<CompetencyItemProps> = ({
    onClick,
    area,
}: CompetencyItemProps) => (
    <div role="menuitem" tabIndex={0} onClick={onClick} onKeyPress={onClick}>
        <CompetencySubItem name={area.name} />
    </div>
);
