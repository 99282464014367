import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    Area,
    AssessmentCompentencyIdWithScore,
    AssessmentToolContextType,
    CurrentAnswer,
    PartnerAssessment,
    ResultsData,
    StudentAnswer,
    VerificationQuestionWithType,
} from '@lib/types';
import { useRouter } from 'next/router';
import { useEffectOnMount } from '@lib/hooks/useEffectOnMount';

const AssessmentToolContext = createContext<
    AssessmentToolContextType | undefined
>(undefined);

const AssessmentToolProvider: React.FunctionComponent = ({ children }) => {
    const router = useRouter();
    const [partnerAssessment, setPartnerAssessment] =
        useState<PartnerAssessment>(null);
    const [areasQuestions, setAreasQuestions] = useState<Area[] | undefined>(
        [],
    );
    const [studentVerificationAnswers, setStudentVerificationAnswers] =
        useState<StudentAnswer[]>([]);
    const [globalQuestionIndex, setGlobalQuestionIndex] = useState<number>(0);
    const [metaQuestions, setMetaQuestions] = useState<
        VerificationQuestionWithType[]
    >([]);
    const [globalAreaIndex, setGlobalAreaIndex] = useState<number>(0);
    const [areaVerificationQuestionIndex, setAreaVerificationQuestionIndex] =
        useState<number>(0);
    const [studentAnswers, setStudentAnswers] = useState<StudentAnswer[]>([]);
    const [currentAnswer, setCurrentAnswer] = useState<CurrentAnswer>(null);
    const [upcomingVerificationQuestion, setUpcomingVerificationQuestion] =
        useState<VerificationQuestionWithType | null>(null);
    const [
        verificationQuestionAssessmentCompetencyIdsProcessed,
        setVerificationQuestionAssessmentCompetencyIdsProcessed,
    ] = useState<AssessmentCompentencyIdWithScore[]>([]);
    const [currentAnswerIndex, setCurrentAnswerIndex] = useState<number | null>(
        null,
    );
    const [isLoadingResults, setLoadingResults] = useState(false);
    const [resultsData, setResultsData] = useState<ResultsData[]>([]);
    const [triggerCreateResults, setCreateResults] = useState<boolean>(false);
    const [verificationQuestionIsClicked, setVerificationQuestionIsClicked] =
        useState(false);
    const [watchTutorial, setWatchTutorial] = useState(false);
    const [hasWatchedTutorial, setHasWatchedTutorial] = useState(false);
    const [isIntro, setIsIntro] = useState(false);
    const [showPrivacyGuidelines, setShowPrivacyGuidelines] = useState(false);
    const [showMotivation, setShowMotivation] = useState(false);
    const [redirectToResults, setRedirectToResults] = useState(false);
    const [showSignup, setShowSignup] = useState(false);
    const [
        areasQuestionsArrayHasBeenBuild,
        setAreasQuestionsArrayHasBeenBuild,
    ] = useState(false);

    const resetAssessmentState = useCallback((intro: boolean) => {
        let currentIndex = window.sessionStorage.getItem('globalQuestionIndex')
            ? Number(window.sessionStorage.getItem('globalQuestionIndex'))
            : 0;
        if (intro !== false) {
            currentIndex = 0;
        }
        setGlobalAreaIndex(0);
        setGlobalQuestionIndex(currentIndex);
        setStudentAnswers([]);
        setHasWatchedTutorial(false);
        setAreasQuestions([]);
        setIsIntro(intro);
        setAreasQuestionsArrayHasBeenBuild(false);
    }, []);

    const storeMemo = useMemo<AssessmentToolContextType>(
        () => ({
            resetAssessmentState,
            studentVerificationAnswers,
            setStudentVerificationAnswers,
            partnerAssessment,
            setPartnerAssessment,
            areasQuestions,
            setAreasQuestions,
            areaVerificationQuestionIndex,
            setAreaVerificationQuestionIndex,
            upcomingVerificationQuestion,
            setUpcomingVerificationQuestion,
            globalQuestionIndex,
            setGlobalQuestionIndex,
            globalAreaIndex,
            setGlobalAreaIndex,
            studentAnswers,
            setStudentAnswers,
            currentAnswer,
            setCurrentAnswer,
            currentAnswerIndex,
            setCurrentAnswerIndex,
            isLoadingResults,
            setLoadingResults,
            resultsData,
            setResultsData,
            triggerCreateResults,
            setCreateResults,
            verificationQuestionIsClicked,
            setVerificationQuestionIsClicked,
            watchTutorial,
            setWatchTutorial,
            hasWatchedTutorial,
            setHasWatchedTutorial,
            isIntro,
            setIsIntro,
            showPrivacyGuidelines,
            setShowPrivacyGuidelines,
            showMotivation,
            setShowMotivation,
            redirectToResults,
            setRedirectToResults,
            showSignup,
            setShowSignup,
            areasQuestionsArrayHasBeenBuild,
            setAreasQuestionsArrayHasBeenBuild,
            metaQuestions,
            setMetaQuestions,
            verificationQuestionAssessmentCompetencyIdsProcessed,
            setVerificationQuestionAssessmentCompetencyIdsProcessed,
        }),
        [
            resetAssessmentState,
            studentVerificationAnswers,
            setStudentVerificationAnswers,
            partnerAssessment,
            setPartnerAssessment,
            areasQuestions,
            setAreasQuestions,
            globalQuestionIndex,
            setGlobalQuestionIndex,
            areaVerificationQuestionIndex,
            setAreaVerificationQuestionIndex,
            globalAreaIndex,
            setGlobalAreaIndex,
            studentAnswers,
            setStudentAnswers,
            currentAnswer,
            setCurrentAnswer,
            currentAnswerIndex,
            upcomingVerificationQuestion,
            setUpcomingVerificationQuestion,
            setCurrentAnswerIndex,
            isLoadingResults,
            setLoadingResults,
            resultsData,
            setResultsData,
            triggerCreateResults,
            setCreateResults,
            verificationQuestionIsClicked,
            setVerificationQuestionIsClicked,
            watchTutorial,
            setWatchTutorial,
            hasWatchedTutorial,
            setHasWatchedTutorial,
            isIntro,
            setIsIntro,
            showPrivacyGuidelines,
            setShowPrivacyGuidelines,
            showMotivation,
            setShowMotivation,
            redirectToResults,
            setRedirectToResults,
            showSignup,
            setShowSignup,
            areasQuestionsArrayHasBeenBuild,
            setAreasQuestionsArrayHasBeenBuild,
            metaQuestions,
            setMetaQuestions,
            verificationQuestionAssessmentCompetencyIdsProcessed,
            setVerificationQuestionAssessmentCompetencyIdsProcessed,
        ],
    );

    useEffect(() => {
        if (showPrivacyGuidelines || showMotivation) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [showPrivacyGuidelines, showMotivation]);

    // ========= Get data from sessionStorage =======
    useEffectOnMount(() => {
        if (window.sessionStorage.getItem('partnerAssessment')) {
            setPartnerAssessment(
                JSON.parse(
                    window.sessionStorage.getItem('partnerAssessment') || '',
                ),
            );
        }
        if (window.sessionStorage.getItem('studentVerificationAnswers')) {
            setStudentVerificationAnswers(
                JSON.parse(
                    window.sessionStorage.getItem(
                        'studentVerificationAnswers',
                    ) || '',
                ),
            );
        }
        if (window.sessionStorage.getItem('areasQuestions')) {
            setAreasQuestions(
                JSON.parse(
                    window.sessionStorage.getItem('areasQuestions') || '[]',
                ),
            );
        }
        if (
            window.sessionStorage.getItem(
                'verificationQuestionAssessmentCompetencyIdsProcessed',
            )
        ) {
            setVerificationQuestionAssessmentCompetencyIdsProcessed(
                JSON.parse(
                    window.sessionStorage.getItem(
                        'verificationQuestionAssessmentCompetencyIdsProcessed',
                    ) || '[]',
                ),
            );
        }
        if (window.sessionStorage.getItem('globalQuestionIndex')) {
            setGlobalQuestionIndex(
                Number(window.sessionStorage.getItem('globalQuestionIndex')),
            );
        }
        if (window.sessionStorage.getItem('globalAreaIndex')) {
            setGlobalAreaIndex(
                Number(window.sessionStorage.getItem('globalAreaIndex')),
            );
        }
        if (window.sessionStorage.getItem('areaVerificationQuestionIndex')) {
            setAreaVerificationQuestionIndex(
                Number(
                    window.sessionStorage.getItem(
                        'areaVerificationQuestionIndex',
                    ),
                ),
            );
        }
        if (window.sessionStorage.getItem('studentAnswers')) {
            setStudentAnswers(
                JSON.parse(
                    window.sessionStorage.getItem('studentAnswers') || '[]',
                ),
            );
        }
        if (window.sessionStorage.getItem('upcomingVerificationQuestion')) {
            setUpcomingVerificationQuestion(
                JSON.parse(
                    window.sessionStorage.getItem(
                        'upcomingVerificationQuestion',
                    ) || '',
                ),
            );
        }
        if (window.sessionStorage.getItem('currentAnswer')) {
            setCurrentAnswer(
                JSON.parse(
                    window.sessionStorage.getItem('currentAnswer') || '',
                ),
            );
        }
        if (window.sessionStorage.getItem('currentAnswerIndex')) {
            setCurrentAnswerIndex(
                Number(window.sessionStorage.getItem('currentAnswerIndex')),
            );
        }
        if (window.sessionStorage.getItem('isLoadingResults')) {
            setLoadingResults(
                JSON.parse(sessionStorage.getItem('isLoadingResults') || ''),
            );
        }
        if (window.sessionStorage.getItem('resultsData')) {
            setResultsData(
                JSON.parse(
                    window.sessionStorage.getItem('resultsData') || '[]',
                ),
            );
        }
        if (window.sessionStorage.getItem('triggerCreateResults')) {
            setCreateResults(
                JSON.parse(
                    window.sessionStorage.getItem('triggerCreateResults') || '',
                ),
            );
        }

        if (window.sessionStorage.getItem('hasWatchedTutorial')) {
            setHasWatchedTutorial(
                JSON.parse(
                    window.sessionStorage.getItem('hasWatchedTutorial') || '',
                ),
            );
        }
        if (
            window.sessionStorage.getItem('isIntro') &&
            router.query.intro !== 'false'
        ) {
            setIsIntro(
                JSON.parse(window.sessionStorage.getItem('isIntro') || ''),
            );
        }
        if (window.sessionStorage.getItem('showPrivacyGuidelines')) {
            setShowPrivacyGuidelines(
                JSON.parse(
                    window.sessionStorage.getItem('showPrivacyGuidelines') ||
                        '',
                ),
            );
        }
        if (window.sessionStorage.getItem('showMotivation')) {
            setShowMotivation(
                JSON.parse(
                    window.sessionStorage.getItem('showMotivation') || '',
                ),
            );
        }
        if (window.sessionStorage.getItem('redirectTotResults')) {
            setRedirectToResults(
                JSON.parse(
                    window.sessionStorage.getItem('showMotivation') || '',
                ),
            );
        }
        if (window.sessionStorage.getItem('showSignup')) {
            setShowSignup(
                JSON.parse(window.sessionStorage.getItem('showSignup') || ''),
            );
        }
    });

    // ========= Save data to sessionStorage =======
    useEffect(() => {
        window.sessionStorage.setItem(
            'partnerAssessment',
            JSON.stringify(partnerAssessment),
        );
        window.sessionStorage.setItem(
            'studentVerificationAnswers',
            JSON.stringify(studentVerificationAnswers),
        );
        window.sessionStorage.setItem(
            'areasQuestions',
            JSON.stringify(areasQuestions),
        );
        window.sessionStorage.setItem(
            'upcomingVerificationQuestion',
            JSON.stringify(upcomingVerificationQuestion),
        );
        window.sessionStorage.setItem(
            'globalQuestionIndex',
            globalQuestionIndex.toString(),
        );
        window.sessionStorage.setItem(
            'areaVerificationQuestionIndex',
            areaVerificationQuestionIndex.toString(),
        );
        window.sessionStorage.setItem(
            'globalAreaIndex',
            globalAreaIndex.toString(),
        );
        window.sessionStorage.setItem(
            'studentAnswers',
            JSON.stringify(studentAnswers),
        );
        window.sessionStorage.setItem(
            'currentAnswerIndex',
            (currentAnswerIndex || '').toString(),
        );
        window.sessionStorage.setItem(
            'isLoadingResults',
            JSON.stringify(isLoadingResults),
        );
        window.sessionStorage.setItem(
            'resultsData',
            JSON.stringify(resultsData),
        );
        window.sessionStorage.setItem(
            'triggerCreateResults',
            JSON.stringify(triggerCreateResults),
        );
        window.sessionStorage.setItem(
            'hasWatchedTutorial',
            JSON.stringify(hasWatchedTutorial),
        );
        window.sessionStorage.setItem('isIntro', JSON.stringify(isIntro));
        window.sessionStorage.setItem(
            'showPrivacyGuidelines',
            JSON.stringify(showPrivacyGuidelines),
        );
        window.sessionStorage.setItem(
            'showMotivation',
            JSON.stringify(showMotivation),
        );
        window.sessionStorage.setItem(
            'verificationQuestionAssessmentCompetencyIdsProcessed',
            JSON.stringify(
                verificationQuestionAssessmentCompetencyIdsProcessed,
            ),
        );
        window.sessionStorage.setItem(
            'redirectToResults',
            JSON.stringify(redirectToResults),
        );
        window.sessionStorage.setItem('showSignup', JSON.stringify(showSignup));
    }, [
        partnerAssessment,
        areasQuestions,
        globalQuestionIndex,
        globalAreaIndex,
        areaVerificationQuestionIndex,
        studentAnswers,
        currentAnswer,
        currentAnswerIndex,
        isLoadingResults,
        resultsData,
        triggerCreateResults,
        verificationQuestionIsClicked,
        hasWatchedTutorial,
        isIntro,
        showPrivacyGuidelines,
        showMotivation,
        studentVerificationAnswers,
        redirectToResults,
        showSignup,
        upcomingVerificationQuestion,
        verificationQuestionAssessmentCompetencyIdsProcessed,
    ]);
    // ========= End -  sessionStorage =======

    return (
        <AssessmentToolContext.Provider value={storeMemo}>
            {children}
        </AssessmentToolContext.Provider>
    );
};

export default AssessmentToolProvider;

export const useAssessmentToolProvider = () => {
    const context = useContext(AssessmentToolContext);
    if (!context) {
        throw new Error(
            'useAssessmentToolProvider must be used within a AssessmentToolProvider',
        );
    }
    return context;
};
