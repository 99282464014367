import componentTypes from '@data-driven-forms/react-form-renderer/component-types';
import { validatorTypes } from '@data-driven-forms/react-form-renderer';
import type { SignUp } from '@digico/common/lib/';
import { TextField } from '@components/FormComponents/Fields/TextField';
import { Select } from '@components/FormComponents/Fields/Select';
import { InputRadio } from '@components/FormComponents/Fields/InputRadio';
import { CommonSignupValues } from '@lib/types';
import { getCountries } from '../../../services/api';

const ageOptions: { value: string; label: string }[] = [];
for (let i = 18; i < 101; i++) {
    ageOptions.push({
        value: i.toString(),
        label: i.toString(),
    });
}

const customValidator =
    (text: SignUp.SignUp | undefined) => (value: string | undefined) =>
        value === undefined
            ? text?.formFields?.required ?? 'Required'
            : undefined;

export const SignUpStageOneSchema = (
    text?: SignUp.SignUp,
    innerWindowWidth?: number,
    formValues?: CommonSignupValues,
) => ({
    fields: [
        {
            name: 'firstName',
            label: text?.formFields?.firstName?.label,
            component: componentTypes.TEXT_FIELD,
            isRequired: true,
            validate: [customValidator(text)],
            type: 'text',
        },
        {
            name: 'lastName',
            label: text?.formFields?.lastName?.label,
            component: componentTypes.TEXT_FIELD,
            isRequired: true,
            validate: [customValidator(text)],
            type: 'text',
        },
        {
            name: 'age',
            component: componentTypes.SELECT,
            label: text?.formFields?.age?.label,
            placeholder: text?.formFields?.age?.placeholder,
            isRequired: true,
            options: ageOptions,
            validate: [customValidator(text)],
            type: 'number',
        },
        {
            name: 'country',
            component: componentTypes.SELECT,
            label: text?.formFields?.country?.label,
            placeholder: text?.formFields?.country?.placeholder,
            options: [],
            loadOptions: getCountries,
            isRequired: true,
            validate: [customValidator(text)],
        },
        {
            name: 'gender',
            cols: innerWindowWidth && innerWindowWidth < 1200 ? 2 : undefined,
            component: componentTypes.RADIO,
            label: text?.formFields?.gender?.label,
            savedValue: formValues?.gender,
            options: [
                {
                    value: 'Male',
                    label: text?.formFields?.gender?.options?.male,
                },
                {
                    value: 'Female',
                    label: text?.formFields?.gender?.options?.female,
                },
                {
                    value: 'Non-binary',
                    label: text?.formFields?.gender?.options?.nonBinary,
                },
                {
                    value: 'Prefer-not-to-say',
                    label: text?.formFields?.gender?.options?.preferNotToSay,
                },
            ],
            isRequired: true,
            validate: [customValidator(text)],
        },
    ],
});

export const SignUpStageTwoSchema = (
    text?: SignUp.SignUp,
    formValues?: CommonSignupValues,
) => ({
    fields: [
        {
            name: 'education',
            component: componentTypes.SELECT,
            label: text?.formFields?.education?.label,
            placeholder: text?.formFields?.education?.placeholder,
            options: [
                {
                    value: 'Primary',
                    label: text?.formFields?.education?.options?.primary,
                },
                {
                    value: 'HighSchool',
                    label: text?.formFields?.education?.options?.highSchool,
                },
                {
                    value: 'College',
                    label: text?.formFields?.education?.options?.college,
                },
                {
                    value: 'Degree',
                    label: text?.formFields?.education?.options?.degree,
                },
                {
                    value: 'Prefer-not-to-say',
                    label: text?.formFields?.education?.options?.preferNotToSay,
                },
            ],
            isRequired: true,
            validate: [customValidator(text)],
        },
        {
            name: 'employment',
            component: componentTypes.SELECT,
            label: text?.formFields?.employment?.label,
            placeholder: text?.formFields?.employment?.placeholder,
            options: [
                {
                    value: 'FullTime',
                    label: text?.formFields?.employment?.options?.fullTime,
                },
                {
                    value: 'PartTime',
                    label: text?.formFields?.employment?.options?.partTime,
                },
                {
                    value: 'Student',
                    label: text?.formFields?.employment?.options?.student,
                },
                {
                    value: 'Retired',
                    label: text?.formFields?.employment?.options?.retired,
                },
                {
                    value: 'Unemployed',
                    label: text?.formFields?.employment?.options?.unemployed,
                },
                {
                    value: 'Prefer-not-to-say',
                    label: text?.formFields?.employment?.options
                        ?.preferNotToSay,
                },
            ],
            isRequired: true,
            validate: [customValidator(text)],
        },
        {
            name: 'benefits',
            component: componentTypes.SELECT,
            label: text?.formFields?.benefits?.label,
            placeholder: text?.formFields?.employment?.placeholder,
            savedValue: formValues?.benefits,
            options: [
                {
                    value: 'Yes',
                    label: text?.formFields?.benefits?.options?.yes,
                },
                {
                    value: 'No',
                    label: text?.formFields?.benefits?.options?.no,
                },
                {
                    value: 'Prefer-not-to-say',
                    label: text?.formFields?.benefits?.options?.preferNotToSay,
                },
            ],
            isRequired: true,
            validate: [customValidator(text)],
        },
    ],
});

export const SignUpStageThreeSchema = (text?: SignUp.SignUp) => ({
    fields: [
        {
            name: 'emailAddress',
            component: componentTypes.TEXT_FIELD,
            label: text?.formFields?.email?.label,
            isRequired: true,
            validate: [
                customValidator(text),
                {
                    type: validatorTypes.PATTERN,
                    pattern: /^[A-Z\d._%+-]+@[A-Z\d.-]+\.[A-Z]{2,10}$/i,
                    message: text?.formFields?.email?.errorMessage,
                },
            ],
            type: 'email',
        },
        {
            name: 'password',
            component: componentTypes.TEXT_FIELD,
            fields: [],
            label: text?.formFields?.password?.label,
            isRequired: true,
            validate: [
                customValidator(text),
                {
                    type: validatorTypes.PATTERN,
                    pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,50}$/,
                    message: text?.formFields?.password?.errorMessage,
                },
            ],
        },
        {
            name: 'confirmPassword',
            component: componentTypes.TEXT_FIELD,
            label: text?.formFields?.confirmPassword?.label,
            isRequired: true,
            validate: [customValidator(text)],
        },
    ],
});

export const signUpComponentMapper = {
    [componentTypes.TEXT_FIELD]: TextField,
    [componentTypes.SELECT]: Select,
    [componentTypes.RADIO]: InputRadio,
};
