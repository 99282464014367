import useFieldApi from '@data-driven-forms/react-form-renderer/use-field-api';
import { Image } from '@components/Image';
import { useState } from 'react';
import { useRouter } from 'next/router';
import { useLanguageProvider } from '@providers/LanguageProvider';
import type { ForgotPassword } from '@digico/common';
import { useDynamicPagesContent } from '@lib/hooks/useDynamicPagesContent';
import { CustomLink } from '@components/CustomLink';

export const TextField = (props: any) => {
    const [isPassword, setInputType] = useState<boolean>(true);
    const {
        label,
        input,
        isRequired,
        type,
        meta: { error, touched },
        placeholderIcon,
        ...rest
    } = useFieldApi(props);
    const router = useRouter();
    const { countryCode } = useLanguageProvider();
    const [resetPasswordText] =
        useDynamicPagesContent<ForgotPassword.ForgotPasswordType>(
            countryCode,
            'forgotPassword',
        );

    return (
        <div className="tw-mb-4">
            <div className="tw-mb-3 tw-flex tw-items-center tw-justify-between ">
                <label
                    className="skillify-font tw-text-base tw-font-semibold tw-text-skillify-color lg:tw-text-lg lg:tw-font-bold"
                    htmlFor={input.name}
                >
                    {label}:
                </label>
                {input?.name?.toLowerCase().includes('password') &&
                router.asPath.includes('sign-in') ? (
                    <CustomLink
                        href="/forgot-password"
                        className="skillify-font tw-m-0 tw-text-2xs tw-font-bold tw-leading-3 tw-text-skillify-color/80 tw-no-underline"
                    >
                        {resetPasswordText?.pageHeader}
                    </CustomLink>
                ) : null}
            </div>
            <div className="tw-relative tw-flex tw-items-center">
                {touched && error && (
                    <div className="tw-absolute tw-my-auto tw-flex tw-h-full tw-items-center tw-end-10">
                        <Image
                            className="tw-h-5 tw-w-5"
                            src="/images/errorInfo.svg"
                            alt="error Info"
                        />
                    </div>
                )}
                {placeholderIcon && (
                    <Image
                        src={placeholderIcon}
                        alt="placeholder Icon"
                        width={25}
                        height={25}
                        className="tw-absolute tw-pe-1 tw-start-2"
                    />
                )}
                <input
                    id={input.name}
                    {...input}
                    {...rest}
                    type={
                        !input?.name?.toLowerCase().includes('password')
                            ? type
                            : isPassword
                            ? 'password'
                            : 'text'
                    }
                    min={
                        type === 'number' && input.name === 'age'
                            ? '16'
                            : undefined
                    }
                    max={
                        type === 'number' && input.name === 'age'
                            ? '101'
                            : undefined
                    }
                    className={`
                    skillify-font
                    tw-flex
                    tw-h-10 
                    tw-w-full 
                    tw-items-center 
                    tw-justify-between 
                    tw-rounded-lg 
                    tw-border-[2.5px]
                    tw-border-solid
                    tw-border-skillify-color
                    tw-py-0
                    tw-text-sm
                    tw-font-semibold
                    tw-text-skillify-color 
                    tw-text-fill-skillify-color 
                    tw-shadow-fill-white
                    lg:tw-text-base
                    ${placeholderIcon ? 'tw-ps-9' : ''}
                    placeholder:tw-text-gray-500
                    focus:tw-outline-none
                    focus:tw-ring-transparent ${
                        touched && error
                            ? '!tw-border-red-500 focus:tw-border-red-500 focus:tw-shadow-form-error'
                            : 'focus:tw-border-skillify-color focus:tw-shadow-form'
                    }`}
                />
                {input?.name?.toLowerCase().includes('password') && (
                    <Image
                        className="tw-absolute tw-cursor-pointer tw-end-4"
                        src="/images/visibilityIcon.svg"
                        alt="visibility Icon"
                        height={24}
                        width={24}
                        onClick={() => setInputType(!isPassword)}
                    />
                )}
            </div>
            {touched && error && (
                <div className="skillify-font tw-inline tw-text-sm tw-font-medium tw-text-red-500">
                    {error}
                </div>
            )}
        </div>
    );
};
