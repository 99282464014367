/**
 * Capitalizes a string
 *
 * @param {string} word The string to capitalize
 * @returns {string} The capitalized string
 */
export const capitalizeFirstLetter = (word: string) => {
    try {
        return word.charAt(0).toUpperCase() + word.slice(1);
    } catch {
        return '';
        // When changing locales the score of results will be NaN for a moment and give an error when trying to be capitalized, which is why this method is wrapped with a try catch.
    }
};
