import type { NextPage } from 'next';

import AssessmentToolProvider from '@providers/AssessmentToolProvider';
import Head from 'next/head';
import React from 'react';
import { SkillifyContainer } from '@components/AssessmentTool/SkillifyContainer';
import { useDisableRightClick } from '@lib/hooks/useDisableRightClick';

export interface PartnerAssessmentPageProps {
    partnerId: string | string[] | null;
    assessmentId: string | string[] | null;
    intro: string | string[] | null;
    areaId: string | string[] | null;
}

const PartnerAssessmentPage: NextPage<PartnerAssessmentPageProps> = ({
    partnerId,
    assessmentId,
    intro,
    areaId,
}: PartnerAssessmentPageProps) => {
    useDisableRightClick();
    return (
        <>
            <Head>
                <title>Digico - Skillify Assessment</title>
                <meta name="description" content="Digico" />
                <link rel="icon" href="/favicon.ico" />
            </Head>
            <div className="tw-mx-8 tw-flex tw-flex-col">
                <AssessmentToolProvider>
                    <SkillifyContainer
                        partnerId={partnerId}
                        intro={intro}
                        areaId={areaId}
                        assessmentId={assessmentId}
                    />
                </AssessmentToolProvider>
            </div>
        </>
    );
};

export default PartnerAssessmentPage;
