import React, { ReactNode } from 'react';
import { Image } from '@components/Image';
import { getImageUrl } from '@lib/helper/getImageUrl';

interface Props {
    children: ReactNode;
    image?: string;
    textWhite: boolean;
}

export const Card = ({ children, image, textWhite }: Props) => (
    <div
        style={{
            background: 'linear-gradient(45deg, #ACA5FF 0%, #958DFD 100%)',
        }}
        className={`tw-rounded-2xl tw-px-6 tw-pt-12 tw-pb-10 sm:tw-px-16 ${
            textWhite ? 'tw-text-white' : 'tw-text-black'
        }`}
    >
        {children}
        {image && (
            <div className="col-md text-center">
                <Image
                    src={getImageUrl(image)}
                    alt="Card Image"
                    className="tw-h-auto tw-w-1/2"
                />
            </div>
        )}
    </div>
);
