import React from 'react';
import { Image } from '@components/Image';
import { SkillifyButton } from '@components/SkillifyButton';
import { ButtonType } from '@components/SkillifyButton/SkillifyButton';

interface ModalProps {
    children: React.ReactNode;
    backgroundImage?: string;
    cancelButton?: {
        text: string | undefined;
        onClick: () => void;
        buttonType?: ButtonType;
    };
    confirmButton: {
        text: string | undefined;
        onClick: () => void;
        buttonType?: ButtonType;
    };
}

export const SkillifyModal: React.FC<ModalProps> = ({
    children,
    confirmButton,
    backgroundImage,
    cancelButton,
}: ModalProps) => (
    <section className="tw-fixed tw-inset-0 tw-z-10 tw-flex tw-items-center tw-justify-center tw-bg-black/5 tw-backdrop-blur-md tw-backdrop-filter">
        {backgroundImage && (
            <Image
                className="tw-absolute tw-z-0 tw-h-[30rem] md:tw-h-[40rem]"
                src={backgroundImage}
                alt="background Image"
            />
        )}
        <div className="tw-z-10 tw-m-8 tw-flex tw-h-fit tw-w-full tw-flex-col tw-items-center tw-rounded-lg tw-bg-white tw-p-8 tw-pb-0 md:tw-max-w-2xl md:tw-pb-8">
            <div className="tw-flex tw-flex-col tw-items-center md:tw-max-w-xs">
                {children}
                <div className="tw-flex tw-w-full tw-flex-col tw-gap-2 tw-p-4">
                    <SkillifyButton
                        buttonType={confirmButton.buttonType}
                        className="tw-text-sm"
                        onClick={confirmButton.onClick}
                    >
                        {confirmButton.text}
                    </SkillifyButton>
                    {cancelButton && (
                        <SkillifyButton
                            buttonType={cancelButton.buttonType}
                            className="tw-text-sm"
                            onClick={cancelButton.onClick}
                        >
                            {cancelButton.text}
                        </SkillifyButton>
                    )}
                </div>
            </div>
        </div>
    </section>
);
