import { Image } from '@components/Image';
import { useAssessmentToolProvider } from '@providers/AssessmentToolProvider';
import { NormalQuestionOption } from '@lib/types';
import { useState } from 'react';
import { getImageUrl } from '@lib/helper/getImageUrl';

interface NormalQuestionOptionsProps {
    options?: NormalQuestionOption[];
    onOptionClick: (
        optionIndex: number,
        questionId: string,
        assessmentCompetencyId: string,
        questionsLength: number,
        areaIndex: number,
        questionType: string,
        questionIndex: number,
        areaName: string | undefined,
        areaId: string | undefined,
        chosenScore: number | undefined,
        isCorrect: boolean | undefined,
        contentAnswer: string | undefined,
        isVerificationQuestion: boolean | undefined,
    ) => void;
    questionsLength: number;
    areaIndex: number;
    questionType: string;
    questionIndex: number;
    areaName?: string;
    areaId?: string;
    questionId: string;
    assessmentCompetencyId: string;
}

export const NormalQuestionOptions = ({
    options,
    onOptionClick,
    questionsLength,
    areaIndex,
    questionType,
    questionIndex,
    areaName,
    areaId,
    questionId,
    assessmentCompetencyId,
}: NormalQuestionOptionsProps) => {
    const { currentAnswerIndex } = useAssessmentToolProvider();
    const [selectedOptionStyle, setSelectedOptionStyle] = useState({
        optionIndex: -1,
        style: '',
    });
    const onClick = (option: NormalQuestionOption, optionIndex: number) => {
        setSelectedOptionStyle({
            optionIndex,
            style: 'tw-bg-skillify-color-light',
        });
        setTimeout(() => {
            onOptionClick(
                optionIndex,
                questionId,
                assessmentCompetencyId,
                questionsLength,
                areaIndex,
                questionType,
                questionIndex,
                areaName,
                areaId,
                option.score,
                undefined,
                undefined,
                false,
            );
        }, 50);
    };
    return (
        <>
            {options?.map((option, optionIndex) => (
                <div
                    role="option"
                    aria-selected={currentAnswerIndex === optionIndex}
                    tabIndex={0}
                    key={option.text}
                    onKeyPress={() => onClick(option, optionIndex)}
                    onClick={() => onClick(option, optionIndex)}
                    className={`tw-my-4 tw-flex tw-cursor-pointer tw-select-none tw-items-center tw-justify-between tw-rounded-md tw-shadow-skillify ${
                        selectedOptionStyle.optionIndex === optionIndex
                            ? selectedOptionStyle.style
                            : ''
                    } ${
                        currentAnswerIndex === optionIndex
                            ? 'tw-border-2 tw-border-solid tw-border-skillify-color tw-bg-privacy-modal'
                            : ''
                    }`}
                >
                    <div className="tw-py-4 tw-ps-8">
                        <p
                            className={`skillify-font tw-m-0 tw-text-xs tw-font-bold md:tw-text-sm ${
                                currentAnswerIndex === optionIndex
                                    ? 'tw-text-skillify-color'
                                    : 'tw-text-black/70'
                            }`}
                        >
                            {option.text}
                        </p>
                    </div>
                    <Image
                        key={option.text}
                        className="tw-h-[5.625rem] tw-pe-4 md:tw-h-[6.5625rem]"
                        src={getImageUrl(option.image)}
                        alt={option.text}
                    />
                </div>
            ))}
        </>
    );
};
