import { useEffect, useState } from 'react';

export const useInnerWindowWidth = () => {
    const [innerWidth, setInnerWidth] = useState<number | undefined>();
    useEffect(() => {
        if (window) {
            setInnerWidth(window.innerWidth);
            window.addEventListener('resize', () =>
                setInnerWidth(window.innerWidth),
            );
        }
        return () => {
            window.removeEventListener('resize', () =>
                setInnerWidth(window.innerWidth),
            );
            setInnerWidth(undefined);
        };
    }, []);

    return innerWidth;
};
