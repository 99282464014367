import React, { useState } from 'react';
import { VerificationQuestionOption } from '@lib/types';
import { useAssessmentToolProvider } from '@providers/AssessmentToolProvider';
import { Image } from '@components/Image';
import { WAIT_TIME_VERIFICATION_QUESTIONS } from '@lib/constants';

type VerificationQuestionProps = {
    questionText?: string;
    titleSmallScreen?: string;
    titleBigScreen?: string;
    questionOptions: VerificationQuestionOption[];
    onNextClick: any;
    onVerificationQuestionAnsweredChange: (newValue: number) => void;
};

export const VerificationQuestion: React.FC<VerificationQuestionProps> = ({
    questionText,
    titleSmallScreen,
    titleBigScreen,
    questionOptions,
    onNextClick,
    onVerificationQuestionAnsweredChange,
}: VerificationQuestionProps) => {
    const { currentAnswerIndex } = useAssessmentToolProvider();
    const [questionHasBeenAnswered, setQuestionHasBeenAnswered] =
        useState(false);

    const handleOptionChange = (optionIndex: number) => {
        onVerificationQuestionAnsweredChange(optionIndex); // Call the callback function and pass the new value
        onNextClick();
    };

    const onClick = (
        option: VerificationQuestionOption,
        optionIndex: number,
    ) => {
        setQuestionHasBeenAnswered(true);
        // It doesn't matter here if the answer is correct, we just visualise the same thing here
        setTimeout(
            () => handleOptionChange(optionIndex),
            process.env.NODE_ENV === 'development'
                ? WAIT_TIME_VERIFICATION_QUESTIONS
                : 5000,
        );
    };

    return (
        <section className="tw-mb-12 tw-flex tw-flex-col">
            <div className="tw-text-center md:tw-text-start">
                <h1 className="skillify-font tw-text-base tw-font-semibold tw-text-skillify-color md:tw-hidden">
                    {titleSmallScreen}
                    <span className="tw-sr-only">.</span>
                </h1>
                <h1 className="skillify-font tw-hidden tw-text-lg tw-font-semibold tw-text-skillify-color md:tw-inline xl:tw-text-xl">
                    {titleBigScreen}
                    <span className="tw-sr-only">.</span>
                </h1>
                <h2 className="skillify-font tw-min-h-[80px] tw-text-lg tw-font-semibold tw-text-black/80 md:tw-text-xl xl:tw-text-2xl">
                    {questionText}
                    <span className="tw-sr-only">.</span>
                </h2>
            </div>
            <div className="md:tw-grid md:tw-h-full md:tw-grid-flow-col md:tw-grid-cols-2 md:tw-grid-rows-2 md:tw-gap-x-8 md:tw-py-8">
                {questionOptions.map((option, optionIndex) => (
                    <div
                        role="option"
                        aria-selected={currentAnswerIndex === optionIndex}
                        tabIndex={0}
                        key={option.text}
                        onKeyPress={() => onClick(option, optionIndex)}
                        onClick={() => onClick(option, optionIndex)}
                        className={`bg tw-mb-5 tw-flex tw-cursor-pointer tw-select-none tw-items-center tw-justify-center tw-rounded-md tw-p-5 tw-text-center tw-shadow-skillify  ${
                            currentAnswerIndex === optionIndex
                                ? 'tw-border-2 tw-border-solid tw-border-skillify-color tw-bg-privacy-modal'
                                : ''
                        }
                            ${
                                questionHasBeenAnswered
                                    ? 'tw-border-2 tw-border-solid'
                                    : ''
                            } 
                            ${
                                questionHasBeenAnswered && option.isCorrect
                                    ? 'tw-border-verification-green tw-bg-verification-green'
                                    : ''
                            } 
                            ${
                                questionHasBeenAnswered && !option.isCorrect
                                    ? ' tw-border-verification-red'
                                    : ''
                            } 
                        
                        `}
                    >
                        <div className="tw-items-center tw-py-4 tw-text-center">
                            <p
                                className={`skillify-font tw-m-0 tw-text-center tw-text-xs tw-font-bold md:tw-text-sm 
                                ${
                                    questionHasBeenAnswered
                                        ? option.isCorrect
                                            ? 'tw-text-white'
                                            : 'tw-text-verification-red'
                                        : 'tw-text-black/70'
                                }
                                `}
                            >
                                {option.text}
                            </p>
                        </div>
                        {questionHasBeenAnswered ? (
                            <div
                                className={`tw-absolute tw-mb-[-110px] tw-flex tw-h-7 tw-w-7 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-solid tw-bg-white ${
                                    option.isCorrect
                                        ? 'tw-border-verification-green'
                                        : 'tw-border-verification-red'
                                }`}
                            >
                                {option.isCorrect ? (
                                    <Image
                                        alt="green check mark"
                                        className="tw-h-2"
                                        src="/images/CheckmarkGreen.svg"
                                    />
                                ) : (
                                    <Image
                                        alt="red check mark"
                                        className="tw-h-2"
                                        src="/images/CrossRed.svg"
                                    />
                                )}
                            </div>
                        ) : null}
                    </div>
                ))}
            </div>
        </section>
    );
};
