import React, { ReactNode, useEffect } from 'react';
import { useRouter } from 'next/router';
import { CUSTOM_FOOTER_PATHS } from '@lib/constants';
import {
    AssessmentAreaPath,
    AssessmentCompetencyPath,
    AssessmentPath,
    AssessmentQuestionPath,
    LanguagesPath,
    LearningContentPath,
    OptionCategoryPath,
    PartnerPath,
} from '@lib/firebase/firestorePaths';
import { useGlobalStoreProvider } from '@providers/GlobalStoreProvider';
import { useLanguageProvider } from '@providers/LanguageProvider';
import { useAuthProvider } from '@providers/AuthProvider';
import { useCmsCollectionData } from '@lib/hooks/useCmsCollectionData';
import { LoaderIcon } from '@components/Loader';
import { LandingHeader } from '@components/LandingHeader';
import { Footer } from '@components/Footer';
import { useDynamicPagesContent } from '@lib/hooks/useDynamicPagesContent';
import type { General as GeneralT } from '@digico/common/lib/General';

interface Props {
    children: ReactNode;
}

export const Layout = (props: Props) => {
    const router = useRouter();
    const { children } = props;
    const { setLanguages, countryCode, isRTL } = useLanguageProvider();
    const [generalContent] = useDynamicPagesContent<GeneralT>(
        countryCode,
        'general',
    );
    const { isLoading } = useAuthProvider();
    const { isLoading: isLoadingLanguage } = useLanguageProvider();
    const {
        setAssessments,
        setAreas,
        setQuestions,
        setCompetencies,
        setLearningContents,
        setPartners,
        setOptions,
        competencies,
        questions,
        assessments,
        areas,
        learningContents,
        options,
    } = useGlobalStoreProvider();

    const hasLoadingText = router.pathname === '/learning-content';

    const assessmentsSnapshot = useCmsCollectionData({
        collectionPath: AssessmentPath,
        requiredProperties: ['name', 'id'],
        translationRequired: true,
        countryCode,
    });

    const areasSnapshot = useCmsCollectionData({
        collectionPath: AssessmentAreaPath,
        requiredProperties: [
            'image',
            'order',
            'id',
            'assessmentId',
            'partnerId',
            'medals',
            'motivationImage',
        ],
        translationRequired: true,
        countryCode,
    });

    const questionsSnapshot = useCmsCollectionData({
        collectionPath: AssessmentQuestionPath,
        requiredProperties: [
            'assessmentElementId',
            'assessmentCompetencyId',
            'id',
        ],
        translationRequired: true,
        countryCode,
    });

    const competenciesSnapshot = useCmsCollectionData({
        collectionPath: AssessmentCompetencyPath,
        requiredProperties: ['assessmentAreaId', 'id'],
        translationRequired: true,
        countryCode,
    });

    const learningContentsSnapshot = useCmsCollectionData({
        collectionPath: LearningContentPath,
        requiredProperties: [
            'partnerId',
            'displayConditions',
            'image',
            'isActive',
            'link',
            'id',
            'isFree',
            'isCertified',
            'duration',
            'isOnline',
            'isFaceToFace',
            'isMix',
        ],
        translationRequired: true,
        countryCode,
    });

    const [partnersSnapshot] = useCmsCollectionData({
        collectionPath: PartnerPath,
    });

    const optionsSnapshot = useCmsCollectionData({
        collectionPath: OptionCategoryPath,
        requiredProperties: ['id'],
        translationRequired: true,
        countryCode,
    });

    const [languagesSnapshot] = useCmsCollectionData({
        collectionPath: LanguagesPath,
    });

    useEffect(() => {
        if (assessmentsSnapshot && !assessments?.length) {
            if (countryCode === 'en') {
                setAssessments(assessmentsSnapshot);
            } else {
                Promise.resolve(assessmentsSnapshot).then(
                    (data) => data[0] && setAssessments(data),
                );
            }
        }

        if (areasSnapshot && !areas?.length) {
            if (countryCode === 'en') {
                setAreas(areasSnapshot);
            } else {
                Promise.resolve(areasSnapshot).then(
                    (data) => data[0] && setAreas(data),
                );
            }
        }

        if (questionsSnapshot && !questions?.length) {
            if (countryCode === 'en') {
                setQuestions(questionsSnapshot);
            } else {
                Promise.resolve(questionsSnapshot).then(
                    (data) => data[0] && setQuestions(data),
                );
            }
        }

        if (competenciesSnapshot && !competencies?.length) {
            if (countryCode === 'en') {
                setCompetencies(competenciesSnapshot);
            } else {
                Promise.resolve(competenciesSnapshot).then(
                    (data) => data[0] && setCompetencies(data),
                );
            }
        }

        if (learningContentsSnapshot && !learningContents?.length) {
            if (countryCode === 'en') {
                setLearningContents(learningContentsSnapshot);
            } else {
                Promise.resolve(learningContentsSnapshot).then(
                    (data) => data[0] && setLearningContents(data),
                );
            }
        }

        if (partnersSnapshot) setPartners(partnersSnapshot);

        if (optionsSnapshot && !options?.length) {
            if (countryCode === 'en') {
                setOptions(optionsSnapshot);
            } else {
                Promise.resolve(optionsSnapshot).then(
                    (data) => data[0] && setOptions(data),
                );
            }
        }

        if (languagesSnapshot) setLanguages(languagesSnapshot);
    }, [
        areasSnapshot,
        questionsSnapshot,
        competenciesSnapshot,
        learningContentsSnapshot,
        partnersSnapshot,
        languagesSnapshot,
        setAreas,
        setQuestions,
        setCompetencies,
        setLearningContents,
        setPartners,
        optionsSnapshot,
        setOptions,
        setLanguages,
        assessmentsSnapshot,
        assessments,
        areas,
        questions,
        competencies,
        learningContents,
        options?.length,
        countryCode,
        setAssessments,
    ]);
    if (isLoading || isLoadingLanguage)
        return (
            <LoaderIcon
                solidBackground
                hasLoadingText={hasLoadingText}
                loadingText={generalContent?.loadingText}
            />
        );
    return (
        <div dir={isRTL ? 'rtl' : 'ltr'} className="text-center">
            {!router.pathname.includes('/assessment-tool/[partnerId]') && (
                <LandingHeader />
            )}
            {children}
            {!CUSTOM_FOOTER_PATHS.includes(router.pathname) && <Footer />}
        </div>
    );
};
