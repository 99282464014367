/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import type { Assessment } from '@digico/common/lib/Assessment';
import type {
    AssessmentArea,
    SubAssessmentArea,
} from '@digico/common/lib/AssessmentArea';
import type { AssessmentCompetency } from '@digico/common/lib/AssessmentCompetency';
import type {
    AssessmentQuestion,
    NormalQuestionBlob,
} from '@digico/common/lib/AssessmentQuestion';
import type { OptionCategory } from '@digico/common/lib/Options';
import type { Partner } from '@digico/common/lib/Partner';
import type { LearningContent } from '@digico/common/lib/LearningContent';
import * as FirebaseAuth from '@firebase/auth';
import type { Result, ResultGroup } from '@digico/common/lib/Result';
import { Timestamp } from 'firebase/firestore';

//  NOTE:  Enums

export enum Score {
    LOW = 'low',
    FOUNDATION = 'foundation',
    INTERMEDIATE = 'intermediate',
    ADVANCED = 'advanced',
}

export enum VerificationQuestionLevel {
    NONE = 'none',
    FOUNDATIONEASY = 'foundation easy',
    FOUNDATIONHARD = 'foundation hard',
    INTERMEDIATE = 'intermediate',
    ADVANCED = 'advanced',
}

export type CommonSignupValues = {
    age: string;
    benefits: string;
    country: string;
    disability: string;
    education: string;
    employment: string;
    firstName: string;
    gender: string;
    lastName: string;
    previousSkills: string;
    residency: string;
    assessmentId: string;
    partnerId: string;
};

export interface ManualSignupValues extends CommonSignupValues {
    emailAddress: string;
    password: string;
    confirmPassword: string;
}

export interface GoogleSignupValues extends CommonSignupValues {}

//  NOTE:  Types
export type IsNewStudent = boolean;

export type AuthUserContextType = {
    authUser: DigicoUser | null;
    isLoading: boolean;
    isUserLoggedIn: boolean;
    signInWithEmailAndPassword: (
        email: string,
        password: string,
    ) => Promise<DigicoUser>;
    createUserWithEmailAndPassword: (
        values: ManualSignupValues,
    ) => Promise<[DigicoUser, IsNewStudent]>;
    signOut: () => Promise<void>;
    signInWithGoogle: (
        signUpValues?: GoogleSignupValues,
    ) => Promise<[DigicoUser, IsNewStudent]>;
    sendPasswordResetEmail: (
        email: string,
        actionCodeSettings: FirebaseAuth.ActionCodeSettings,
    ) => Promise<void>;
    isAboutSkillifyAccessible: boolean;
    setIsAboutSkillifyAccessible: React.Dispatch<React.SetStateAction<boolean>>;
};

export type AssessmentToolContextType = {
    resetAssessmentState: (intro: boolean) => void;
    partnerAssessment: PartnerAssessment;
    setPartnerAssessment: React.Dispatch<
        React.SetStateAction<PartnerAssessment>
    >;
    studentVerificationAnswers: StudentAnswer[];
    setStudentVerificationAnswers: React.Dispatch<
        React.SetStateAction<StudentAnswer[]>
    >;
    areasQuestions: Area[] | undefined;
    setAreasQuestions: React.Dispatch<React.SetStateAction<Area[] | undefined>>;
    globalQuestionIndex: number;
    setGlobalQuestionIndex: React.Dispatch<React.SetStateAction<number>>;
    globalAreaIndex: number;
    verificationQuestionAssessmentCompetencyIdsProcessed: AssessmentCompentencyIdWithScore[];
    setVerificationQuestionAssessmentCompetencyIdsProcessed: React.Dispatch<
        React.SetStateAction<AssessmentCompentencyIdWithScore[]>
    >;
    areaVerificationQuestionIndex: number;
    setAreaVerificationQuestionIndex: React.Dispatch<
        React.SetStateAction<number>
    >;
    setGlobalAreaIndex: React.Dispatch<React.SetStateAction<number>>;
    studentAnswers: StudentAnswer[];
    setStudentAnswers: React.Dispatch<React.SetStateAction<StudentAnswer[]>>;
    currentAnswer: CurrentAnswer;

    upcomingVerificationQuestion: VerificationQuestionWithType | null;
    setUpcomingVerificationQuestion: React.Dispatch<
        React.SetStateAction<VerificationQuestionWithType | null>
    >;
    setCurrentAnswer: React.Dispatch<React.SetStateAction<CurrentAnswer>>;
    currentAnswerIndex: number | null;
    setCurrentAnswerIndex: React.Dispatch<React.SetStateAction<number | null>>;
    isLoadingResults: boolean;
    setLoadingResults: React.Dispatch<React.SetStateAction<boolean>>;
    resultsData: ResultsData[];
    setResultsData: React.Dispatch<React.SetStateAction<ResultsData[]>>;
    triggerCreateResults: boolean;
    setCreateResults: React.Dispatch<React.SetStateAction<boolean>>;
    verificationQuestionIsClicked: boolean;
    setVerificationQuestionIsClicked: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    watchTutorial: boolean;
    setWatchTutorial: React.Dispatch<React.SetStateAction<boolean>>;
    isIntro: boolean;
    setIsIntro: React.Dispatch<React.SetStateAction<boolean>>;
    showPrivacyGuidelines: boolean;
    setShowPrivacyGuidelines: React.Dispatch<React.SetStateAction<boolean>>;
    showMotivation: boolean;
    setShowMotivation: React.Dispatch<React.SetStateAction<boolean>>;
    hasWatchedTutorial: boolean;
    setHasWatchedTutorial: React.Dispatch<React.SetStateAction<boolean>>;
    redirectToResults: boolean;
    setRedirectToResults: React.Dispatch<React.SetStateAction<boolean>>;
    showSignup: boolean;
    setShowSignup: React.Dispatch<React.SetStateAction<boolean>>;
    areasQuestionsArrayHasBeenBuild: boolean;
    setAreasQuestionsArrayHasBeenBuild: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    metaQuestions: VerificationQuestionWithType[];
    setMetaQuestions: React.Dispatch<
        React.SetStateAction<VerificationQuestionWithType[]>
    >;
};

export type GlobalStoreContextType = {
    assessments: Assessment[] | undefined;
    setAssessments: React.Dispatch<
        React.SetStateAction<Assessment[] | undefined>
    >;
    areas: AssessmentArea[] | undefined;
    setAreas: React.Dispatch<
        React.SetStateAction<AssessmentArea[] | undefined>
    >;
    questions: AssessmentQuestion[] | undefined;
    setQuestions: React.Dispatch<
        React.SetStateAction<AssessmentQuestion[] | undefined>
    >;
    competencies: AssessmentCompetency[] | undefined;
    setCompetencies: React.Dispatch<
        React.SetStateAction<AssessmentCompetency[] | undefined>
    >;
    learningContents: LearningContentWithId[] | undefined;
    setLearningContents: React.Dispatch<
        React.SetStateAction<LearningContentWithId[] | undefined>
    >;
    currentPartner: Partner | undefined;
    setCurrentPartner: React.Dispatch<
        React.SetStateAction<Partner | undefined>
    >;
    partners: Partner[];
    setPartners: React.Dispatch<React.SetStateAction<Partner[]>>;
    options: OptionCategory[] | undefined;
    setOptions: React.Dispatch<
        React.SetStateAction<OptionCategory[] | undefined>
    >;
    resultsId: string;
    setResultsId: React.Dispatch<React.SetStateAction<string>>;
    studentResultsData: StudentResultsData[];
    setStudentResultsData: React.Dispatch<
        React.SetStateAction<StudentResultsData[]>
    >;
};

export type CmsHookArgsT = {
    collectionPath: string;
    requiredProperties?: string[];
    translationRequired?: boolean;
    referencePath?: string;
    pred?: any;
    countryCode?: string;
};

export type PartnerAssessment = Assessment | null;

export type AssessmentQuestionWithType = {
    id: string;
    name?: string;
    question?: NormalQuestionBlob;
    element?: string;
    assessmentElementId: { id: string };
    assessmentCompetencyId: { id: string };
    isMetaQuestion?: boolean;
    type: string;
};

export interface AssessmentCompentencyIdWithScore {
    assessmentCompetencyId: string;
    score: Score;
}

export type VerificationQuestionWithType = {
    id: string;
    question?: {
        text?: string;
        image?: string;
        presentationStyle?: 'image-selection' | 'text-selection';
        options?: VerificationQuestionOption[];
    };
    element?: string;
    assessmentCompetencyId: { id: string };
    type: string;
    level: VerificationQuestionLevel;
};

export type Area = SubAssessmentArea & {
    id?: string;
    image?: string;
    questions: (AssessmentQuestionWithType | VerificationQuestionWithType)[];
    motivationImage?: string;
    competencies: AssessmentCompetency[];
};

export type StudentAnswer = {
    questionId: string;
    chosenScore?: number | undefined;
    area: string;
    areaId: string;
    assessmentCompetencyId: string;
    isCorrect: boolean | undefined;
    contentAnswer: string | undefined;
    isVerificationQuestion: boolean | undefined;
};

export type CurrentAnswer = StudentAnswer | null;

export type ResultsData = Omit<Result, 'score'> & {
    isVerificationQuestion: boolean;
};

export type NormalQuestionOption = {
    text?: string;
    image?: string;
    score?: number;
};

export type VerificationQuestionOption = {
    text?: string;
    imageBefore?: string;
    imageAfter?: string;
    isCorrect?: boolean;
};

export type StudentResult = {
    areaId: string;
    date: string;
    areaName: string;
    score: string;
    percentage: number;
    tooltip?: string;
};
export type StudentResultsData = {
    partnerId?: string;
    assessmentId?: string;
    results?: StudentResult[];
};

export type ResultCompetency = {
    name: string;
    scoreNumber: number;
    score: Score;
    scorePercentage: number;
    verificationScore: number;
};

export type LearningContentWithId = LearningContent & { id: string };

export type AreasWithLearningContent = {
    id?: string;
    name?: string;
    summary?: string;
    score?: Score;
    medal?: string;
    percentage: number;
    competencies: ResultCompetency[];
};

export type Levels = {
    low?: string;
    foundation?: string;
    intermediate?: string;
    advanced?: string;
};

export interface TranslatedCompetency
    extends Omit<ResultCompetency, 'scoreNumber'> {
    scoreText: string;
}

export type DownloadPdfType = {
    areaId: string;
    circleRef: React.MutableRefObject<HTMLDivElement> | null;
    areaName: string;
    areaSummary: string;
    areaMedal: string;
    areaScore: string;
    areaScoreText: string;
    areaPercentage: number;
    areaCompetencies: TranslatedCompetency[];
};
export type DownloadPdfPromiseType = (
    pdfAreas: DownloadPdfType[],
) => Promise<void>;
export type PdfArea = {
    areaId: string;
    levelCircle: string;
    name: string;
    summary: string;
    score: string;
    scoreText: string;
    medal: string;
    percentage: string;
    competencies: TranslatedCompetency[];
};

export type Medal = {
    areaId: string;
    src: string;
};
export type ScoreLevels =
    | {
          low?: string;
          foundation?: string;
          intermediate?: string;
          advanced?: string;
      }
    | undefined;

export type DigicoUser = {
    displayName: string | null | undefined;
    studentId?: string;
    emailAddress: string | null;
    age?: string;
    benefits?: string;
    country?: string;
    disability?: string;
    education?: string;
    employment?: string;
    firstName?: string;
    gender?: string;
    lastName?: string;
    previousSkills?: string;
    residency?: string;
    assessmentId?: string;
    partnerId?: string;
    hasSeenResultsTutorial?: boolean;
};

export interface ResultGroupWeb extends Omit<ResultGroup, 'created_at'> {
    created_at?: Timestamp;
}
