import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN =
    process.env.SENTRY_DSN ||
    process.env.NEXT_PUBLIC_SENTRY_DSN ||
    'https://3361a2af901b459fab243fa7f288de1c@o1323477.ingest.sentry.io/6582746';

Sentry.init({
    dsn: SENTRY_DSN,
    tracesSampleRate: 1.0,
});
