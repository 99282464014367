import React from 'react';
import { useAssessmentToolProvider } from '@providers/AssessmentToolProvider';
import { SkillifyModal } from '@components/AssessmentTool/SkillifyModal';

interface PrivacyModalProps {}

export const PrivacyModal: React.FC<PrivacyModalProps> = () => {
    const { setShowPrivacyGuidelines, partnerAssessment } =
        useAssessmentToolProvider();
    return (
        <SkillifyModal
            confirmButton={{
                onClick: () => setShowPrivacyGuidelines(false),
                text: partnerAssessment?.privacyModal?.consentButton,
            }}
        >
            <h1 className="skillify-font tw-text-lg tw-font-bold">
                {partnerAssessment?.privacyModal?.title}
                <span className="tw-sr-only">.</span>
            </h1>
            <div className="tw-rounded-lg tw-bg-privacy-modal tw-p-8 tw-text-center">
                <p className="skillify-font tw-m-0 tw-text-sm tw-font-semibold tw-text-black/60">
                    {partnerAssessment?.privacyModal?.text}
                </p>
            </div>
        </SkillifyModal>
    );
};
