import React, { useEffect, useState } from 'react';
import { LoaderIcon } from '@components/Loader/Loader';
import { useLanguageProvider } from '@providers/LanguageProvider';
import { useDynamicPagesContent } from '@lib/hooks/useDynamicPagesContent';
import type { NavigationItems } from '@digico/common/lib/NavigationItems';
import { useIsLoading } from '@lib/hooks/useIsLoading';
import { Navigation } from '@components/Navigation';
import { useAuthProvider } from '@providers/AuthProvider';
import { getDocData } from '@lib/firebase/firestore-crud';
import type { Partner } from '@digico/common/lib/Partner';
import { PartnerLogo } from '@components/AssessmentTool/PartnerLogo';
import { useGlobalStoreProvider } from '@providers/GlobalStoreProvider';

const getPartner = async (partnerId: string) => {
    try {
        return await getDocData<Partner>('partner', partnerId);
    } catch (err: unknown) {
        console.error(err);
        return undefined;
    }
};

export const LandingHeader = () => {
    const { countryCode } = useLanguageProvider();
    const { authUser } = useAuthProvider();
    const { currentPartner } = useGlobalStoreProvider();
    const [navigationItemsContent, isLoadingNavigationItemsContent] =
        useDynamicPagesContent<NavigationItems>(countryCode, 'navigationItems');
    const isLoading = useIsLoading([isLoadingNavigationItemsContent]);
    const [partner, setPartner] = useState<Partner | undefined>();

    useEffect(() => {
        if (authUser?.partnerId) {
            getPartner(authUser.partnerId).then((partnerDoc) => {
                setPartner(partnerDoc);
            });
        } else {
            setPartner(currentPartner);
        }
    }, [authUser, currentPartner]);

    if (isLoading) return <LoaderIcon />;
    return (
        <header className="tw-mx-8 tw-my-8 tw-flex tw-items-center tw-justify-between lg:tw-mt-4">
            <PartnerLogo partnerData={partner} />
            <Navigation navigationItemsContent={navigationItemsContent} />
        </header>
    );
};
