import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { doc, getFirestore } from '@firebase/firestore';
import { create, db } from '@lib/firebase/firestore-crud';
import { ANALYTICS_NAMES } from '@lib/constants';
import { useGlobalStoreProvider } from '@providers/GlobalStoreProvider';
import { useAssessmentToolProvider } from '@providers/AssessmentToolProvider';
import type { AssessmentArea } from '@digico/common/lib/AssessmentArea';
import type { AssessmentCompetency } from '@digico/common/lib/AssessmentCompetency';
import {
    Area,
    AssessmentQuestionWithType,
    ResultsData,
    VerificationQuestionWithType,
} from '@lib/types';
import { useAuthProvider } from '@providers/AuthProvider';
import {
    AssessmentCompetencyPath,
    PartnerPath,
    ResultGroupPathV2,
} from '@lib/firebase/firestorePaths';
import { CountryCodesDropDown } from '@components/DropDown';
import { ProgressBarContainer } from '@components/AssessmentTool/ProgressBarContainer';
import { IntroCard } from '@components/AssessmentTool/IntroCard';
import { LoaderIcon } from '@components/Loader';
import { Tutorial } from '@components/AssessmentTool/Tutorial';
import { PrivacyModal } from '@components/AssessmentTool/PrivacyModal';
import { LoadingResults } from '@components/AssessmentTool/LoadingResults';
import { PartnerLogo } from '@components/AssessmentTool/PartnerLogo';
import { MotivationModal } from '@components/AssessmentTool/MotivationModal';
import { AreaQuestionsContainer } from '@components/AssessmentTool/AreaQuestionsContainer';
import type { AssessmentQuestion } from '@digico/common/lib/AssessmentQuestion';
import { mapScore } from '@lib/helper/mapScore';
import { logAnalytic } from '@lib/helper/logAnalytic';
import { useLanguageProvider } from '@providers/LanguageProvider';
import { useDynamicPagesContent } from '@lib/hooks/useDynamicPagesContent';
import type { SignUp } from '@digico/common/lib/SignUp';
import { useIsLoading } from '@lib/hooks/useIsLoading';
import { SignUpContainer } from '@components/SignupContainer';
import type { Partner } from '@digico/common/lib/Partner';
import { parseQueryToString } from '@lib/helper/parseQueryToString';
import { Navigation } from '@components/Navigation';
import type { NavigationItems } from '@digico/common/lib/NavigationItems';

export const buildAreasQuestionsArray = (
    areas: AssessmentArea[],
    questions: AssessmentQuestion[],
    competencies: AssessmentCompetency[],
): Area[] =>
    areas
        .map((area) => {
            const areaCompetencies: AssessmentCompetency[] = [];
            competencies?.forEach((competency) => {
                if (competency.assessmentAreaId.id === area.id) {
                    if (competency.id) areaCompetencies.push(competency);
                }
            });
            return {
                id: area.id,
                name: area.name,
                motivationImage: area.motivationImage,
                motivationTitle: area.motivationTitle,
                motivationText: area.motivationText,
                motivationNextUp: area.motivationNextUp,
                motivationButtonLabel: area.motivationButtonLabel,
                competencies: areaCompetencies,
            };
        })
        .map((area) => {
            const areaQuestions: AssessmentQuestionWithType[] = [];
            questions?.forEach((question) => {
                if (
                    area.competencies
                        .map((assessmentCompetency) => assessmentCompetency.id)
                        .includes(question.assessmentCompetencyId?.id)
                ) {
                    areaQuestions.push({ ...question, type: 'normal' });
                }
            });
            areaQuestions?.unshift({
                name: 'intro',
                isMetaQuestion: false,
                assessmentElementId: { id: '0' },
                assessmentCompetencyId: { id: '0' },
                element: 'intro',
                id: '0',
                question: {
                    text: '',
                },
                type: 'intro',
            });
            return {
                ...area,
                questions: areaQuestions,
            };
        });

interface SkillifyContainerProps {
    partnerId: string | string[] | null;
    intro: string | string[] | null;
    assessmentId: string | string[] | null;
    areaId: string | string[] | null;
}

export const SkillifyContainer: React.FC<SkillifyContainerProps> = ({
    partnerId,
    intro,
    assessmentId,
    areaId,
}: SkillifyContainerProps) => {
    const { countryCode } = useLanguageProvider();
    const { isUserLoggedIn, setIsAboutSkillifyAccessible, authUser } =
        useAuthProvider();
    const {
        assessments,
        areas,
        questions,
        competencies,
        setCurrentPartner,
        currentPartner,
        options,
        setResultsId,
        studentResultsData,
    } = useGlobalStoreProvider();
    const {
        resetAssessmentState,
        setPartnerAssessment,
        areasQuestions,
        setAreasQuestions,
        globalQuestionIndex,
        isLoadingResults,
        resultsData,
        triggerCreateResults,
        setCreateResults,
        watchTutorial,
        hasWatchedTutorial,
        isIntro,
        setIsIntro,
        showPrivacyGuidelines,
        showMotivation,
        setWatchTutorial,
        globalAreaIndex,
        redirectToResults,
        showSignup,
        areasQuestionsArrayHasBeenBuild,
        setAreasQuestionsArrayHasBeenBuild,
        studentAnswers,
    } = useAssessmentToolProvider();
    const [visibleAreas, setVisibleAreas] = useState<AssessmentArea[]>([]);
    const router = useRouter();
    const dynamicRoute = router.asPath;
    const [navigationItemsContent] = useDynamicPagesContent<NavigationItems>(
        countryCode,
        'navigationItems',
    );
    const partnerDocumentData = useDocumentData<Partner>(
        doc(getFirestore(), `${PartnerPath}/${partnerId}`),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        },
    );

    const assessmentAreas = useDocumentData<any>(
        doc(getFirestore(), `assessments/${assessmentId}`),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        },
    )[0];

    useEffect(() => {
        // Clear areaId to make sure you are on the overview of assessments and no assessment is loaded
        if (areaId === null && !router.asPath.includes('full-assessment')) {
            window.sessionStorage.clear();
        }
    }, []);

    let partnerData = partnerDocumentData[0];
    const isLoadingPartner = partnerDocumentData[1];

    // When we exit the skillify container all session storage should be cleared, to prevent unexpected behaviour
    useEffect(() => {
        if (window) {
            // Needed for when the user changes the url
            window.addEventListener('beforeunload', () => {
                sessionStorage.clear();
            });
        }
        return () => {
            window.removeEventListener('beforeunload', () => {
                sessionStorage.clear();
            });
            // Needed when we change url/component programmatically
            sessionStorage.clear();
        };
    }, []);

    useEffect(() => {
        resetAssessmentState(intro !== 'false');
        // eslint-disable-next-line react-hooks/exhaustive-deps
        partnerData = undefined;
    }, [dynamicRoute, intro, resetAssessmentState]);

    const [signUpText, isLoadingSignUpText] = useDynamicPagesContent<SignUp>(
        countryCode,
        'signUp',
    );

    const isLoading = useIsLoading([
        isLoadingPartner,
        isLoadingSignUpText,
        !areasQuestionsArrayHasBeenBuild,
    ]);

    const isLoadingAfterSignup = useIsLoading([
        localStorage.getItem('creatingUser'),
    ]);

    const createResults = useCallback(
        (priorityAnswers?) => {
            if (studentAnswers) {
                let answersToProcess = studentAnswers;
                if (priorityAnswers.length > 0) {
                    answersToProcess = priorityAnswers;
                }

                const verificationAnswers = answersToProcess.filter(
                    (x) => x.isVerificationQuestion === true,
                );

                // Always take the studentAnswers
                const mappedResultsData = answersToProcess.map(
                    (x) =>
                        ({
                            scoreNumber: x.chosenScore,
                            scorePercentage: x.chosenScore,
                            assessmentCompetencyId: {
                                id: x.assessmentCompetencyId,
                            },
                            verificationScore: 0,
                            isVerificationQuestion: x.isVerificationQuestion,
                        } as ResultsData),
                );
                const results = mappedResultsData
                    .flat()
                    .filter(
                        (item) => item.assessmentCompetencyId?.id !== undefined,
                    );

                const resultsArray = results
                    .map((res) => {
                        if (!res.isVerificationQuestion) {
                            if (res.assessmentCompetencyId?.id !== undefined) {
                                if (
                                    Number.isNaN(res.scoreNumber) ||
                                    !res.scoreNumber
                                ) {
                                    res.scoreNumber = 0;
                                }

                                if (
                                    Number.isNaN(res.scorePercentage) ||
                                    !res.scorePercentage
                                )
                                    res.scorePercentage = 0;
                                return {
                                    score: mapScore(res.scorePercentage),
                                    scoreNumber: res.scoreNumber,
                                    scorePercentage: res.scorePercentage,
                                    verificationScore: 0, // Verification results are now a seperate entity
                                    assessmentCompetencyId: doc(
                                        db,
                                        AssessmentCompetencyPath,
                                        res.assessmentCompetencyId.id,
                                    ),
                                };
                            }
                        }
                        return null;
                    })
                    .filter((result) => result !== null);

                // Cleanup resultsArray, we don't include verification questions in the actual results, only in the verificationAnswers
                // Also cleanup possible faulty values
                const cleanedResultsArray = resultsArray.filter(
                    (x) => !Number.isNaN(x?.score),
                );
                const resultGroupData = {
                    created_at: new Date(),
                    studentId: authUser?.studentId
                        ? doc(db, 'student', authUser.studentId)
                        : null,
                    partnerId: doc(
                        db,
                        PartnerPath,
                        parseQueryToString(partnerId),
                    ),
                    assessmentId,
                    areaId,
                    isFullAssessment: !areaId,
                    results: cleanedResultsArray,
                    verificationAnswers,
                };

                logAnalytic(ANALYTICS_NAMES.assessmentArea, {
                    event: 'finished',
                    partnerId,
                });
                console.info('resultGroupData', resultGroupData);
                create(ResultGroupPathV2, resultGroupData).then((res) => {
                    setResultsId(res.id);
                    localStorage.setItem('resultGroupId', res.id);
                    localStorage.setItem(
                        'partnerId',
                        (partnerId || '').toString(),
                    );

                    if (isUserLoggedIn) {
                        setIsAboutSkillifyAccessible(true);
                    }

                    if (redirectToResults) {
                        router.push(
                            `/learning-content?partnerId=${partnerId}&assessmentId=${res.id}&lang=${countryCode}`,
                        );
                    }
                });
            }
        },
        [
            resultsData,
            authUser,
            currentPartner,
            partnerId,
            setResultsId,
            isUserLoggedIn,
            redirectToResults,
            setIsAboutSkillifyAccessible,
        ],
    );

    useEffect(() => {
        if (!isIntro) {
            setWatchTutorial(true);
        } else {
            setWatchTutorial(false);
        }
    }, [isIntro, setWatchTutorial]);

    useEffect(() => {
        setIsIntro(
            intro !== 'false' && !router.asPath.includes('full-assessment'),
        );
    }, []);

    useEffect(() => {
        if (questions && areas && competencies && assessments) {
            setCurrentPartner(partnerData);
            const partnerAreasIds = assessmentAreas?.assessmentAreaIds?.map(
                (item: { id: any }) => item.id,
            );

            const filteredAreas = areas
                .sort((a, b) => a.order - b.order)
                .filter((area) => partnerAreasIds?.includes(area.id ?? ''));
            setVisibleAreas(filteredAreas);
            const areasQuestionsArray: Area[] = buildAreasQuestionsArray(
                filteredAreas,
                questions,
                competencies,
            );

            // ============= When refresh the page ==========

            const areasQuestionsFromSession: Area[] = JSON.parse(
                window.sessionStorage.getItem('areasQuestions') || '[]',
            );

            const includesVerificationQuestionsArray =
                areasQuestionsFromSession.map((area) =>
                    area.questions
                        ?.map(
                            (
                                question:
                                    | null
                                    | AssessmentQuestionWithType
                                    | VerificationQuestionWithType,
                            ) => question?.type === 'verification',
                        )
                        .includes(true),
                );

            if (areaId !== null) {
                if (!includesVerificationQuestionsArray[globalAreaIndex]) {
                    setAreasQuestions(
                        areasQuestionsArray.filter(
                            (area) => area.id === areaId,
                        ),
                    );
                }
            } else if (includesVerificationQuestionsArray[globalAreaIndex]) {
                // addVerificationQuestion(globalAreaIndex, areasQuestionsArray); // TODO: Can it go ja?
            } else {
                setAreasQuestions(areasQuestionsArray);
            }
            // =============== End - When refresh the page ===============

            const assessmentAreaId = areas?.[0]?.assessmentId?.id;
            const filteredAssessment = assessments.find(
                (assessment) => assessment.id === assessmentAreaId,
            );
            if (filteredAssessment !== undefined) {
                setPartnerAssessment(filteredAssessment);
            }
            setAreasQuestionsArrayHasBeenBuild(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        assessments,
        partnerData,
        areas,
        questions,
        competencies,
        setCurrentPartner,
        setAreasQuestions,
        setPartnerAssessment,
        partnerId,
        options,
        globalAreaIndex,
        areasQuestionsArrayHasBeenBuild,
        setAreasQuestionsArrayHasBeenBuild,
        assessmentAreas,
    ]);

    useEffect(() => {
        if (globalQuestionIndex !== 0) return;
        logAnalytic(ANALYTICS_NAMES.assessmentArea, {
            event: 'pageStart',
            partnerId,
        });
    }, [partnerId, globalQuestionIndex]);

    useEffect(
        () => () => {
            logAnalytic(ANALYTICS_NAMES.assessmentArea, {
                event: 'pageExit',
                partnerId,
                progress: globalQuestionIndex,
            });
        },
        [partnerId, globalQuestionIndex],
    );

    useEffect(() => {
        if (resultsData && triggerCreateResults) {
            createResults(studentAnswers);
            setCreateResults(false);
        }
    }, [
        createResults,
        resultsData,
        setCreateResults,
        studentAnswers,
        triggerCreateResults,
    ]);

    useEffect(() => {
        if (
            (isUserLoggedIn && studentResultsData.length === 0) ||
            !isUserLoggedIn
        ) {
            setIsAboutSkillifyAccessible(false);
        }
    }, [
        isUserLoggedIn,
        setIsAboutSkillifyAccessible,
        studentResultsData.length,
    ]);

    return isLoadingAfterSignup ? (
        <LoaderIcon solidBackground />
    ) : (
        <>
            {isLoading && <LoaderIcon />}
            {showPrivacyGuidelines && <PrivacyModal />}
            {showMotivation && <MotivationModal />}
            {isLoadingResults ? (
                <LoadingResults partnerData={partnerData} />
            ) : (
                <div>
                    <header className="tw-my-8 tw-flex tw-items-center tw-justify-between lg:tw-mt-4">
                        <PartnerLogo partnerData={partnerData} />
                        {isIntro && (
                            <Navigation
                                navigationItemsContent={navigationItemsContent}
                                linkToAssessmentTool={
                                    !isUserLoggedIn ? router.asPath : undefined
                                }
                            />
                        )}
                        {!isIntro && <CountryCodesDropDown />}
                    </header>
                    <div
                        className="tw-w-full tw-max-w-md tw-px-8 md:tw-max-w-screen-xl "
                        style={{ margin: '0 auto' }}
                    >
                        {showSignup ? (
                            <SignUpContainer signUpText={signUpText} />
                        ) : (
                            <>
                                {globalQuestionIndex !== 0 && (
                                    <ProgressBarContainer />
                                )}
                                {!areaId && isIntro && (
                                    <IntroCard visibleAreas={visibleAreas} />
                                )}{' '}
                                {watchTutorial && !hasWatchedTutorial && (
                                    <Tutorial />
                                )}
                                {areasQuestions &&
                                    areasQuestions?.length > 0 &&
                                    areasQuestions?.map((area, index) => (
                                        <AreaQuestionsContainer
                                            key={`QuestionArea_${area.name}`}
                                            mappedArea={{ area, index }}
                                        />
                                    ))}
                            </>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};
