import { db, getWithQuery } from '@lib/firebase/firestore-crud';
import { ResultGroupPathV2, StudentPath } from '@lib/firebase/firestorePaths';
import { doc, runTransaction } from 'firebase/firestore';

export const addStudentIdToResultGroupByEmail = async (
    email: string | null,
    resultGroupId: string,
) => {
    if (!resultGroupId) {
        console.warn('No resultGroupId found.');
        return false;
    }

    try {
        const res = await getWithQuery(
            StudentPath,
            'emailAddress',
            '==',
            email,
        );

        if (res.docs.length === 0) {
            console.warn('No student found with the given email.');
            return false;
        }

        const studentId = res.docs[0].id;
        // Update the result group with the studentId
        const updatedResult = await runTransaction(db, async (transaction) => {
            const sfDocRefResultGroup = doc(
                db,
                ResultGroupPathV2,
                resultGroupId,
            );
            const sfDocsDocRefResultGroup = await transaction.get(
                sfDocRefResultGroup,
            );

            if (!sfDocsDocRefResultGroup.exists()) {
                console.error('Document does not exist!');
                return false;
            }

            transaction.update(sfDocRefResultGroup, {
                studentId: doc(db, StudentPath, studentId),
            });
            return true;
        });
        // Update the student document with _id field
        const updatedStudent = await runTransaction(db, async (transaction) => {
            const sfDocRefStudent = doc(db, StudentPath, studentId);
            const sfDocsStudent = await transaction.get(sfDocRefStudent);

            if (!sfDocsStudent.exists()) {
                console.error('Document does not exist!');
                return false;
            }

            transaction.update(sfDocRefStudent, {
                _id: studentId,
            });
            return true;
        });
        return updatedResult && updatedStudent;
    } catch (error) {
        console.error('Error while adding studentId to result group:', error);
        return false;
    }
};
