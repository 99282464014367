//  TODO:  add Swagger/Open API documentation

import { collection, getDocs, getFirestore } from 'firebase/firestore';
import { LearningContentWithId } from '@lib/types';
import { firebaseApp } from '@lib/firebase/firebase';
import { doc, getDoc } from '@firebase/firestore';
// eslint-disable-next-line import/no-extraneous-dependencies
import { LearningContentPath } from '@digico/common/lib/_paths';
// eslint-disable-next-line import/no-extraneous-dependencies
import { SubLearningContent } from '@digico/common/lib/LearningContent';
import { getCookie } from '@lib/helper/getCookie';
import { PROXY_SERVER } from '@lib/constants';
import { servicesURL } from '@lib/firebase/config';

const db = getFirestore(firebaseApp);

const Params = (method: string, data?: object, withCredentials?: boolean) => {
    const params: RequestInit = {
        method,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    };
    if (data) params.body = JSON.stringify(data);
    if (withCredentials) {
        const token = getCookie('csst', window.document.cookie);
        params.credentials = 'include';
        if (token) {
            params.headers = {
                ...params.headers,
                Authorization: `Bearer ${token}`,
            };
        }
    }
    return params;
};

export async function getCountries(language: string) {
    const response = await fetch(
        `/data/countries/${language.toLowerCase()}.json`,
        Params('GET'),
    );
    return response.json();
}

export async function getAllCourses(
    language: string,
): Promise<LearningContentWithId[]> {
    const docRefs = await getDocs(collection(db, LearningContentPath));
    const res: LearningContentWithId[] = [];

    docRefs.forEach((course) => {
        res.push({
            id: course.id,
            ...course.data(),
        });
    });
    res.forEach((course) => {
        getDoc(
            doc(db, `${LearningContentPath}/${course.id}/locales/${language}`),
        ).then((translation) => {
            if (translation && translation.exists() && translation.data()) {
                const translationData: SubLearningContent = translation.data();
                course.title = translationData.title;
                course.text = translationData.text;
            }
        });
    });
    return res;
}

export async function checkIfStudentExists(
    email: string | null | undefined,
): Promise<{ exists: boolean; id: string | undefined }> {
    if (!email)
        return {
            exists: false,
            id: undefined,
        };
    return getData(`${servicesURL}service-checkIfStudentEmailExists`, 'POST', {
        email,
    });
}

export async function getData(endpoint: string, method: string, data?: object) {
    try {
        const response = await fetch(endpoint, Params(method, data));
        const json = response.json();
        return await Promise.resolve(json);
    } catch (err) {
        return Promise.reject();
    }
}

const isProxyServerAvailable = async (proxyUrl: string) => {
    try {
        const response = await fetch(proxyUrl, Params('GET'));
        return response.ok;
    } catch (error) {
        return false;
    }
};

export async function getDataWithCredentials(
    endpoint: string,
    method: string,
    data?: object,
) {
    try {
        let fetchUrl = endpoint;
        const proxyUrl = PROXY_SERVER;
        if (
            process.env.NODE_ENV === 'development' &&
            (await isProxyServerAvailable(proxyUrl))
        ) {
            if (endpoint.startsWith('http')) {
                const url = new URL(endpoint);
                fetchUrl = `${proxyUrl}${url.pathname}`;
            } else {
                fetchUrl = `${proxyUrl}${endpoint}`;
            }
        }
        const response = await fetch(fetchUrl, Params(method, data, true));
        const json = response.json();
        return await Promise.resolve(json);
    } catch (err) {
        console.error('fetchErr', err);
        return Promise.reject();
    }
}
