import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    GlobalStoreContextType,
    LearningContentWithId,
    ResultGroupWeb,
    StudentResult,
    StudentResultsData,
} from '@lib/types';
import {
    collection,
    FirestoreError,
    getFirestore,
    query,
} from 'firebase/firestore';
import { ResultGroupPathV2 } from '@lib/firebase/firestorePaths';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import type { AssessmentArea } from '@digico/common/lib/AssessmentArea';
import type { Assessment } from '@digico/common/lib/Assessment';
import type { AssessmentCompetency } from '@digico/common/lib/AssessmentCompetency';
import type { Partner } from '@digico/common/lib/Partner';
import type { OptionCategory } from '@digico/common/lib/Options';
import { useAuthProvider } from '@providers/AuthProvider';
import type { AssessmentQuestion } from '@digico/common/lib/AssessmentQuestion';
import { where } from '@firebase/firestore';
import { calculateAverageFromResults } from '@lib/helper/calculateAverageFromResults';
import { mapScore } from '@lib/helper/mapScore';

export const StoreContext = createContext<GlobalStoreContextType | undefined>(
    undefined,
);

const GlobalStoreProvider: React.FunctionComponent = ({ children }) => {
    const { authUser, isUserLoggedIn } = useAuthProvider();
    const [assessments, setAssessments] = useState<Assessment[] | undefined>(
        [],
    );
    const [areas, setAreas] = useState<AssessmentArea[] | undefined>([]);
    const [questions, setQuestions] = useState<
        AssessmentQuestion[] | undefined
    >([]);
    const [competencies, setCompetencies] = useState<
        AssessmentCompetency[] | undefined
    >([]);
    const [learningContents, setLearningContents] = useState<
        LearningContentWithId[] | undefined
    >([]);
    const [currentPartner, setCurrentPartner] = useState<Partner | undefined>();
    const [partners, setPartners] = useState<Partner[]>([]);
    const [options, setOptions] = useState<OptionCategory[] | undefined>([]);
    const [resultsId, setResultsId] = useState<string>('');
    const [studentResultsData, setStudentResultsData] = useState<
        StudentResultsData[]
    >([]);

    const ref = collection(getFirestore(), ResultGroupPathV2);

    const q = query(
        ref,
        where('_studentIdRefString', '==', authUser?.studentId ?? ''), // If we are not logged in or the value is not available, we don't return results this way
    );

    const [resultsValues] = useCollectionData(q, {
        snapshotListenOptions: { includeMetadataChanges: true },
        idField: 'id',
    }) as [ResultGroupWeb[] | undefined, boolean, FirestoreError];

    useEffect(() => {
        if (partners?.length && areas && competencies && isUserLoggedIn) {
            // First map assessment competencies
            const studentResults = resultsValues
                ?.filter(
                    (result) =>
                        (result.studentId &&
                            (result.studentId.id || result.studentId)) ===
                        authUser?.studentId,
                )
                .sort((a, b) =>
                    a.created_at && b.created_at && a.created_at > b.created_at
                        ? -1
                        : 1,
                );

            const resultsData: StudentResultsData[] = [];

            studentResults?.forEach((studentResult) => {
                // Check for corrupted data
                if (
                    !studentResult.partnerId ||
                    !studentResult.created_at?.seconds ||
                    !studentResult.id
                ) {
                    return;
                }

                let areasArray: AssessmentArea[] = [];
                const partnerId = studentResult.partnerId.id;

                // Only add result from the current partner linked to a User
                if (partnerId !== authUser?.partnerId) return;

                const assessmentCompetencyInResults: string[] = [];
                studentResult.results?.forEach((r) => {
                    if (r.assessmentCompetencyId) {
                        // find matching object
                        const matchingAssessmentCompetency = competencies.find(
                            (x) => x.id === r.assessmentCompetencyId?.id,
                        );
                        if (matchingAssessmentCompetency) {
                            if (
                                assessmentCompetencyInResults.indexOf(
                                    matchingAssessmentCompetency
                                        .assessmentAreaId.id,
                                ) === -1
                            ) {
                                assessmentCompetencyInResults.push(
                                    matchingAssessmentCompetency
                                        .assessmentAreaId.id,
                                );
                            }
                        }
                    }
                });
                const filteredAreas: AssessmentArea[] = areas
                    ?.filter((area) =>
                        assessmentCompetencyInResults?.includes(area.id ?? ''),
                    )
                    .sort((a, b) => (a.order > b.order ? 1 : -1));
                if (filteredAreas?.length)
                    areasArray = Array.from(
                        new Set([...areasArray, ...filteredAreas]),
                    );

                const areasMapped: StudentResult[] = areasArray.map(
                    (assessmentArea) => {
                        const assessmentComponentIds = competencies
                            .filter(
                                (comp) =>
                                    comp.assessmentAreaId.id ===
                                    assessmentArea.id,
                            )
                            .map((comp) => comp.id);
                        const filteredResults =
                            studentResult.results?.filter((res) =>
                                assessmentComponentIds.includes(
                                    res.assessmentCompetencyId?.id ?? '',
                                ),
                            ) ?? [];
                        const percentage = filteredResults
                            ? calculateAverageFromResults(
                                  filteredResults,
                                  'scorePercentage',
                              )
                            : 0;

                        return {
                            areaId: assessmentArea.id ?? '',
                            date: new Date(
                                (studentResult.created_at?.seconds ?? 0) * 1000,
                            ).toLocaleDateString('en-UK'),
                            areaName: assessmentArea.name ?? '',
                            score: mapScore(percentage),
                            percentage,
                        };
                    },
                );

                resultsData.push({
                    partnerId,
                    assessmentId: studentResult.id,
                    results: areasMapped,
                });
            });
            setStudentResultsData(resultsData);
        }
    }, [
        partners,
        areas,
        competencies,
        resultsValues,
        authUser,
        isUserLoggedIn,
    ]);

    const storeMemo = useMemo<GlobalStoreContextType>(
        () => ({
            assessments,
            setAssessments,
            areas,
            setAreas,
            questions,
            setQuestions,
            competencies,
            setCompetencies,
            learningContents,
            setLearningContents,
            currentPartner,
            setCurrentPartner,
            partners,
            setPartners,
            options,
            setOptions,
            resultsId,
            setResultsId,
            studentResultsData,
            setStudentResultsData,
        }),
        [
            assessments,
            setAssessments,
            areas,
            setAreas,
            questions,
            setQuestions,
            competencies,
            setCompetencies,
            learningContents,
            setLearningContents,
            currentPartner,
            setCurrentPartner,
            partners,
            setPartners,
            options,
            setOptions,
            resultsId,
            setResultsId,
            studentResultsData,
            setStudentResultsData,
        ],
    );

    // ========= Get data from sessionStorage =======
    useEffect(() => {
        if (window.sessionStorage.getItem('currentPartner')) {
            const partnerString =
                window.sessionStorage.getItem('currentPartner');
            if (partnerString) {
                const parsedPartner: Partner = JSON.parse(partnerString);
                setCurrentPartner(parsedPartner);
            }
        }
    }, []);

    // ========= Save data to sessionStorage =======
    useEffect(() => {
        if (currentPartner) {
            window.sessionStorage.setItem(
                'partnerAssessment',
                JSON.stringify(currentPartner),
            );
        }
    }, [currentPartner]);

    // ========= End -  sessionStorage =======

    return (
        <StoreContext.Provider value={storeMemo}>
            {children}
        </StoreContext.Provider>
    );
};

export default GlobalStoreProvider;

export const useGlobalStoreProvider = () => {
    const context = useContext(StoreContext);
    if (!context) {
        throw new Error(
            'useGlobalStoreProvider must be used within a GlobalStoreProvider',
        );
    }
    return context;
};
