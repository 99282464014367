import React from 'react';
import { SkillifyButton } from '@components/SkillifyButton';
import { CustomLink } from '@components/CustomLink';
import { useDynamicPagesContent } from '@lib/hooks/useDynamicPagesContent';
import { useLanguageProvider } from '@providers/LanguageProvider';
import type { CookieModal as CookieModalT } from '@digico/common/lib/CookieModal';
import { LoaderIcon } from '@components/Loader';

interface CookieModalProps {
    setVisibility: (val: boolean) => void;
}

export const COOKIE_KEY_POLICY = 'digico_cookie_policy';

const setCookie = (name: string, value: string, daysToExpire: number) => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + daysToExpire);

    const cookieString = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/`;

    document.cookie = cookieString;
};

export const doesCookieExist = (name: string) => {
    // Get all cookies
    const cookies = document.cookie.split(';');

    // Search for the specific cookie
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Check if the cookie starts with the desired name
        if (cookie.startsWith(`${name}=`)) {
            return true; // Cookie found
        }
    }

    return false; // Cookie not found
};

export const CookieModal: React.FC<CookieModalProps> = ({
    setVisibility,
}: CookieModalProps) => {
    const { countryCode } = useLanguageProvider();
    const [content, isLoading] = useDynamicPagesContent<CookieModalT>(
        countryCode,
        'cookieModal',
    );

    const onCancel = () => {
        setCookie(COOKIE_KEY_POLICY, 'not accepted', 365);
        setVisibility(false);
    };

    const onAccept = () => {
        // Expires in 1 year
        setCookie(COOKIE_KEY_POLICY, 'accepted', 365);
        setVisibility(false);
    };

    return (
        <div className="tw-fixed tw-inset-0 tw-z-[99999] tw-bg-black/50">
            <dialog
                open
                className="tw-bottom-0 tw-mb-10 tw-rounded-lg tw-border-none tw-p-8 tw-shadow-skillify sm:tw-max-w-[90%] md:tw-max-w-[80%] lg:tw-max-w-[80%] xl:tw-max-w-[75%] 2xl:tw-max-w-[50%]"
            >
                {isLoading ? (
                    <LoaderIcon />
                ) : (
                    <>
                        <h1 className="skillify-font tw-mb-2  tw-text-2xl tw-font-bold">
                            {content?.title}
                        </h1>
                        <p className="skillify-font tw-mb-1 tw-text-sm">
                            {content?.paragraphOne}
                        </p>
                        <p className="skillify-font tw-mb-1 tw-text-sm">
                            For more information about our&nbsp;
                            <span>
                                <CustomLink
                                    onClick={() => setVisibility(false)}
                                    href="/privacy-policy"
                                    data-testid="privacy-policy-link"
                                    type="button"
                                    className="skillify-font link tw-mb-1 tw-text-sm"
                                >
                                    {content?.privacyPolicy}
                                </CustomLink>
                            </span>
                            .
                        </p>
                        <div className=" tw-flex tw-justify-end tw-gap-8 tw-self-center">
                            <SkillifyButton
                                className="tw-w-min-48 tw-w-48"
                                size="sm"
                                onClick={onAccept}
                            >
                                {content?.acceptBtn}
                            </SkillifyButton>
                            <SkillifyButton
                                className="tw-w-min-48 tw-w-48"
                                onClick={onCancel}
                                buttonType="secondary"
                                size="sm"
                            >
                                {content?.declineBtn}
                            </SkillifyButton>
                        </div>
                    </>
                )}
            </dialog>
        </div>
    );
};
