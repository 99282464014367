import { useEffect, useState } from 'react';

export const useEffectOnMount = (callback: () => void) => {
    const [effectHasRun, setEffectHasRun] = useState(false);
    const effect = callback;
    useEffect(() => {
        if (!effectHasRun) {
            effect();
            setEffectHasRun(true);
        }
    }, [effect, effectHasRun]);
};
