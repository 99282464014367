import {
    Area,
    AssessmentCompentencyIdWithScore,
    AssessmentQuestionWithType,
    Score,
    StudentAnswer,
    VerificationQuestionOption,
    VerificationQuestionWithType,
} from '@lib/types';
import type { OptionCategory } from '@digico/common/lib/Options';
import React, { Fragment, useEffect, useState } from 'react';
import { ANALYTICS_NAMES } from '@lib/constants';
import { useAssessmentToolProvider } from '@providers/AssessmentToolProvider';
import { useGlobalStoreProvider } from '@providers/GlobalStoreProvider';
import { useRouter } from 'next/router';
import { AreaQuestion } from '@components/AssessmentTool/AreaQuestion';
import { NormalQuestionOptions } from '@components/AssessmentTool/NormalQuestionOption';
import { logAnalytic } from '@lib/helper/logAnalytic';
import { useAuthProvider } from '@providers/AuthProvider';
import { mapScore } from '@lib/helper/mapScore';
import {
    CalculateVerificationQuestionScore,
    VerificationDeterminateNextQuestionLevel,
} from '@components/Util/VerificationDeterminateNextQuestionLevel';
import { VerificationQuestion } from '@components/AssessmentTool/AreaQuestion/VerificationQuestion';
import { mapToVerificationQuestionLevel } from '@lib/helper/mapVerificationQuestionLevels';
import { compareLastTwoElementsOfArray } from '@lib/helper/compareLastTwoElementsOfArray';

interface AreaQuestionsContainerProps {
    mappedQuestion: {
        question: AssessmentQuestionWithType | VerificationQuestionWithType;
        index: number;
    };
    mappedArea: {
        area: Area;
        index: number;
    };
}

const isAssessmentQuestionWithType = (
    question: AssessmentQuestionWithType | VerificationQuestionWithType,
): question is AssessmentQuestionWithType =>
    (question as AssessmentQuestionWithType)?.assessmentElementId !== undefined;

const convertChosenScore = (score?: string | number): number | undefined => {
    let convertedScore = score;
    if (typeof score === 'string' && score?.includes(','))
        convertedScore = Number(score.split(',').join('.'));
    return convertedScore as number | undefined;
};

export const AreaQuestions: React.FC<AreaQuestionsContainerProps> = ({
    mappedQuestion,
    mappedArea,
}: AreaQuestionsContainerProps) => {
    const { isUserLoggedIn } = useAuthProvider();
    const {
        partnerAssessment,
        setStudentAnswers,
        studentAnswers,
        upcomingVerificationQuestion,
        setUpcomingVerificationQuestion,
        setGlobalAreaIndex,
        setGlobalQuestionIndex,
        globalQuestionIndex,
        setCreateResults,
        setLoadingResults,
        setShowMotivation,
        setRedirectToResults,
        areasQuestions,
        globalAreaIndex,
        verificationQuestionAssessmentCompetencyIdsProcessed,
        setVerificationQuestionAssessmentCompetencyIdsProcessed,
        setCurrentAnswerIndex,
    } = useAssessmentToolProvider();

    const { options, competencies } = useGlobalStoreProvider();
    const router = useRouter();
    const { partnerId } = router.query;
    const [questionNumber, setQuestionNumber] = useState(mappedArea.index);
    const [numberOfAreas] = useState(
        areasQuestions ? areasQuestions.length : 1,
    );
    const [verificationQuestionWasCorrect, setVerificationQuestionWasCorrect] =
        useState(false);

    useEffect(() => {
        // This is used to visualise Statement i of n
        setQuestionNumber(mappedQuestion.index);
    }, [mappedQuestion]);

    const handleVerificationQuestionAnsweredChange = (
        chosenAnswerIndex: number,
    ) => {
        if (upcomingVerificationQuestion) {
            const correctAnswerIndex =
                upcomingVerificationQuestion.question?.options?.findIndex(
                    (x) => x.isCorrect,
                );
            const wasCorrect = correctAnswerIndex === chosenAnswerIndex;
            console.info('wasCorrect', wasCorrect);
            setVerificationQuestionWasCorrect(wasCorrect);
            console.info('setting new value for verificationQuestionWasCorrect:', verificationQuestionWasCorrect);
            const selectedVerificationAnswer: StudentAnswer = {
                questionId: upcomingVerificationQuestion.id,
                areaId: mappedArea.area.id ?? '',
                area: mappedArea.area.name ?? '',
                assessmentCompetencyId:
                    upcomingVerificationQuestion.assessmentCompetencyId.id,
                isVerificationQuestion: true,
                chosenScore: CalculateVerificationQuestionScore(
                    upcomingVerificationQuestion.level,
                    wasCorrect,
                ),
                contentAnswer:
                    upcomingVerificationQuestion.question?.options?.[
                        chosenAnswerIndex
                    ].text ?? '',
                isCorrect: wasCorrect,
            };
            let currentStudentAnswers = studentAnswers;
            currentStudentAnswers = [
                ...currentStudentAnswers,
                selectedVerificationAnswer,
            ];
            setStudentAnswers(currentStudentAnswers);
        }
    };

    const getNextVerificationQuestion = (
        levelsOfAreaCompetences: AssessmentCompentencyIdWithScore[],
        verificationQuestionCorrect: boolean,
    ): VerificationQuestionWithType | any => {
        console.info('getNextVerificationQuestion: verificationQuestionCorrect', verificationQuestionCorrect);
        // Ignore low level questions
        let filteredLevelsOfAreaCompetences = levelsOfAreaCompetences.filter(
            (x) => x.score !== Score.LOW,
        );
        if (filteredLevelsOfAreaCompetences.length === 0) {
            return null;
        }
        if (areasQuestions) {
            console.info('areasQuestions', areasQuestions);
            const numberOfVerificationQuestions =
                filteredLevelsOfAreaCompetences.length * 2; // 2 = number of questions per competence that is not low
            const relatedCompetencies =
                areasQuestions[globalAreaIndex].competencies;

            const verificationStudentAnswers = studentAnswers
                .filter((x) => x.areaId === areasQuestions[globalAreaIndex].id)
                .filter((x) => x.isVerificationQuestion === true);

            const goToNextAssessmentCompetency = compareLastTwoElementsOfArray(
                verificationStudentAnswers,
                (a, b) =>
                    a && b
                        ? a.assessmentCompetencyId === b.assessmentCompetencyId
                        : false,
            );

            const goToNextQuestionInSameAssessmentCompetency =
                compareLastTwoElementsOfArray(
                    verificationStudentAnswers,
                    (a, b) =>
                        a?.assessmentCompetencyId !== b?.assessmentCompetencyId,
                );

            console.info('verificationStudentAnswers', verificationStudentAnswers);
            console.info('verificationStudentAnswers.length', verificationStudentAnswers.length);
            console.info('numberOfVerificationQuestions', numberOfVerificationQuestions);
            console.info('goToNextQuestionInSameAssessmentCompetency', goToNextQuestionInSameAssessmentCompetency);


            const goToNextArea =
                verificationStudentAnswers.length ===
                numberOfVerificationQuestions;

            const processedCompetencies: AssessmentCompentencyIdWithScore[] = [
                ...verificationQuestionAssessmentCompetencyIdsProcessed,
            ];
            if (goToNextAssessmentCompetency) {
                const processed = filteredLevelsOfAreaCompetences.filter(
                    (x) =>
                        !verificationQuestionAssessmentCompetencyIdsProcessed?.some(
                            (item) =>
                                item.assessmentCompetencyId ===
                                x.assessmentCompetencyId,
                        ),
                );
                processedCompetencies.push(processed[0]);
                setVerificationQuestionAssessmentCompetencyIdsProcessed(
                    processedCompetencies,
                );
            }

            filteredLevelsOfAreaCompetences =
                filteredLevelsOfAreaCompetences.filter(
                    (x) =>
                        !processedCompetencies?.some(
                            (item) =>
                                item.assessmentCompetencyId ===
                                x.assessmentCompetencyId,
                        ),
                );

            // Always take first element, the list will shrink when area competences have been verified
            let upcomingVerificationQuestionLevel =
                VerificationDeterminateNextQuestionLevel(
                    filteredLevelsOfAreaCompetences[0]?.score,
                );

            if (goToNextQuestionInSameAssessmentCompetency) {
                console.info('goToNextQuestionInSameAssessmentCompetency', goToNextQuestionInSameAssessmentCompetency);
                console.info('goToNextQuestionInSameAssessmentCompetency: verificationQuestionCorrect', verificationQuestionCorrect);
                upcomingVerificationQuestionLevel =
                    VerificationDeterminateNextQuestionLevel(
                        filteredLevelsOfAreaCompetences[0].score,
                        verificationQuestionCorrect,
                    );

                console.info('upcomingVerificationQuestionLevel', upcomingVerificationQuestionLevel);
            }

            console.info('goToNextArea', goToNextArea);
            if (goToNextArea) {
                console.info('Going to next area...');
                return null;
            }

            const poolOfVerificationQuestions: VerificationQuestionWithType[] =
                relatedCompetencies
                    .filter(
                        (competency) =>
                            competency.assessmentAreaId.id ===
                                mappedArea.area.id &&
                            filteredLevelsOfAreaCompetences.some(
                                (comp) =>
                                    comp.assessmentCompetencyId ===
                                    competency.id,
                            ),
                    )
                    .flatMap((competency) => {
                        if (
                            processedCompetencies.find(
                                (x) =>
                                    x.assessmentCompetencyId === competency.id,
                            )
                        ) {
                            return [];
                        }
                        if (competency.questions) {
                            return competency.questions.map((question) => ({
                                question: {
                                    text: question.text,
                                    image: question.image,
                                    options: question.options,
                                    presentationStyle:
                                        question.questionDisplayStyle,
                                },
                                type: 'verification',
                                level: mapToVerificationQuestionLevel(
                                    question.level,
                                ),
                                assessmentCompetencyId: {
                                    id: competency.id,
                                },
                                id: `${competency.id}_${question.text}`,
                            }));
                        }

                        return [];
                    });
            const rawIds = sessionStorage.getItem('uniqueQuestionIds');
            const ids = rawIds ? JSON.parse(rawIds) : [];
            const possibleVerificationQuestions = poolOfVerificationQuestions
                .filter((x) => x.level === upcomingVerificationQuestionLevel)
                .filter((x) => ids.indexOf(x.id) === -1);

            const nextVerificationQuestion = possibleVerificationQuestions[0];
            ids.push(nextVerificationQuestion?.id);
            sessionStorage.setItem('uniqueQuestionIds', JSON.stringify(ids));
            // TODO: extra logic? Do we need to randomize it? Not specified. If so, it needs to be put here.
            return nextVerificationQuestion;
        }
        return null;
    };

    const calculateLevelsForCurrentAreaCompetences =
        (): AssessmentCompentencyIdWithScore[] => {
            const currentAreaId = mappedArea.area.id;
            // TODO: Fix issue with last answer not yet in array at click next
            const currentAreaAnswers = studentAnswers.filter(
                (answers) => answers.areaId === currentAreaId,
            );
            const currentAreaCompetenceLevels: AssessmentCompentencyIdWithScore[] =
                [];
            const matchingCompetencyAnswersInArea: any[] = [];
            // We need to find which questions belong to which competencies
            // We then need to aggregate the scores per competency
            // Then take the average to determine the score
            competencies?.forEach((competency) => {
                if (competency.assessmentAreaId.id === currentAreaId) {
                    const matchingCompetencyAnswers = currentAreaAnswers.filter(
                        (x) =>
                            x.assessmentCompetencyId === competency.id &&
                            !x.isVerificationQuestion,
                    );
                    matchingCompetencyAnswers.forEach((mc) => {
                        if (
                            matchingCompetencyAnswersInArea.findIndex(
                                (c) => c.questionId === mc.questionId,
                            ) === -1
                        ) {
                            matchingCompetencyAnswersInArea.push(mc);
                        }
                    });
                    const totalScore = matchingCompetencyAnswersInArea.reduce(
                        (sum, answer) => sum + answer.chosenScore,
                        0,
                    );
                    const averageScore =
                        totalScore / matchingCompetencyAnswersInArea.length;

                    currentAreaCompetenceLevels.push({
                        assessmentCompetencyId: competency.id,
                        score: mapScore(averageScore),
                    });
                }
            });

            return currentAreaCompetenceLevels;
        };

    const onNextClick = (
        questionsLength: number,
        verificationQuestionCorrect = false,
    ) => {
        if (verificationQuestionCorrect !== undefined) {
            console.info('verificationQuestionCorrect', verificationQuestionCorrect);
        }
        console.info(
            `Questions Completed:${globalQuestionIndex}/${questionsLength}`,
        );
        // Need to set to null, so when you go back and change an answer the appearance of the answer will be reset
        setCurrentAnswerIndex(null);
        const endOfArea = globalQuestionIndex + 1 > questionsLength;
        let goToResults = endOfArea;
        let inVerificationMode = false;
        console.info('endOfArea', endOfArea);
        if (endOfArea) {
            console.info('Should be end of Area');
            // We have reached the end of the area
            // Get score based on the normal questions:
            const levelsOfAreaCompetences =
                calculateLevelsForCurrentAreaCompetences();
            const nextVerificationQuestion = getNextVerificationQuestion(
                levelsOfAreaCompetences,
                verificationQuestionCorrect,
            );
            console.info('nextVerificationQuestion', nextVerificationQuestion);
            if (
                nextVerificationQuestion !== null &&
                nextVerificationQuestion !== undefined
            ) {
                console.info('Verification Questions');
                // Preprocess possible verification questions
                setUpcomingVerificationQuestion(nextVerificationQuestion);
                mappedArea.area.questions.push(nextVerificationQuestion);
                // If we have a pending verification question, then we show it!

                const verificationStudentAnswers = studentAnswers
                    .filter((x) => x.areaId === mappedArea.area.id)
                    .filter((x) => x.isVerificationQuestion === true);
                const numberOfVerificationQuestions =
                    levelsOfAreaCompetences.filter((x) => x.score !== Score.LOW)
                        .length * 2;

                console.info(
                    'numberOfVerificationQuestions:',
                    numberOfVerificationQuestions,
                );
                if (
                    verificationStudentAnswers.length <
                    numberOfVerificationQuestions
                ) {
                    // There is one exception when all is low:
                    const answersForThisArea = studentAnswers.filter(
                        (x) => x.areaId === mappedArea.area.id,
                    );
                    const allIsZero = answersForThisArea
                        .filter((x) => x.isVerificationQuestion === false)
                        .every((x) => x.chosenScore === 0);

                    const levelOfArea =
                        calculateLevelsForCurrentAreaCompetences();

                    // @ts-ignore
                    if (!allIsZero && levelOfArea !== Score.LOW) {
                        inVerificationMode = true;
                    } else {
                        inVerificationMode = false;
                    }
                } else {
                    inVerificationMode = false;
                }
            } else {
                console.info('toggling in verification mode to false');
                inVerificationMode = false;
                setUpcomingVerificationQuestion(null);
            }
        }
        console.info('in verificationmode:', inVerificationMode);
        //-----------------------------------------------------------
        // Move to the next question logic
        setGlobalQuestionIndex((index) => index + 1);
        if (!inVerificationMode) {
            console.info('globalQuestionIndex', globalQuestionIndex);
            console.info('questionsLength', questionsLength);
            if (globalQuestionIndex >= questionsLength) {
                // If we have gone through all normal questions
                console.info('No normal questions left.');
                console.info('globalAreaIndex', globalAreaIndex);
                console.info('numberOfAreas', numberOfAreas);
                if (globalAreaIndex + 1 < numberOfAreas) {
                    console.info('End of Area');
                    // and there are other areas left: GO TO NEXT AREA
                    setShowMotivation(true);
                    setGlobalAreaIndex((a) => a + 1);
                    setGlobalQuestionIndex(1); // because of reasons the first item in index 1 instead of 0...
                    goToResults = false;
                    console.info('Completed area:', globalAreaIndex + 1);
                } else {
                    // and there are no other areas left
                    setShowMotivation(true);
                    goToResults = true;
                    console.info('Completed area:', globalAreaIndex + 1);
                    console.info('No next areas left, going to results');
                }
            }

            if (goToResults) {
                if (isUserLoggedIn) {
                    setShowMotivation(false);
                    setLoadingResults(true);
                    setRedirectToResults(true);
                } else {
                    setShowMotivation(true);
                    setRedirectToResults(false);
                }
                setCreateResults(true);
            }
        }
    };

    const logAnalytics = (selectedAnswer: StudentAnswer) => {
        // Log question information for analytics
        // Requirement to send it after every question because we
        // would like to know where tests are cut of if the case, i.e. incomplete assessments
        logAnalytic(ANALYTICS_NAMES.assessmentArea, {
            event: 'questionAnswered',
            capturedData: studentAnswers,
            answer: selectedAnswer,
            areaName: areasQuestions?.[globalAreaIndex].name,
            question:
                areasQuestions?.[globalAreaIndex]?.questions?.[
                    globalQuestionIndex
                ],
            partnerId,
        });
    };

    const onOptionClick = (
        optionIndex: number,
        questionId: string,
        assessmentCompetencyId: string,
        questionsLength: number,
        areaIndex: number,
        questionType: string,
        questionIndex: number,
        areaName: string | undefined,
        areaId: string | undefined,
        chosenScore: number | undefined,
        isCorrect: boolean | undefined,
        contentAnswer: string | undefined,
        isVerificationQuestion: boolean | undefined,
    ) => {
        const selectedAnswer: StudentAnswer = {
            questionId,
            chosenScore: convertChosenScore(chosenScore),
            isCorrect,
            area: areaName ?? '',
            areaId: areaId ?? '',
            assessmentCompetencyId,
            contentAnswer,
            isVerificationQuestion,
        };

        logAnalytics(selectedAnswer);
        console.info('selectedAnswer', selectedAnswer);
        let currentStudentAnswers = studentAnswers;
        currentStudentAnswers = [...currentStudentAnswers, selectedAnswer];
        setStudentAnswers(currentStudentAnswers);
        console.info('currentStudentAnswers', currentStudentAnswers);
        console.info('questionsLength', questionsLength);

        onNextClick(questionsLength);
    };

    const normalQuestionsOptions = options?.map((element: OptionCategory) => {
        const isMatchingId =
            isAssessmentQuestionWithType(mappedQuestion.question) &&
            element.id === mappedQuestion.question.assessmentElementId?.id;
        return (
            <Fragment key={element.id}>
                {isMatchingId && (
                    <NormalQuestionOptions
                        options={element.options}
                        onOptionClick={onOptionClick}
                        questionsLength={
                            mappedArea.area.questions.filter(
                                (x) => x.type === 'normal',
                            ).length
                        }
                        areaIndex={mappedArea.index}
                        questionType={mappedQuestion.question.type}
                        questionIndex={mappedQuestion.index}
                        areaName={mappedArea.area.name}
                        areaId={mappedArea.area.id}
                        questionId={mappedQuestion.question.id ?? ''}
                        assessmentCompetencyId={
                            mappedQuestion.question.assessmentCompetencyId.id
                        }
                    />
                )}
            </Fragment>
        );
    });

    if (upcomingVerificationQuestion && upcomingVerificationQuestion.question) {
        return (
            <VerificationQuestion
                key={`${partnerAssessment?.main?.verificationQuestionConvention}_${upcomingVerificationQuestion.question?.text}`}
                titleSmallScreen={
                    partnerAssessment?.main?.verificationQuestionConvention
                }
                titleBigScreen={
                    partnerAssessment?.main?.verificationQuestionConvention
                }
                onNextClick={() => {
                    onNextClick(
                        mappedArea.area.questions.filter(
                            (x) => x.type === 'normal',
                        ).length,
                        verificationQuestionWasCorrect,
                    );
                }}
                questionText={upcomingVerificationQuestion.question?.text}
                questionOptions={
                    upcomingVerificationQuestion.question
                        .options as VerificationQuestionOption[]
                }
                onVerificationQuestionAnsweredChange={
                    handleVerificationQuestionAnsweredChange
                }
            />
        );
    }

    if (mappedQuestion.question?.type === 'normal') {
        const normalQuestions = mappedArea.area.questions.filter(
            (question) => question.type === 'normal',
        );
        let statementText = partnerAssessment?.main?.statementNamingConvention;
        statementText = statementText?.replace(
            /{x}/i,
            questionNumber.toString(),
        );
        statementText = statementText?.replace(
            /{y}/i,
            normalQuestions.length.toString(),
        );

        return (
            <AreaQuestion
                key={`${mappedArea.area.name} ${questionNumber}/${normalQuestions.length}`}
                titleSmallScreen={`${mappedArea.area.name} ${questionNumber}/${normalQuestions.length}`}
                titleBigScreen={`${mappedArea.area.name} ${statementText}`}
                question={mappedQuestion.question.question?.text}
                questionOptions={normalQuestionsOptions}
            />
        );
    }

    // TODO: CHECK WHY WE CREATE UI ELEMENTS HERE AND NOT LET THE OTHER COMPONENT TAKE THAT RESPONSIBILITY!

    return null;
};
