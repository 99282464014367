import { useEffect } from 'react';

export const useDisableRightClick = () => {
    useEffect(() => {
        document.addEventListener('contextmenu', (event) =>
            event.preventDefault(),
        );

        return () =>
            document.removeEventListener('contextmenu', (event) =>
                event.preventDefault(),
            );
    }, []);
};
