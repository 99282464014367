// firebase-config.json gets added at build time
// Keep in sync with components/_common/src/config.ts
import sdkConfig from './firebase-config.json';

const DIGICO_URL_PROD_SIGNIN = 'https://skillify.digico.global/sign-in';
const DIGICO_URL_TEST_SIGNIN = 'https://test.digico.global/sign-in';
const DIGICO_URL_ACCEPTANCE_SIGNIN = 'https://acceptance.digico.global/sign-in';

const DIGICO_URL_PROD = 'https://skillify.digico.global/';
const DIGICO_URL_TEST = 'https://test.digico.global/';
const DIGICO_URL_ACCEPTANCE = 'https://acceptance.digico.global/';

const servicesUrlProd = 'https://us-central1-digico-global.cloudfunctions.net/';
const servicesUrlTest =
    'https://us-central1-test-digico-global.cloudfunctions.net/';
const servicesUrlAcceptance =
    'https://us-central1-acceptance-digico-global-00001.cloudfunctions.net/';

export const firebaseConfig = sdkConfig.result.sdkConfig;
export const servicesURL =
    firebaseConfig.projectId === 'digico-global'
        ? servicesUrlProd
        : firebaseConfig.projectId === 'acceptance-digico-global-00001'
        ? servicesUrlAcceptance
        : servicesUrlTest;

export const DIGICO_URL =
    firebaseConfig.projectId === 'digico-global'
        ? DIGICO_URL_PROD
        : firebaseConfig.projectId === 'acceptance-digico-global-00001'
        ? DIGICO_URL_ACCEPTANCE
        : DIGICO_URL_TEST;

export const DIGICO_URL_SIGNIN =
    firebaseConfig.projectId === 'digico-global'
        ? DIGICO_URL_PROD_SIGNIN
        : firebaseConfig.projectId === 'acceptance-digico-global-00001'
        ? DIGICO_URL_ACCEPTANCE_SIGNIN
        : DIGICO_URL_TEST_SIGNIN;
