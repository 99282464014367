import { useCallback, useEffect, useState } from 'react';
import { getCookie } from '@lib/helper/getCookie';

export const useCookieListener = (name: string) => {
    const [cookie, setCookie] = useState<string | undefined>('emptyCookie');

    const checkCookie = useCallback(() => {
        let lastCookies = window.document.cookie;

        return () => {
            const currentCookies = window.document.cookie;
            if (currentCookies !== lastCookies) {
                const c = getCookie(name, currentCookies);
                setCookie(c);
                lastCookies = currentCookies; // store latest cookie
            }
        };
    }, [name]);

    useEffect(() => {
        if (window) {
            window.setInterval(checkCookie(), 100);
        }
    }, [checkCookie]);

    return cookie;
};
