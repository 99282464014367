import React from 'react';
import { useAssessmentToolProvider } from '@providers/AssessmentToolProvider';
import { AnimatedProgressBar } from '@components/AnimatedProgressBar';
import { Card } from '@components/Card';
import type { Partner } from '@digico/common/lib/Partner';
import { PartnerLogo } from '@components/AssessmentTool/PartnerLogo';

interface LoadingResultsProps {
    partnerData: Partner | undefined;
}
export const LoadingResults: React.FC<LoadingResultsProps> = ({
    partnerData,
}: LoadingResultsProps) => {
    const { partnerAssessment } = useAssessmentToolProvider();
    return (
        <div className="tw-w-full tw-p-8">
            <header>
                <PartnerLogo partnerData={partnerData} />
            </header>
            <Card textWhite>
                <div className="tw-flex tw-h-[28rem] tw-flex-col tw-items-center tw-justify-center tw-px-8">
                    <h1>{partnerAssessment?.main?.loading}</h1>
                    <span className="tw-sr-only">.</span>
                    <AnimatedProgressBar />
                </div>
            </Card>
        </div>
    );
};
