import { doc, runTransaction } from '@firebase/firestore';
import { ResultGroupPathV2, StudentPath } from '@lib/firebase/firestorePaths';
import { db } from '@lib/firebase/firestore-crud';

export const addStudentIdToResultGroup = async (
    studentId: string,
    resultGroupId: string,
) => {
    await runTransaction(db, async (transaction) => {
        const sfDocRef = doc(db, ResultGroupPathV2, resultGroupId);
        const sfDoc = await transaction.get(sfDocRef);
        if (!sfDoc.exists()) {
            throw new Error('Document does not exist!');
        }
        transaction.update(sfDocRef, {
            studentId: doc(db, StudentPath, studentId),
        });
    });
    await runTransaction(db, async (transaction) => {
        const sfDocRefStudent = doc(db, StudentPath, studentId);
        const sfDocsStudent = await transaction.get(sfDocRefStudent);
        if (!sfDocsStudent.exists()) {
            throw new Error('Document does not exist!');
        }
        transaction.update(sfDocRefStudent, {
            id: doc(db, StudentPath, studentId),
        });
    });
};
