import { Score, VerificationQuestionLevel } from '@lib/types';

export const VerificationDeterminateNextQuestionLevel = (
    areaLevel: Score,
    firstQuestionWasCorrect?: boolean,
): VerificationQuestionLevel => {
    if (areaLevel === null) return VerificationQuestionLevel.NONE;
    if (firstQuestionWasCorrect === undefined) {
        // Meaning we have not answered any verifications yet
        switch (areaLevel) {
            case Score.LOW:
                return VerificationQuestionLevel.NONE;
            case Score.FOUNDATION:
                return VerificationQuestionLevel.FOUNDATIONHARD;
            case Score.INTERMEDIATE:
                return VerificationQuestionLevel.INTERMEDIATE;
            case Score.ADVANCED:
                return VerificationQuestionLevel.INTERMEDIATE;
            default:
                return VerificationQuestionLevel.NONE;
        }
    } else {
        // We must have answered a verification question, so now we follow up
        switch (areaLevel) {
            case Score.FOUNDATION:
                return firstQuestionWasCorrect
                    ? VerificationQuestionLevel.INTERMEDIATE
                    : VerificationQuestionLevel.FOUNDATIONEASY;
            default:
                return firstQuestionWasCorrect
                    ? VerificationQuestionLevel.ADVANCED
                    : VerificationQuestionLevel.FOUNDATIONHARD;
        }
    }
    return VerificationQuestionLevel.NONE;
};

export const CalculateVerificationQuestionScore = (
    level: VerificationQuestionLevel,
    wasCorrect: boolean,
) => {
    if (!wasCorrect) return 0;
    switch (level) {
        case VerificationQuestionLevel.FOUNDATIONEASY:
            return 1;
        case VerificationQuestionLevel.FOUNDATIONHARD:
            return 2;
        case VerificationQuestionLevel.INTERMEDIATE:
            return 4;
        case VerificationQuestionLevel.ADVANCED:
            return 7;
        default:
            return 0;
    }
};
