import { getAnalytics, logEvent } from 'firebase/analytics';
import { firebaseApp } from '@lib/firebase/firebase';
import { servicesURL } from '@lib/firebase/config';
import { getData } from '../../services/api';

/**
 * Takes in an eventName and Params, and will post the analytics to firestore
 *
 * @param {string} eventName Name given to the event
 * @param {
 *         [key: string]: any;
 *     } params Params of the event
 */

export const logAnalytic = (
    eventName: string,
    params: {
        [key: string]: any;
    },
) => {
    if (typeof window === 'undefined') return;
    const analytics = getAnalytics(firebaseApp);
    logEvent(analytics, eventName, params);

    const { event, partnerId } = params;
    if (!event || !partnerId) return;

    getData(`${servicesURL}service-handleAnalytics`, 'POST', {
        eventName: event,
        partnerId,
    });
};
