import { useEffect, useState } from 'react';
import { isTruthy } from '../helper/isTruthy';

export const useIsLoading = (loadingBooleans: any[]) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(loadingBooleans.some(isTruthy));
    }, [loadingBooleans]);

    return isLoading;
};
