import { StudentAnswer } from '@lib/types';
import React from 'react';

interface ProgressBarProps {
    globalQuestionIndex: number;
    areaProgress: any;
    questionsLength: number;
    studentAnswers: StudentAnswer[];
    firstArea: boolean;
    currentArea: boolean;
}

// This function is to calculate the percentage of progress for Area
export const getProgressPercentageForArea = (
    questionsLength: number,
    areaId: string,
    studentAnswers: StudentAnswer[],
) => {
    if (studentAnswers.length > 0) {
        const areaAnswers = studentAnswers.filter(
            (item) => item.areaId === areaId,
        );
        const normalAreaAnswers = areaAnswers.filter(
            (answer, index) => index < questionsLength,
        );
        return (100 * normalAreaAnswers.length) / questionsLength;
    }
    return 0;
};

// This function to check if the Area has any progress or not (based on the progress percentage)
const hasProgressArea = (
    questionsLength: number,
    areaId: string,
    studentAnswers: StudentAnswer[],
) => getProgressPercentageForArea(questionsLength, areaId, studentAnswers) > 0;

// ClassName for the progress container in each area:
// from the 2nd area, if there is any progress OR it is current area, the CSS will change the dot into white color
const progressContainerClassName = (
    questionsLength: number,
    areaId: string,
    studentAnswers: StudentAnswer[],
    currentArea: boolean,
) => {
    const commonClasses =
        'before:tw-w-[0.45rem] before:tw-w-[0.45rem] before:tw-h-[0.45rem] before:tw-rounded-[0.225rem] before:tw-absolute before:tw-top-[50%] ltr:before:tw-translate-x-[-150%] rtl:before:tw-translate-x-[150%] before:tw-translate-y-[-50%]';
    if (
        hasProgressArea(questionsLength, areaId, studentAnswers) ||
        currentArea
    ) {
        return `${commonClasses} before:tw-bg-white before:tw-z-[2]`;
    }
    return `${commonClasses} before:tw-bg-skillify-color`;
};

// ClassName for each progress bar:
// from the 2nd area, if there is any progress, the CSS will add the solid square to cover the round corner of the previous area
const progressClassName = (
    normalQuestionLength: number,
    areaId: string,
    studentAnswers: StudentAnswer[],
) => {
    const commonClasses =
        'tw-bg-skillify-color tw-h-[0.9rem] ltr:tw-rounded-r-[0.45rem] rtl:tw-rounded-l-[0.45rem]';
    if (hasProgressArea(normalQuestionLength, areaId, studentAnswers)) {
        return `${commonClasses} before:tw-w-[0.7rem] before:tw-h-[0.9rem] before:tw-bg-skillify-color before:tw-absolute ltr:before:tw-translate-x-[-90%] rtl:before:tw-translate-x-[50%] tw-transition-all tw-ease-linear tw-duration-400`;
    }
    return commonClasses;
};

export const ProgressBar: React.FC<ProgressBarProps> = ({
    globalQuestionIndex,
    areaProgress,
    questionsLength,
    studentAnswers,
    firstArea,
    currentArea,
}: ProgressBarProps) => (
    <div
        className={`tw-relative tw-max-w-full tw-flex-1 tw-flex-grow tw-px-6 tw-ps-0 tw-pe-0 ${
            globalQuestionIndex === 0 ? 'tw-bg-skillify-color' : ''
        }  ${
            firstArea
                ? ''
                : progressContainerClassName(
                      questionsLength,
                      areaProgress.areaId,
                      studentAnswers,
                      currentArea,
                  )
        }`}
    >
        <div
            className={`${
                globalQuestionIndex === 0
                    ? ''
                    : firstArea
                    ? 'tw-duration-400 tw-h-[0.9rem] tw-rounded-[0.45rem] tw-bg-skillify-color tw-transition-all tw-ease-linear'
                    : progressClassName(
                          questionsLength,
                          areaProgress.areaId,
                          studentAnswers,
                      )
            }`}
            role="progressbar"
            aria-label="progressbar"
            style={{
                width: `${getProgressPercentageForArea(
                    questionsLength,
                    areaProgress.areaId,
                    studentAnswers,
                )}%`,
            }}
        />
    </div>
);
