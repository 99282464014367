import useFieldApi from '@data-driven-forms/react-form-renderer/use-field-api';

type InputRadioItem = {
    label: string;
    value: string;
};

export const InputRadio = (props: any) => {
    const {
        label,
        input,
        cols,
        meta: { error, touched },
        savedValue,
        options,
    } = useFieldApi(props);
    return (
        <div className="tw-mb-6">
            <p className="skillify-font tw-text-base tw-font-semibold tw-text-skillify-color lg:tw-text-lg lg:tw-font-bold">
                {label}
            </p>
            <div
                className={`tw-mb-1 tw-mt-2 tw-w-full ${
                    cols ? `tw-grid tw-grid-cols-${cols}` : 'tw-flex tw-gap-6'
                } tw-justify-between`}
            >
                {options.map((item: InputRadioItem) => (
                    <div
                        className="tw-flex tw-items-center tw-gap-2"
                        key={item.label + item.value} // TODO: Verify
                    >
                        <input
                            className={`
                            tw-form-radio 
                            tw-scale-[1.15] 
                            tw-cursor-pointer
                            tw-border-[2.5px]
                            tw-border-solid
                            checked:tw-bg-skillify-color
                            checked:hover:tw-bg-skillify-color
                            checked:focus:tw-bg-skillify-color
                            ${
                                touched && error
                                    ? 'tw-border-red-500 focus:tw-ring-red-500'
                                    : 'tw-border-skillify-color focus:tw-ring-skillify-color'
                            }`}
                            id={item.value}
                            name={input.name}
                            type={input.type}
                            onChange={input.onChange}
                            value={item.value}
                            defaultChecked={savedValue === item.value}
                        />
                        <label
                            className={`skillify-font tw-cursor-pointer tw-text-sm tw-font-semibold tw-text-skillify-color lg:tw-text-base ${
                                touched && error ? 'tw-text-red-500' : ''
                            }`}
                            htmlFor={item.value}
                        >
                            {item.label}
                        </label>
                    </div>
                ))}
            </div>
            {touched && error && (
                <div className="skillify-font tw-inline tw-text-sm tw-font-medium tw-text-red-500">
                    {error}
                </div>
            )}
        </div>
    );
};
