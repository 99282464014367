import { CmsHookArgsT } from '@lib/types';
import { collection, doc, getDoc, getFirestore } from '@firebase/firestore';
import { firebaseApp } from '@lib/firebase/firebase';
import { useCollectionData } from 'react-firebase-hooks/firestore';

/**
 * React hook for retrieval of collection data from firestore cms
 *
 * @param {string} collectionPath The path of the firestore collection
 * @param {array<string>} requiredProperties The properties required from the collection > doc snapshot. Defaults to an empty array.
 * @param {boolean} translationRequired Whether or not the data will need to be translated. Defaults to false
 * @param {string} countryCode The country code needed to get the correct translation. Defaults to 'en'
 * @returns {array<any>} The collection data
 */
export const useCmsCollectionData = ({
    collectionPath,
    requiredProperties = [],
    translationRequired = false,
    countryCode = 'en',
}: CmsHookArgsT) => {
    const fireStore = getFirestore(firebaseApp);

    const snapshot: any = useCollectionData(
        collection(fireStore, collectionPath),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
            idField: 'id',
        },
    );
    if (translationRequired) {
        if (countryCode === 'en') return snapshot[0];

        if (snapshot.length > 0 && Array.isArray(snapshot[0])) {
            const promises: any = [];
            snapshot[0].forEach((docu) => {
                if (docu.id) {
                    promises.push(
                        getDoc(
                            doc(
                                fireStore,
                                `${collectionPath}/${docu.id}/locales/${countryCode}`,
                            ),
                        ),
                    );
                }
            });

            const data: any = [];
            return Promise.all(promises)
                .then((promise) =>
                    promise.forEach((locale, i) => {
                        const translation: any = locale.data();
                        if (requiredProperties?.length > 0 && translation) {
                            requiredProperties.forEach((property) => {
                                if (snapshot[0][i][property])
                                    translation[property] =
                                        snapshot[0][i][property];
                            });
                        }
                        data.push(translation);
                    }),
                )
                .then(() => data);
        }
        return snapshot;
    }

    return snapshot;
};
