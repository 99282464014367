import { VerificationQuestionLevel } from '@lib/types';

export const mapToVerificationQuestionLevel = (level: any) => {
    switch (level) {
        case 'foundationEasy':
        case 'Foundation Easy':
            return VerificationQuestionLevel.FOUNDATIONEASY;
        case 'foundationHard':
        case 'Foundation Hard':
            return VerificationQuestionLevel.FOUNDATIONHARD;
        case 'intermediate':
        case 'Intermediate':
            return VerificationQuestionLevel.INTERMEDIATE;
        case 'advanced':
        case 'Advanced':
            return VerificationQuestionLevel.ADVANCED;
        default:
            return VerificationQuestionLevel.FOUNDATIONEASY;
    }
};

export const mapToVerificationLevelString = (
    level: VerificationQuestionLevel,
) => {
    switch (level) {
        case VerificationQuestionLevel.FOUNDATIONEASY:
            return 'Foundation Easy';
        case VerificationQuestionLevel.FOUNDATIONHARD:
            return 'Foundation Hard';
        case VerificationQuestionLevel.INTERMEDIATE:
            return 'Intermediate';
        case VerificationQuestionLevel.ADVANCED:
            return 'Advanced';
        default:
            return 'None';
    }
};
