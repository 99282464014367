import { GetServerSideProps } from 'next';
import PartnerAssessmentPage, {
    PartnerAssessmentPageProps,
} from './PartnerAssessmentPage';

export default PartnerAssessmentPage;

export const getServerSideProps: GetServerSideProps = async (
    context,
): Promise<{ props: PartnerAssessmentPageProps }> => ({
    props: {
        partnerId: context.query.partnerId ?? null,
        assessmentId: context.query.assessmentId ?? null,
        intro: context.query.intro ?? null,
        areaId: context.query.areaId ?? null,
    }, // will be passed to the page component as props
});
