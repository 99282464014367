import React from 'react';
import { useAssessmentToolProvider } from '@providers/AssessmentToolProvider';
import { Area } from '@lib/types';
import { SkillifyButton } from '@components/SkillifyButton';
import { AreaQuestions } from '@components/AssessmentTool/AreaQuestions';

interface AreaQuestionsContainerProps {
    mappedArea: {
        index: number;
        area: Area;
    };
}
/* eslint-disable react/no-array-index-key */
export const AreaQuestionsContainer: React.FC<AreaQuestionsContainerProps> = ({
    mappedArea,
}: AreaQuestionsContainerProps) => {
    const {
        areasQuestions,
        globalAreaIndex,
        globalQuestionIndex,
        partnerAssessment,
        studentVerificationAnswers,
        studentAnswers,
        setCurrentAnswer,
        setCurrentAnswerIndex,
        setGlobalQuestionIndex,
    } = useAssessmentToolProvider();

    const onBackButton = () => {
        const index = globalQuestionIndex - 2;
        const selectedAnswer = studentAnswers[index];
        if (studentAnswers[studentAnswers.length - 1].isVerificationQuestion) {
            studentVerificationAnswers.pop();
        }
        studentAnswers.pop();
        setCurrentAnswer({
            questionId: selectedAnswer.questionId,
            chosenScore: selectedAnswer.chosenScore,
            isCorrect: selectedAnswer.isCorrect,
            area: selectedAnswer.area,
            areaId: selectedAnswer.areaId,
            assessmentCompetencyId: selectedAnswer.assessmentCompetencyId,
            contentAnswer: selectedAnswer.contentAnswer,
            isVerificationQuestion: selectedAnswer.isVerificationQuestion,
        });

        switch (selectedAnswer.chosenScore) {
            case 0:
                setCurrentAnswerIndex(0);
                break;
            case 0.33:
                setCurrentAnswerIndex(1);
                break;
            case 0.66:
                setCurrentAnswerIndex(2);
                break;
            case 1:
                setCurrentAnswerIndex(3);
                break;
            default:
                break;
        }

        setGlobalQuestionIndex((prevState) => prevState - 1);

        console.info('Global Question Index:', globalQuestionIndex);
        if (areasQuestions) {
            const currentIndex = globalQuestionIndex - 1;

            const currentAreaQuestions =
                areasQuestions[globalAreaIndex].questions;
            const currentAreaNormalQuestions = currentAreaQuestions.filter(
                (item) => item.type !== 'verification',
            );
            const hasVerificationQuestions = currentAreaQuestions.some(
                (item) => item.type === 'verification',
            );

            if (
                currentIndex === currentAreaNormalQuestions.length - 1 &&
                hasVerificationQuestions
            ) {
                areasQuestions[globalAreaIndex].questions =
                    currentAreaNormalQuestions;
            }
        }
    };
    return (
        <div>
            {mappedArea.index === globalAreaIndex &&
                mappedArea.area.questions.map((question, index) => (
                    <div key={`${question?.id}-${index}`}>
                        {globalQuestionIndex > 0 &&
                            index === globalQuestionIndex &&
                            question?.element !== 'intro' && (
                                <div className="tw-mt-6 ">
                                    <AreaQuestions
                                        mappedQuestion={{
                                            question,
                                            index,
                                        }}
                                        mappedArea={mappedArea}
                                    />
                                    {/* Id of verification questions consists of id_question */}
                                    {globalQuestionIndex > 1 &&
                                        !question?.id.includes('_') && (
                                            <div className="tw-min-w-36 tw-flex">
                                                {/* Check if question is verification question */}
                                                <SkillifyButton
                                                    className="tw-text-xs tw-text-start md:tw-text-sm"
                                                    isBackButton
                                                    onClick={() =>
                                                        onBackButton()
                                                    }
                                                >
                                                    {
                                                        partnerAssessment?.main
                                                            ?.cta?.back
                                                    }
                                                </SkillifyButton>
                                            </div>
                                        )}
                                </div>
                            )}
                    </div>
                ))}
        </div>
    );
};
