import React, { useState, useEffect } from 'react';
import { useAssessmentToolProvider } from '@providers/AssessmentToolProvider';
import { Image } from '@components/Image';
import { useInnerWindowWidth } from '@lib/hooks/useInnerWindowWidth';
import { SkillifyButton } from '@components/SkillifyButton';
import { useLanguageProvider } from '@providers/LanguageProvider';

export const Tutorial: React.FC = () => {
    const {
        partnerAssessment,
        setGlobalQuestionIndex,
        setWatchTutorial,
        setHasWatchedTutorial,
        setShowPrivacyGuidelines,
    } = useAssessmentToolProvider();
    const { countryCode } = useLanguageProvider();
    const [display, setDisplay] = useState(false);

    const windowInnerWidth = useInnerWindowWidth();

    const endTutorialSmallScreen = () => {
        setGlobalQuestionIndex((prevState) => prevState + 1);
        setWatchTutorial(false);
        setShowPrivacyGuidelines(true);
        setHasWatchedTutorial(true);
    };
    const endTutorialBigScreen = () => {
        setGlobalQuestionIndex((prevState) => prevState + 1);
        setWatchTutorial(false);
        setHasWatchedTutorial(true);
    };

    useEffect(() => {
        sessionStorage.removeItem('uniqueQuestionIds');
        const privacyConsentCheckbox =
            document.getElementById('privacy-consent');
        if (privacyConsentCheckbox) {
            privacyConsentCheckbox.oninvalid = function (e: any) {
                e?.target?.setCustomValidity(
                    partnerAssessment?.main?.requiredMessageCheckbox,
                );
            };
        }
    }, []);

    if (windowInnerWidth && windowInnerWidth < 768)
        return (
            <section className="tw-flex tw-flex-col tw-items-center">
                <h1 className="skillify-font tw-text-2xl tw-font-bold">
                    {partnerAssessment?.tutorialMobile?.mainTitle}
                    <span className="tw-sr-only">.</span>
                </h1>
                <div className="tw-sr-only">
                    {partnerAssessment?.tutorialMobile?.explanation}
                </div>
                <Image
                    className="tw-mb-5 tw-mt-2 tw-w-full tw-p-10 tw-pb-2"
                    src={`/images/tutorial-web-${countryCode.toLowerCase()}.gif`}
                    alt={partnerAssessment?.tutorialMobile?.mainTitle}
                />
                <SkillifyButton
                    className="tw-text-xs"
                    onClick={() => endTutorialSmallScreen()}
                    isNextButton
                >
                    {partnerAssessment?.main?.cta?.next}
                </SkillifyButton>
            </section>
        );

    return (
        <section className="tw-relative tw-flex tw-flex-col tw-items-center ">
            <h1 className="skillify-font tw-text-xl tw-font-bold">
                {partnerAssessment?.tutorialDesktop?.mainTitle}
                <span className="tw-sr-only">.</span>
            </h1>
            <div style={{ display: display ? 'block' : 'none' }}>
                <div className="skillify-font tw-absolute tw-bottom-[3.5rem] tw-w-[26rem] tw-rounded-lg tw-bg-privacy-modal tw-p-4 tw-text-xs tw-font-semibold tw-text-black/70 tw-shadow-skillify ltr:tw-right-[9.5rem] rtl:tw-left-[9.5rem]">
                    {partnerAssessment?.privacyModal?.text}
                </div>
            </div>
            <div className="tw-sr-only">
                {partnerAssessment?.tutorialDesktop?.explanation}
            </div>
            <Image
                className="tw-mb-2 tw-mt-2 tw-w-full tw-p-2 tw-pb-2"
                src={`/images/tutorial-web-${countryCode.toLowerCase()}.gif`}
                alt={partnerAssessment?.tutorialDesktop?.mainTitle}
            />
            <div className="tw-w-full tw-pr-4 tw-me-12">
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        endTutorialBigScreen();
                    }}
                    className="tw-flex tw-w-full tw-items-center tw-justify-end"
                >
                    <label className="tw-mx-4 tw-mb-0.5 tw-cursor-pointer">
                        <input
                            className="tw-mx-4 tw-rounded tw-border-solid tw-text-skillify-color"
                            type="checkbox"
                            id="privacy-consent"
                            required
                        />
                        <span className="skillify-font tw-text-xs tw-font-semibold tw-text-black/70 lg:tw-text-sm">
                            {partnerAssessment?.privacyModal?.checkboxText}
                        </span>
                    </label>
                    <div className="tw-relative tw-pe-8">
                        <div
                            style={{ display: display ? 'block' : 'none' }}
                            className="tw-absolute -tw-top-[2rem] tw-h-4 tw-w-4 tw-rotate-45 tw-bg-privacy-modal tw-start-0.5"
                        />
                        <Image
                            className="tw-cursor-pointer"
                            alt="info"
                            src="/images/info.svg"
                            height={15}
                            onClick={() =>
                                setDisplay((prevState) => !prevState)
                            }
                        />
                    </div>
                    <SkillifyButton
                        isSubmit
                        className="mt tw-text-base lg:tw-text-xl"
                        isNextButton
                    >
                        {partnerAssessment?.main?.cta?.start}
                    </SkillifyButton>
                </form>
            </div>
        </section>
    );
};
