import Link, { LinkProps } from 'next/link';
import { AnchorHTMLAttributes, PropsWithChildren } from 'react';

type PropTypes = LinkProps &
    Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>;

export const CustomLink = ({
    children,
    href,
    as,
    replace,
    scroll,
    shallow,
    prefetch,
    locale,
    ...anchorProps
}: PropsWithChildren<PropTypes>) => (
    <Link {...{ href, as, replace, scroll, shallow, prefetch, locale }}>
        <a {...anchorProps}>{children}</a>
    </Link>
);
