import React from 'react';
import { Image } from '@components/Image';
import { CONTACT, DIGICO_GLOBAL } from '@lib/constants';
import type { Footer as FooterT } from '@digico/common';
import { useLanguageProvider } from '@providers/LanguageProvider';
import { useDynamicPagesContent } from '@lib/hooks/useDynamicPagesContent';
import { CustomLink } from '@components/CustomLink';

export const Footer = ({ ccCopy }: { ccCopy?: boolean }) => {
    const { countryCode } = useLanguageProvider();
    const [footerText] = useDynamicPagesContent<FooterT.Footer>(
        countryCode,
        'footer',
    );
    return (
        <div className="tw-my-12 tw-border-t tw-border-[#dee2e6]">
            <div className="tw-mx-4 tw-mt-12 tw-flex tw-flex-col tw-flex-wrap tw-justify-around tw-gap-6 md:tw-flex-row md:tw-gap-12">
                <div className="tw-text-center">
                    <p>© {new Date().getFullYear()} Digital Collective</p>
                </div>

                <div className="tw-text-center">
                    <a href={DIGICO_GLOBAL} target="_blank" rel="noreferrer">
                        <Image
                            className="tw-inline"
                            src="/images/digico-logo.svg"
                            alt="Digico logo"
                            width={136}
                            height={53}
                        />
                    </a>
                    <p>DigiCo (Digital Collective)</p>
                    <p>{footerText?.digicoDescription}</p>
                    <p>ASBL no. 0771.466.437</p>
                </div>
                <div className="tw-text-center">
                    <a
                        href={`mailto:${CONTACT}`}
                        type="button"
                        className="link"
                    >
                        {CONTACT}
                    </a>
                    <p>
                        {' '}
                        <CustomLink
                            href="/privacy-policy"
                            data-testid="privacy-policy-link"
                            type="button"
                            className="link"
                        >
                            {footerText?.privacyPolicy}
                        </CustomLink>
                    </p>
                </div>
            </div>
            {ccCopy && (
                <div className="tw-text-center">
                    <small>{footerText?.ccCopy}</small>
                </div>
            )}
        </div>
    );
};
