import * as React from 'react';

export const AnimatedProgressBar = () => (
    <div>
        <div className="tw-absolute tw-mx-auto tw-h-4 tw-w-[50%] tw-overflow-x-hidden tw-rounded-2xl tw-start-0 tw-end-0 sm:tw-w-[18rem]">
            <div className="tw-absolute tw-h-4 tw-w-[150%] tw-rounded-2xl tw-bg-white tw-opacity-40" />
            <div className="tw-absolute tw-h-4 tw-animate-inc tw-rounded-2xl tw-bg-white" />
            <div className="tw-absolute tw-h-4 tw-animate-inc tw-rounded-2xl tw-bg-white" />
        </div>
    </div>
);
