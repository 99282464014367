import { useEffect, useRef, useState } from 'react';
import useFieldApi from '@data-driven-forms/react-form-renderer/use-field-api';
import { Image } from '@components/Image';
import { useInnerWindowWidth } from '@lib/hooks/useInnerWindowWidth';
import { useEffectOnMount } from '@lib/hooks/useEffectOnMount';
import { useClickedOutsideOfComponent } from '@lib/hooks/useClickedOutsideOfComponent';
import { useLanguageProvider } from '@providers/LanguageProvider';

type SelectItem = { value: string; label: string };

export const Select = (props: any) => {
    const { countryCode } = useLanguageProvider();
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const {
        label,
        input,
        loadOptions,
        placeholder,
        options,
        isRequired,
        meta: { error, touched },
    } = useFieldApi(props);
    const [items, setItems] = useState<SelectItem[]>([]);
    const [openSelect, setOpenSelect] = useState(false);
    const [selected, setSelected] = useState({
        label: placeholder,
        value: '',
    });
    const [selectWidth, setSelectWidth] = useState<number>();
    const selectRef = useRef<HTMLSelectElement | null>(null);
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const [savedInputValue, setSavedInputValue] = useState('');
    const windowWidth = useInnerWindowWidth();

    useEffect(() => {
        if (loadOptions) {
            loadOptions(countryCode).then((res: any) => {
                setItems(res);
            });
        }
    }, [loadOptions, countryCode]);

    useClickedOutsideOfComponent(wrapperRef, () => setOpenSelect(false));

    const onOptionClick = (item: SelectItem) => {
        setSelected({
            value: item.value,
            label: item.label,
        });
        setOpenSelect(false);
    };

    useEffectOnMount(() => {
        setSavedInputValue(input.value);
        if (!loadOptions) setItems(options);
    });

    useEffect(() => {
        selectRef.current?.dispatchEvent(
            new Event('change', { bubbles: true }),
        );
    }, [selected]);

    useEffect(() => {
        if (dropdownRef.current && items)
            setSelectWidth(dropdownRef.current.offsetWidth);
    }, [items, windowWidth]);

    useEffect(() => {
        if (items) {
            const previousSelectedItem = items.filter(
                (item) => item.value === savedInputValue,
            );
            setSelected({
                value: savedInputValue,
                label: previousSelectedItem[0]?.label,
            });
        }
    }, [savedInputValue, items]);

    return (
        <div className="tw-mb-6" ref={wrapperRef}>
            <label
                className="skillify-font tw-text-base tw-font-semibold tw-text-skillify-color lg:tw-text-lg lg:tw-font-bold"
                htmlFor={input.name}
            >
                {label}
            </label>
            <div className="tw-mt-2">
                <div
                    ref={dropdownRef}
                    role="listbox"
                    className={` 
                    tw-relative 
                    tw-flex 
                    tw-h-10 
                    tw-max-w-full 
                    tw-cursor-pointer
                    tw-items-center 
                    tw-justify-between 
                    tw-border-[2.5px] 
                    tw-px-4 
                    tw-text-sm
                    tw-font-semibold 
                    tw-transition 
                    tw-ease-in-out 
                    lg:tw-text-base 
                     ${
                         touched && error
                             ? 'tw-border-red-500 tw-text-red-500'
                             : 'tw-border-skillify-color tw-text-skillify-color'
                     } 
                    ${openSelect ? 'tw-rounded-t-lg' : 'tw-rounded-lg'} 

                    `}
                    onClick={() => setOpenSelect((prevState) => !prevState)}
                    onKeyPress={() => setOpenSelect((prevState) => !prevState)}
                    tabIndex={0}
                >
                    {touched && error && (
                        <div className="tw-absolute tw-my-auto tw-flex tw-h-full tw-items-center tw-end-9">
                            <Image
                                className="tw-h-5 tw-w-5"
                                src="/images/errorInfo.svg"
                                alt="error Info"
                            />
                        </div>
                    )}
                    <span className="skillify-font tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap">
                        {selected.label || placeholder}
                    </span>
                    <Image
                        className="tw-pointer-events-none tw-select-none"
                        alt={
                            openSelect
                                ? touched && error
                                    ? 'caret Up Error'
                                    : 'caret Up'
                                : touched && error
                                ? 'caret Down Error'
                                : 'caret Down'
                        }
                        src={
                            openSelect
                                ? touched && error
                                    ? '/images/caretUpError.svg'
                                    : '/images/caretUp.svg'
                                : touched && error
                                ? '/images/caretDownError.svg'
                                : '/images/caretDown.svg'
                        }
                        height={13}
                        width={13}
                    />
                </div>
                <div
                    style={{
                        width: selectWidth,
                    }}
                    role="listbox"
                    className={` 
                        tw-absolute 
                        tw-z-10 
                        tw-flex 
                        tw-max-h-[14.25rem] 
                        tw-w-full 
                        tw-flex-col 
                        tw-rounded-b-lg 
                        tw-border-[2.5px] 
                        tw-border-t-0 
                        tw-bg-white 
                        tw-text-xs 
                        tw-font-semibold 
                        lg:tw-text-sm
                        ${
                            touched && error
                                ? 'tw-border-red-500 tw-text-red-500'
                                : 'tw-border-skillify-color tw-text-skillify-color'
                        } 
                        ${
                            items.length > 8
                                ? 'tw-overflow-y-scroll'
                                : 'tw-overflow-hidden'
                        } 
                        ${!openSelect ? 'tw-invisible' : ''} 
                        `}
                >
                    {items.length > 0 &&
                        items.map((item) => (
                            <span
                                role="option"
                                aria-selected={selected.value === item.value}
                                className="skillify-font tw-cursor-pointer tw-select-none tw-py-1 tw-px-4 hover:tw-bg-skillify-color-light"
                                key={item.value}
                                onClick={() => onOptionClick(item)}
                                onKeyPress={() => onOptionClick(item)}
                                tabIndex={0}
                            >
                                {item.label}
                            </span>
                        ))}
                </div>
            </div>
            <select
                id={label}
                ref={selectRef}
                required={isRequired}
                {...input}
                hidden
                value={selected.value}
                onChange={input.onChange}
            >
                <option value="" disabled hidden>
                    {placeholder}
                </option>
                {items.length > 0 &&
                    items.map((item) => (
                        <option
                            aria-label={item.label}
                            key={item.value}
                            value={item.value}
                        >
                            {item.label}
                        </option>
                    ))}
            </select>

            {touched && error && (
                <div className="skillify-font tw-inline tw-text-sm tw-font-medium tw-text-red-500">
                    {error}
                </div>
            )}
        </div>
    );
};
