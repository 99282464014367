import { PartnerAssessment } from '@lib/types';
import React from 'react';
import { Image } from '@components/Image';
import { DIGICO_PARTNER_ID } from '@lib/constants';
import { Card } from '@components/Card';
import { CustomLink } from '@components/CustomLink';
import { useRouter } from 'next/router';
import { useLanguageProvider } from '@providers/LanguageProvider';

type IntroCardTextProps = {
    partnerAssessment?: PartnerAssessment;
    onClick: () => void;
    elements: JSX.Element[] | undefined;
    withCustomLink?: boolean;
};
export const IntroCardText: React.FC<IntroCardTextProps> = ({
    partnerAssessment,
    onClick,
    elements,
    withCustomLink,
}: IntroCardTextProps) => {
    const router = useRouter();
    const { countryCode } = useLanguageProvider();
    const assessmentId = '4PtySHyxKzEDpaomsTxI'; // TODO: A trick to go to the correct route, this should use the correct value
    const button = (
        <button
            type="button"
            onClick={onClick}
            className="tw-mb-6 tw-inline-flex tw-w-full tw-max-w-md tw-items-center tw-justify-between tw-rounded-lg tw-border-0 tw-bg-white tw-px-10 tw-py-3"
        >
            <span className="skillify-font tw-pt-0.5 tw-text-sm tw-font-semibold tw-text-[#958DFD] lg:tw-text-lg">
                {partnerAssessment?.intro?.start}
            </span>
            <Image
                className="rtl:tw-rotate-180"
                src="/images/arrowRightPurple.svg"
                alt="arrow right"
                width={20}
            />
        </button>
    );
    return (
        <Card textWhite>
            <div className="lg:tw-flex lg:tw-items-center lg:tw-justify-between lg:tw-pb-8">
                <h1 className="skillify-font tw-text-4xl tw-font-semibold lg:tw-m-0">
                    {partnerAssessment?.intro?.title}
                    <span className="tw-sr-only">.</span>
                </h1>
                <Image
                    className="tw-hidden tw-w-[10rem] lg:tw-block"
                    src="/images/skillify_white.png"
                    alt="Skillify Logo"
                />
            </div>
            <div className="lg:tw-grid lg:tw-grid-cols-2 lg:tw-gap-12">
                <div>
                    <h2 className="skillify-font tw-text-lg tw-font-semibold lg:tw-text-2xl">
                        {partnerAssessment?.intro?.subTitle}
                        <span className="tw-sr-only">.</span>
                    </h2>
                    <p className="skillify-font tw-mb-6 tw-mt-3 tw-text-sm tw-font-medium tw-leading-loose lg:tw-text-base">
                        {partnerAssessment?.intro?.body}
                    </p>
                    {withCustomLink ? (
                        <CustomLink
                            href={`/assessment-tool/${DIGICO_PARTNER_ID}/${assessmentId}?lang=${countryCode}&intro=false`}
                            className="tw-no-underline"
                            target={
                                router.asPath.includes('assessment-tool')
                                    ? '_self'
                                    : '_self' // TODO: Revert after rework!
                            }
                        >
                            {button}
                        </CustomLink>
                    ) : (
                        button
                    )}
                </div>
                <div>
                    <h2 className="skillify-font tw-mb-4 tw-text-lg tw-font-semibold lg:tw-text-2xl">
                        {partnerAssessment?.intro?.choiceTitle}
                        <span className="tw-sr-only">.</span>
                    </h2>
                    <div
                        role="menu"
                        className="tw-grid tw-grid-cols-2  tw-gap-3
                         md:tw-grid-cols-3 lg:tw-grid-cols-2 xl:tw-grid-cols-3"
                    >
                        {elements}
                    </div>
                </div>
            </div>
        </Card>
    );
};
