exports.DIGICO_PARTNER_ID = 'g5P2JIev6yjBTRy5vhMo';
exports.DIGICO_DEFAULT_ASSESSMENT = '4PtySHyxKzEDpaomsTxI';
exports.DIGICO_ASSESSMENT_ID_INFO_DATA = 'UwWg3fqWn1uirxKpBlSd';
exports.DIGICO_ASSESSMENT_ID_COM_COL = 'bfRVCpjtHRq2lbBbEPwJ';
exports.DIGICO_ASSESSMENT_ID_CREATION = 'YOiBrpG0rFBVAc1tcdRL';
exports.DIGICO_ASSESSMENT_ID_SAFETY = 'SXXA7mteomoos11YEg1P';
exports.DIGICO_ASSESSMENT_ID_PROBLEM = 'KuBd7YRi3pnMDfYDWaYL';

exports.PDF_CONTENT_ID = 'XTC6s8AjUcg0kIVHe7RE';

exports.API_STATIC_FILES = 'https://firebasestorage.googleapis.com/v0/b/';

exports.PARENT_URL = 'parent-url';

exports.CONTACT = 'skillify@digico.global';

exports.DIGICO_GLOBAL = 'https://digico.global/';
exports.DIGICO_JOBS = 'https://jobs.digico.global/';
exports.PROXY_SERVER = 'https://localhost:5050';

exports.CUSTOM_FOOTER_PATHS = ['/assessment-tool/[partnerId]'];

exports.PARTNER_PATH = 'partner';

exports.ANALYTICS_NAMES = {
    assessmentArea: 'Assessment Area',
};

exports.WAIT_TIME_VERIFICATION_QUESTIONS = 0;
