import React from 'react';
import { SkillifyButton } from '@components/SkillifyButton';
import { ButtonType } from '@components/SkillifyButton/SkillifyButton';

interface ModalProps {
    text: string | undefined;
    cancelButton?: {
        text: string | undefined;
        onClick: () => void;
        buttonType?: ButtonType;
    };
    confirmButton: {
        text: string | undefined;
        onClick: () => void;
        buttonType?: ButtonType;
    };
}

export const Modal: React.FC<ModalProps> = ({
    text,
    cancelButton,
    confirmButton,
}: ModalProps) => (
    <div className="tw-fixed tw-inset-0 tw-z-[99999] tw-flex tw-items-center tw-justify-center tw-bg-black/50">
        <dialog
            open
            className="tw-max-w-[90%] tw-rounded-lg tw-border-none tw-p-8 tw-shadow-skillify"
        >
            <p className="skillify-font tw-pb-4 tw-text-center tw-text-base tw-font-semibold">
                {text}
            </p>
            <div className="tw-flex tw-justify-center tw-gap-8">
                {cancelButton && (
                    <SkillifyButton
                        buttonType={cancelButton.buttonType}
                        className="tw-w-min-36 tw-w-36"
                        onClick={cancelButton?.onClick}
                    >
                        {cancelButton?.text}
                    </SkillifyButton>
                )}
                <SkillifyButton
                    buttonType={confirmButton.buttonType}
                    className="tw-w-min-36 tw-w-36"
                    onClick={confirmButton?.onClick}
                >
                    {confirmButton?.text}
                </SkillifyButton>
            </div>
        </dialog>
    </div>
);
