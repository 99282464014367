export const compareLastTwoElementsOfArray = <T>(
    array: T[],
    compareFn: (
        lastElement: T | undefined,
        secondLastElement: T | undefined,
    ) => boolean,
) => {
    const lastElement = array[array.length - 1];
    const secondLastElement = array[array.length - 2];

    return compareFn(lastElement, secondLastElement);
};
