import {
    addDoc,
    collection,
    doc,
    DocumentData,
    getDocs,
    getFirestore,
    query,
    QueryDocumentSnapshot,
    setDoc,
    where,
    WhereFilterOp,
} from 'firebase/firestore';
import { getDoc } from '@firebase/firestore';
import { firebaseApp } from './firebase';

/**
 * Returns the existing Firestore instance that is associated with the provided
 * @firebase/app#FirebaseApp. If no instance exists, initializes a new instance
 * with default settings.
 *
 * @return The Firestore instance of the provided app.
 */
export const db = getFirestore(firebaseApp);

/**
 * Add a new document to specified CollectionReference with the given data, assigning
 * it a document ID automatically.
 *
 * @param {string} name Name of the collection to add to this document
 * @param {Object<any>} data  A map of the fields and values for the document.
 * @returns A DocumentReference pointing to the newly created document after it has
 *          been written to the backend (Note that it won't resolve while you're offline).
 */
export const create = async (name: string, data: any) =>
    addDoc(collection(db, name), data);

/**
 * Writes to the document referred to by this DocumentReference. If the document
 * does not yet exist, it will be created.
 *
 * @param {string} name Name of the collection to add to this document
 * @param {string} id
 * @param {Object<any>} data A map of the fields and values for the document.
 * @param options
 * @returns The response of a resolved Promise  once the data has been successfully
 *          written to the backend (note that it won't resolve while you're offline).
 */
export const update = async (
    name: string,
    id: string,
    data: any,
    options: any = {},
) => setDoc(doc(db, name, id), data, options);

/**
 * Creates a new immutable instance of Query that is extended to also include additional
 * query constraints and executes the query and returns the results as a QuerySnapshot.
 *
 * @param {string} name Name of the collection to add to this document
 * @param {string} queryProperty The path to compare
 * @param {WhereFilterOp} condition The operation string (e.g "<", "<=", "==", "<", "<=", "!=")
 * @param {unknown} data The value for comparison
 * @returns The results of the query.
 */
export const getWithQuery = async (
    name: string,
    queryProperty: string,
    condition: WhereFilterOp,
    data: unknown,
) => {
    const q = query(
        collection(db, name),
        where(queryProperty, condition, data),
    );

    return getDocs(q);
};

/**
 * Retrieves all fields in the document as an Object. Returns undefined if the document doesn't exist.
 *
 * @param {string} path A slash-separated path to a document.
 * @param {string} pathSegments Additional path segments that will be applied relative to the first argument.
 * @returns An Object containing all fields in the document or undefined if the document doesn't exist.
 */
export const getDocData = async <T extends object>(
    path: string,
    ...pathSegments: string[]
) => {
    const docRef = doc(db, path, ...pathSegments).withConverter(
        assignTypes<T>(),
    );
    const docSnapshot = await getDoc(docRef);
    return docSnapshot.data();
};

/**
 * Converter used by withConverter() to transform user objects of type T into Firestore data.
 * Using the converter allows you to specify generic type arguments when storing and retrieving objects from Firestore.
 */
export const assignTypes = <T extends object>() => ({
    toFirestore(firestoreDoc: T): DocumentData {
        return firestoreDoc;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot): T {
        return snapshot.data() as T;
    },
});
